<template>
  <div class="nav-bar">
    <div class="left">
      <el-button class="back" size="small" @click="backAction">返回</el-button>
      <span class="user">球队简介</span>
    </div>
    <input class="search" placeholder="搜索" />
  </div>
</template>

<script>
export default {
  props:[],
  name: 'NavBar',
  methods: {
    backAction() {
      this.$tis.closeCurrentApp();
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-bar {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .back {
    margin-right: 10px;
  }
  .user {
    font-size: 22px;
    font-weight: 500;
  }
  .search {
    border: 1px solid #4b4b4d;
    border-radius: 5px;
    background: transparent;
    padding: 7px;
    font-size: 15px;
    outline: none;
  }
}
</style>