<template>
  <div class="tools-box">
    <span class="item-tool">
      <img
        src="../assets/home/搜索.svg"
        style="height: 40px"
        @click="toggleSearchBox"
      />
    </span>
    <span class="item-tool not-allowed">
      <img src="../assets/home/pad/形状.svg" style="height: 40px" @click.stop="toggleVoiceBox" />
    </span>
    <span class="item-tool">
      <img
        src="../assets/home/名录.svg"
        style="height: 40px"
        @click="toggleMessageBox"
      />
    </span>
    <span class="item-tool" @click="onAlarmShow">
      <img src="../assets/home/警报.svg" style="height: 40px" />
    </span>
    <span class="item-tool" @click="openNotificationWhole">
      <img src="../assets/home/信封.svg" style="height: 36px" />
    </span>
    <!-- <span class="item-tool" @click="openWidgetSettingPanel">
      <img src="../assets/home/设置.svg" style="height: 40px" />
    </span> -->
    <span class="item-tool" @click="openApps">
      <img src="../assets/home/pad/菜单.svg" style="height: 40px" />
    </span>
    <span class="item-tool full-screen" @click="onFullScreen">
      <img src="../assets/home/全屏.svg" style="height: 40px" />
    </span>
  </div>
</template>
<script>
const clickoutside = {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el._vueClickOutside_ = documentHandler;
    setTimeout(() => {
      document.addEventListener("click", documentHandler);
    });
  },
  update() {},
  unbind(el) {
    document.removeEventListener("click", el._vueClickOutside_);
    delete el._vueClickOutside_;
  },
};
export default {
  props: {},
  data() {
    return {};
  },
  directives: { clickoutside },
  computed: {
    isMainApp() {
      // 当前是否是主界面应用
      return this.$tis.isMainApp();
    },
    currentApp() {
      return this.$tis.getCurrentApp();
    },
    messageCounts() {
      return this.$tis.getMessageCount();
    },
  },
  mounted() {
    // this.$tis.openApp('app_demo');
  },
  created() {},
  methods: {
    openApps() {
      // 全部应用

      // this.$tis.hiddenAllVisible();
      this.$tis.bus.registerVisible.alarmMainWineowVisible = false;
      this.$tis.bus.alarmMainWineowVisible = false;
      this.$tis.bus.applicationInterface = {
        visible: false,
      };

      this.$tis.bus.controlPanelPadVisible = !this.$tis.bus
        .controlPanelPadVisible;
      this.$tis.bus.registerVisible.controlPanelPadVisible = !this.$tis.bus
        .controlPanelPadVisible;
    },
    //搜索
    toggleSearchBox() {
      // this.$tis.hiddenAllVisible();
      this.$tis.bus.searchBoxPadVisible = !this.$tis.bus.searchBoxPadVisible;
      this.$tis.bus.registerVisible.searchBoxPadVisible = this.$tis.bus.searchBoxPadVisible;
    },
    // 打开语音助手
    toggleVoiceBox() {
      this.$tis.toggleVoiceBox();
    },
    openNotificationWhole() {
      // 消息抽屉
      this.$tis.bus.controlPanelPadVisible = false;
      this.$tis.hiddenAllVisible();
      this.$tis.bus.visibleMessageBox = !this.$tis.bus.visibleMessageBox;
      this.$tis.bus.registerVisible.visibleMessageBox = this.$tis.bus.visibleMessageBox;
    },
    //通讯录
    toggleMessageBox() {},

    onFullScreen() {
      this.$tis.fullScreen(true);
    },
    onAlarmShow() {
      // 报警页面

      this.$tis.bus.controlPanelPadVisible = false;

      this.$tis.bus.registerVisible.alarmMainWineowVisible = !this.$tis.bus
        .registerVisible.alarmMainWineowVisible;
      this.$tis.bus.alarmMainWineowVisible = !this.$tis.bus
        .alarmMainWineowVisible;

      this.$tis.bus.applicationInterface = {
        visible: !this.$tis.bus.applicationInterface.visible,
        name: "收发报警",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.tools-box {
  display: flex;
  margin: 0 4px;
  position: relative;
  height: 48px;
  padding: 0 10px;
  align-items: center;
  &.cut {
    padding-right: 30px;
    margin-right: -10px;
    border-right: 1px solid #4c4a48;
    &::after {
      content: "";
      position: absolute;
      right: 25px;
      top: 0;
      bottom: 0;
      background: #4c4a48;
      width: 1px;
      height: 100%;
    }
  }

  .item-tool {
    cursor: pointer;
    z-index: 1;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.15);
    img {
      display: block;
    }
    span {
      font-size: 24px;
      margin-left: 8px;
      color: rgba(255, 255, 255, 0.8);
    }
    &.full-screen {
      background: none;
      border: none;
      opacity: 0.5;
    }
  }
}
</style>
