<template>
  <div class="app-bar" v-if="showAppBar">
    <div class="app-name">{{ currentApp.name }}</div>
    <div class="app-controls">
      <el-button-group>
        <el-button
          type="info"
          icon="el-icon-more"
          @click="onOpenWidgetPanel"
        ></el-button>
        <el-button
          @click="onClose"
          type="info"
          icon="el-icon-close"
        ></el-button>
      </el-button-group>
      <!-- <span @click="onOpenWidgets" class="control"> -->
      <!-- <i class="el-icon-share"></i> -->
      <!-- </span> -->
      <!-- <span @click="onClose" class="control">X</span> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onOpenWidgetPanel() {
      this.$tis.openWidgetPanel();
    },
    onClose() {
      this.$tis.closeCurrentApp();
    },
  },
  computed: {
    currentApp() {
      return this.$tis.getCurrentApp();
    },
    showAppBar() {
      return this.currentApp && !this.currentApp.default;
    },
  },
  created() {
    const off = this.$tis.$on(
      "INCREAE COUNT",
      { appIds: ["app_1"] },
      (payload, source) => {
        console.log(payload, source);
      }
    );
    setTimeout(() => {
      console.log("clear");
      off();
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  .app-name {
    font-size: 19px;
    padding: 5px;
    font-weight: bold;
  }
  .control {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 13px;
    border: 1px solid #555;
    background: #37373c;
    color: #fff;
  }
}
::v-deep {
  .el-button {
    background: #37373c;
    padding: 7px;
  }
}
</style>