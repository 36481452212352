<template>
  <div class="historical-news">
    <div class="title">历史消息</div>
    <span class="close" @click="onClose"></span>
     <!-- 表单start -->
    <div class="form-wrap">
      <el-form  :inline="true" class="form">
        <el-form-item :style="{width: '13.5%' }">
          <el-select v-model="formObj.mail" placeholder="收件箱" @change="(value)=>handleChangeMail(value)">
            <el-option label="收件箱" value="1"></el-option>
            <el-option label="发件箱" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :style="{width: '13.5%' }">
          <el-select v-model="formObj.source" placeholder="全部来源" @change="(value)=>handleChangeSource(value)">
            <el-option label="系统" value="1"></el-option>
            <el-option label="李雪琴" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :style="{width: '13.5%' }">
          <el-select v-model="formObj.type" placeholder="全部类型" @change="(value)=>handleChangeType(value)">
            <el-option label="态势类" value="0"></el-option>
            <el-option label="篮板" value="1"></el-option>
            <el-option label="三分" value="2"></el-option>
             <el-option label="扣篮" value="4"></el-option>
          </el-select>
        </el-form-item>
        <!-- 日期框 -->
        <el-form-item label="发送时间" :style="{width: '32%' }"> 
          <el-date-picker
            @change="(value)=>handleChangeTime(value)"
            value-format='yyyy-MM-dd'
            v-model="formObj.time"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item :style="{width: '23%' }">
            <el-input placeholder="请输入内容"  prefix-icon="el-icon-search" v-model="formObj.search_str" @change="(value)=>handleSearch(value)"/>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表单end -->
    <!-- 表格start -->
    <div class="table-wrap"> 
      <el-table
        ref="multipleTable"
        :data="tableData"
        @selection-change="handleSelectionChange"
        :highlight-current-row="true"
        @cell-mouse-leave="showClickIcon=false" 
        @cell-mouse-enter="ncrFormat"
      >
        <el-table-column type="selection" width="60"> </el-table-column>
        <el-table-column
          prop="source"
          label="消息来源"
          width="110"
          align="left"
        >
        </el-table-column>
        <el-table-column
          label="消息类型"
          width="100"
          align="left"
        >
          <template slot-scope="scope">
            <span class="bg-red" v-if='scope.row.status == 1'>{{ scope.row.type }}</span>
            <span class="bg-yellow" v-if='scope.row.status == 2'>{{ scope.row.type }}</span>
            <span class="bg-gray" v-if='scope.row.status == 3'>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          align="left"
        >
        </el-table-column>
        <el-table-column
          prop="time"
          label="接收时间"
          width="180"
          align="right"
        >
        <template slot-scope="scope">
            <template v-if="showClickIcon==true&&scope.row.id==rowid">
              <span class="group-button"  @click="handleLook(scope.$index, scope.row)">查看</span>
              <span class="group-button" @click="handleReply(scope.$index, scope.row)">回复</span>
              <span class="group-button" @click="handleDelete(scope.$index, scope.row)">删除</span>
            </template>
           <span v-else>{{ scope.row.time }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表格end -->
    <!-- footer start-->
    <div class="footer">
      <el-pagination
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, prev, pager, next"
        :total="1000"
      >
      </el-pagination>
      <el-button @click="handleBatchDelect">批量删除</el-button>
      <el-button @click="handleWholeDelect">全部删除</el-button>
      <el-button @click="handleTagRad">标记已读</el-button>
    </div>
    <!-- footer end-->
  </div>
</template>
<script>
export default {
  data() {
    return{
      showClickIcon:false,
      rowid:1,
      rowData:[],

      isVisible: true,
      page: 1,
      formObj: {
        mail:'',
        source:'',
        type:'',
        time:'',
        search_str:'',
      },
      tableData: [
        {
          id:0,
          source: "系统通知",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 1
        },
        {
          id:1,
          source: "系统通知",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 2
        }, 
        {
          id:2,
          source: "系统通知",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 2
        }, 
        {
          id:3,
          source: "系统通知",
          time: "2016-05-02",
          type: "应用",
          content: "上海市普陀区金沙江路",
          status: 3
        },
        {
          id:4,
          source: "系统通知",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 5
        }   
      ],
      multipleSelection: [] //选中行表格数据
    }
  },
  computed: {
  },
  methods: {
    //鼠标移入事件
    ncrFormat(row){
         this.showClickIcon=true
	       this.rowid=row.id   //赋值行id，便于页面判断
	       this.rowData=row   //把行数据赋值，用于后续操作
         console.log(  row, ' this.rowid')
      	},

    //获取表格数据
    getListData() {
    },
    //选中行当表格数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log( this.multipleSelection, 'this.multipleSelection')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    //处理查看
    handleLook(index, row) {
      console.log(index,row,'===')
      this.$tis.openLookAtMsgBox();

    },
    //处理回复
    handleReply(index, row) {
      console.log(index,row,'===')
      this.$tis.openDialogBox();
    },
    //处理删除
    handleDelete(index, row) {
      console.log(index,row,'===')
    this.tableData.splice(index,1)
    },
    //批量删除
    handleBatchDelect(){
      //this.multipleSelection //会用到选中表格当数据，将数据传给接口
      var that = this
      that.multipleSelection.forEach(function(item,index){
        console.log(index);
        that.tableData.forEach(function(itemI,indexI){
            if(item.id === itemI.id){
              that.tableData.splice(indexI,1)
            }
        })
      })
      console.log('批量删除')
    },
    //全部删除
    handleWholeDelect(){
      this.tableData = []
      // console.log('全部删除')
    },
    //标记已读
    handleTagRad(){
      console.log('标记已读')
    },
     //当选择类型
    handleChangeType(value){
      this.page = 1
       this.tableData = [
        {
          id:0,
          source: "系统通知1",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 1
        }
      ]
      console.log(value,this.formObj)
    },
    //当选择收件箱
    handleChangeMail(value){
      this.page = 1
      console.log(value,this.formObj)
      this.tableData = [
        {
          id:0,
          source: "系统通知1",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 1
        },
        {
          id:2,
          source: "系统通知2",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 2
        }
      ]  
    },
    //当选择来源
    handleChangeSource(value){
      this.page = 1
      console.log(value,this.formObj)
      this.tableData = [
        {
          id:0,
          source: "系统通知22",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 1
        },
        {
          id:1,
          source: "系统通知32",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 2
        }
      ] 
    },
    //当改变时间
    handleChangeTime(value){
      this.page = 1
      console.log(value,this.formObj) 
      this.tableData = [
        {
          id:0,
          source: "系统通知44",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 1
        },
        {
          id:1,
          source: "系统通知55",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 2
        }
      ] 
    },
    //当搜索内容当时候，不过需要失去焦点
    handleSearch(value){
      this.page = 1
      console.log(value,this.formObj)
      this.tableData = [
        {
          id:0,
          source: "系统通知6",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 1
        },
        {
          id:1,
          source: "系统通知7",
          time: "2016-05-02",
          type: "告知",
          content: "上海市普陀区金沙江路",
          status: 2
        }
      ] 
    },
    onClose() {
      this.$tis.closeHistoricalNews()
    }
  },
};
</script>

<style lang="scss" scoped>
.historical-news {
  width: 1200px;
  height: 800px;
  box-sizing: border-box;
  margin: 10px auto;
  position: relative;
  border: 1px solid #fee99c;
	// border-image: -webkit-linear-gradient(#ccc, #f7c829) 30 30;
  background: url("../assets/border-top.png") no-repeat #1d1c18;
	background-size: 100%  32px;
  box-shadow: 0 0 36px 0 rgba(255, 221, 94, 0.3);
  padding: 0 42px 30px;
  // height: calc(100% - 12px);
  // height: -webkit-calc(100% - 12px);
  // font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;
  .title {
    line-height: 30px;
    height: 32px;
    color: #fff;
    font-size: 24px;
    text-align: center;
  }
  // background: url("../assets/bg2.png") no-repeat #1d1c18;
  // background-size: 100% 100%;
  // width: 1216px;
  // height: 100%;
  // position: relative;
  // // max-height: 841px;
  // margin: 0 auto;
  // padding: 0 50px;
  // box-sizing: border-box;
  // -webkit-box-sizing: border-box;
  // .title {
  //   height: 36px;
  //   line-height: 42px;
  //   font-size: 24px;
  //   color: #fff;
  //   text-align: center;
  // }
  .close {
    position: absolute;
    background: url("../assets/close.png") no-repeat center;
    background-size: 100%;
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
    opacity: 0.75;
  }
  .close:hover {
    opacity: 1;
  }
  .form-wrap {
    padding: 24px 0 4px;   
  }
  .table-wrap {
    background: #1a1916;
    min-height: 480px;
    padding: 0 20px;
  }
  .footer{
    height: 70px;
    width: 100%;
    box-sizing: border-box;
    // padding: 15px 0 30px ;
    left: 0;
    bottom: 0;
    padding: 0 40px;
    position: absolute;
  }
  .bg-yellow, .bg-red,  .bg-gray {
    padding: 3px 7px;
    margin-left: 4px;
  }
  .bg-yellow {
    background: #e3c468;
  }
  .bg-red {
    background: #CC4E4E;;
  }
  .bg-gray {
    background: #a2a2a2;
  }
  .group-button {
    color: #F7D760;
    padding: 0 8px;
    cursor: pointer;
  }
}
::v-deep {
  .el-form {
    display: flex;
    -webkit-display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .el-input__inner, .el-range-input {
    font-size: 16px;
    color: #FFFFFF;
    background: rgba(59,59,55,0.50);
  }
  .el-input__inner {
    border: 1px solid #979797;
    border-radius: 0;
    color: #fff;
    height: 48px;
    line-height: 48px;
  }
  .el-date-editor .el-range-separator {
    opacity: 0.35;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 40px;
  }
  .el-range-editor.el-date-editor--daterange{
    width: auto;
  }
  .el-range-input {
    background: none;
  }
  .el-form-item:nth-child(4) {
    .el-form-item__content{
      width: 78%;
    }
  }
  .el-form-item:last-child {
    margin-right: 0;
    .el-input__inner {
      background: rgba(24,24,21,0.50);
      border: 1px solid #979797;
    }
  }
  .el-icon-search {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    line-height: 48px;
  }
  .el-form-item__label {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 48px;
    width: 78px;
  }
  // .el-table {
  //   // position: static;
  // }
  .el-table::before {
    height: 0px;
  }
  .el-table, .el-table__expanded-cell {
    background: none;
  }
  .el-table th, .el-table tr , .el-table{
    background: none;
  }
  .el-table th {
    font-size: 16px;
    color: rgba(255,255,255,0.85);
  }
  .el-table td {
    font-size: 16px;
    color: #fff;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;
  }
  .el-table__header-wrapper {
    background: #181815;
  }
  .el-table td, .el-table th.is-leaf {
    border: none;
  }
  .el-table__body-wrapper {
    background: #1a1916;
  }
  .el-table tr:hover {
    background: none;
  }
  .el-table__body tr.current-row>td {
    background-color: #272723;
  }
  .el-checkbox__inner {
    border-radius: 0;
    background: none;
    background-color: none;
    width: 16px;
    height: 16px;
    border:1px solid rgba(255,255,255,0.46);
  }
  .el-table th>.cell {
    padding-left: 14px;
    padding-right: 14px;
  }
  .el-button {
    background: none;
    border-radius: 0;
    width: 90px;
    height: 38px;
    line-height: 38px;
    padding: 0;
    font-size: 16px;
    border: 1px solid #585858;
    color: #ccc;
  }
  .el-button:focus, .el-button:hover {
    color: #dddddd;
    background: #515151; 
    border-color: #a4a4a4;
  }
  .el-pagination {
    float: right;
  }
  .el-table--enable-row-hover .el-table__body tr:hover>td {
    background: rgba(255,255,255,0.20);
  }
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #f7c829 !important;
    border-color: #f7c829 !important;
  }
  .el-pager li {
    background: none;
    color: #fff;
    font-size: 16px;
  }
  .footer .el-pagination .btn-next, .footer .el-pagination .btn-prev, .footer .el-pagination__total {
    background: none;
    color: #fff;
    font-size: 16px;
  }
  .el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon {
    font-size: 18px;
  }
  .el-pager li.active {
    //  background: #272723;
    background: #f7c829;
    color: #1D1D1D;
  }
}

</style>