<template>
  <div class="app-view">
    <div v-for="app in apps" :key="app.id">
      <div :id="app.containerId" v-show="app.opened"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    apps() {
      return this.$tis.getApps();
    },
  },
  mounted() {
    this.$tis.openDefaultApp()
  },
  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.app-view {
  .alert {
    max-width: 500px;
    margin: 100px auto;
  }
}
</style>
