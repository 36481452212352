<template>
  <div class="tools-box">
    <span class="item-tool">
      <img src="../assets/home/搜索.svg" @click="toggleSearchBox" />
    </span>
    <span class="item-tool not-allowed" @click="toggleVoiceBox">
      <img src="../assets/home/pad/形状.svg" class="h-58" />
    </span>
    <span class="item-tool">
      <img
        src="../assets/home/名录.svg"
        class="h-56"
        @click="toggleMessageBox"
      />
    </span>
    <span class="item-tool" @click="onAlarmShow">
      <img src="../assets/home/警报.svg" />
    </span>
    <span class="item-tool" @click="openNotificationWhole">
      <img src="../assets/home/信封.svg" class="h-51" />
    </span>
    <span class="item-tool" @click="openApps">
      <img src="../assets/home/pad/菜单.svg" class="h-56" />
    </span>
    <span class="item-tool full-screen" @click="onFullScreen">
      <img src="../assets/home/全屏.svg" class="h-56" />
    </span>
  </div>
</template>
<script>
const clickoutside = {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el._vueClickOutside_ = documentHandler;
    setTimeout(() => {
      document.addEventListener("click", documentHandler);
    });
  },
  update() {},
  unbind(el) {
    document.removeEventListener("click", el._vueClickOutside_);
    delete el._vueClickOutside_;
  },
};
export default {
  props: {},
  data() {
    return {};
  },
  directives: { clickoutside },
  computed: {
    isMainApp() {
      // 当前是否是主界面应用
      return this.$tis.isMainApp();
    },
    currentApp() {
      return this.$tis.getCurrentApp();
    },
    messageCounts() {
      return this.$tis.getMessageCount();
    },
  },
  mounted() {},
  created() {},
  methods: {
	  toggleVoiceBox(){
		  console.log(111);
		  return this.$tis.toggleVoiceBox();
	  },
    openApps() {
      // 全部应用
      this.$tis.bus.registerVisible.alarmMainWineowVisible = false;
      this.$tis.bus.alarmMainWineowVisible = false;
      this.$tis.bus.applicationInterface = {
        visible: false,
      };

      this.$tis.bus.controlPanelPadVisible = !this.$tis.bus
        .controlPanelPadVisible;
      this.$tis.bus.registerVisible.controlPanelPadVisible = !this.$tis.bus
        .controlPanelPadVisible;
    },
    //搜索
    toggleSearchBox() {
      this.$tis.bus.searchBoxPadVisible = !this.$tis.bus.searchBoxPadVisible;
      this.$tis.bus.registerVisible.searchBoxPadVisible = this.$tis.bus.searchBoxPadVisible;
    },
    openNotificationWhole() {
      // 消息抽屉
      this.$tis.bus.controlPanelPadVisible = false;
      this.$tis.hiddenAllVisible();
      this.$tis.bus.visibleMessageBox = !this.$tis.bus.visibleMessageBox;
      this.$tis.bus.registerVisible.visibleMessageBox = this.$tis.bus.visibleMessageBox;
    },
    //通讯录
    toggleMessageBox() {},

    onFullScreen() {
      this.$tis.fullScreen(true);
    },
    onAlarmShow() {
      // 报警页面
      this.$tis.bus.controlPanelPadVisible = false;

      this.$tis.bus.registerVisible.alarmMainWineowVisible = !this.$tis.bus
        .registerVisible.alarmMainWineowVisible;
      this.$tis.bus.alarmMainWineowVisible = !this.$tis.bus
        .alarmMainWineowVisible;

      this.$tis.bus.applicationInterface = {
        visible: !this.$tis.bus.applicationInterface.visible,
        name: "收发报警",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.tools-box {
  display: flex;
  margin: 0 3px;
  position: relative;
  height: 36px;
  padding: 0 7px;
  align-items: center;
  &.cut {
    padding-right: 23px;
    margin-right: -7px;
    border-right: 1px solid #4c4a48;
    &::after {
      content: "";
      position: absolute;
      right: 19px;
      top: 0;
      bottom: 0;
      background: #4c4a48;
      width: 1px;
      height: 100%;
    }
  }

  .item-tool {
    cursor: pointer;
    z-index: 1;
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 61px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.15);
    img {
      display: block;
      height: 30px;
      &.h-51 {
        height: 25px;
      }
      &.h-56 {
        height: 28px;
      }
      &.h-58 {
        height: 29px;
      }
    }
    span {
      font-size: 18px;
      margin-left: 6px;
      color: rgba(255, 255, 255, 0.8);
    }
    &.full-screen {
      background: none;
      border: none;
      opacity: 0.5;
    }
  }
}
</style>
