<template>
  <div class="search-box">
    <input class="input" type="search" v-model="search" placeholder="请输入要查找的内容" @keyup.enter="onKey" @input="onInput"/>    
   <!-- <ul>
      <li v-for="item in newList" :key="item.id" @click="addItem(item)">
       {{ item.name }}
      </li>
   </ul> -->
    <div class="content"  v-show="fly">
      <div class="header">应用</div>
      <div class="item" v-for="item in newList" :key="item.id">     
         <li class="lis" v-for="item2 in item.tab" :key="item2.names">{{item2.names}}</li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  data() {
    return {  
      
      fly:false,  
     //模拟数组
   list: [
    {
     id:0,
     name: "詹姆斯", 
     tab:[
       {
         names:'詹姆斯A'
       },
       {
         names:'詹姆斯B'
       },
       {
         names:'詹姆斯C'
       },
       {
         names:'詹姆斯D'
       },
       {
         names:'詹姆斯E'
       },
     ]  
    //  ['炮长A','炮长B','炮长C','炮长D','炮长E',]
    },
    {
    id:1,
    name: "戴维斯",
    tab:[
       {
         names:'戴维斯A'
       },
       {
         names:'戴维斯B'
       },
       {
         names:'戴维斯C'
       },
       {
         names:'戴维斯D'
       },
       {
         names:'戴维斯E'
       },
     ] 
    },
    {
     id:2,
     name: "赛斯·库里",
     tab:[
       {
         names:'赛斯·库里A'
       },
       {
         names:'赛斯·库里B'
       },
       {
         names:'赛斯·库里C'
       },
       {
         names:'赛斯·库里D'
       },
       {
         names:'赛斯·库里E'
       },
     ]   
    },
    {
     id:3,
     name: "史蒂芬·库里",
     tab:[
       {
         names:'史蒂芬·库里A'
       },
       {
         names:'史蒂芬·库里B'
       },
       {
         names:'史蒂芬·库里C'
       },
       {
         names:'史蒂芬·库里D'
       },
       {
         names:'史蒂芬·库里E'
       },
     ] 
    },
    {
     id:4,
     name: "加内特",
     tab:[
       {
         names:'加内特A'
       },
       {
         names:'加内特B'
       },
       {
         names:'加内特C'
       },
       {
         names:'加内特D'
       },
       {
         names:'加内特E'
       },
     ] 
    },
    {
     id:5,
     name: "杜兰特",
     tab:[
       {
         names:'杜兰特A'
       },
       {
         names:'杜兰特B'
       },
       {
         names:'杜兰特C'
       },
       {
         names:'杜兰特D'
       },
       {
         names:'杜兰特E'
       },
     ] 
    }
   ],
  newList:[],
  search: "",
  appList:'',
    }
  },
  computed: {
    allApps() {
      return this.$tis.getApps().filter((a) => !a.default);
    },
    recentOpenApps() {
      // 最近打开的5个
      return [...this.allApps]
        .filter((a) => a.lastOpenTime > 0)
        .sort((a, b) => {
          return b.lastOpenTime - a.lastOpenTime;
        })
        .slice(0, 5);
    },
    mostOpenApps() {
      // 最常打开的5个
      return [...this.allApps]
        .filter((a) => a.openTimes > 0)
        .sort((a, b) => {
          return b.openTimes - a.openTimes;
        })
        .slice(0, 5);
    },
    apps() {
      return this.allApps;
    },
  },
  
  methods: {

    //获取搜索内容的数据
    // addItem(e){
    //  this.appList = e.tab;
    //  console.log(e);
    //   this.newList = []
    // },
     onKey() {    
      this.newList = [],
      // 循环list数组
      this.list.forEach((item) => {      
      //  console.log(item);
      //如果这一条的数据中包含关键字就添加到新的数组中
    // if (item.name.includes(this.search)) {
    //   this.newList.push(item);      
    // }
    if(item.name == this.search){
      this.newList.push(item);
      this.fly = true
    }
    
  });
},
//实时监听input中的值的变化，
onInput(){
  //循环list中的数据
  this.list.forEach((item)=>{
    if(item.name !== this.search){      
      this.newList = []
      //input为空时清空应用中的数据
      // this.appList = ''   
      this.fly = false  
    }
  })
},
    onOpenApp(app) {
      this.$tis.openApp(app.id);
      this.$tis.toggleSearchBox();
    },
  },

};
</script>

<style lang="scss" scoped>

.search-box {
  border: 1px solid #fade6d;
  background-image: linear-gradient(
    180deg,
    #29251b 0%,
    rgba(14, 14, 14, 0.79) 100%
  );
  padding: 10px 20px;
  position: fixed;
  top: 80px;
  right: 30%;
  z-index: 999;
  width: 750px;

  overflow: hidden;

  .input {
    // border: 1px solid red;
    display: block;
    width: 100%;
    height: 44px;
    padding: 20px;
    font-size: 22px;
    color: #1d0101;
    background: rgba(51, 51, 51, 0.87);
    border-bottom: 1px solid #4a4a4a;
    caret-color:white;
    color: white;
  }

input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 25px;
      width: 25px;
      background: url(https://yxs-web.oss-cn-beijing.aliyuncs.com/328e4d97f9d0d68ea04e872f68e508e3.png) no-repeat;
      background-size: contain;
    }

  .item {
    // padding: 18px 50px;
    padding-left:18px ;
    font-family: HiraginoSansGB-W3;
    font-size: 20px;
    font-weight: bold;
    color: #cecece;
    cursor: pointer;
    // display: flex;
    // align-items: center;
    line-height: 30px;    
    img {
      width: 20px;
      margin-right: 10px;
    }
    &:hover {
      background-image: linear-gradient(
        90deg,
        rgba(255, 209, 79, 0.52) 0%,
        rgba(255, 216, 109, 0.05) 100%
      );
    }
  }
}
ul{
  // border: 1px solid red;
  position: absolute;
  // top: 356px;
  // left: 20px;
  z-index: 9999;
  margin-left: 20px;
}
li{
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  
}
li:hover{
  color: #fade6d;
}
.header{
  // border: 1px solid red;
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-left: 20px;
  background: #30302F;
  margin-top: 2px;
}
.content{
  margin: 10px 0;
}

</style>
