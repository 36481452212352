<template>
	<el-card class="card" v-show="widget.opened" :class="{ 'full-screen': isfullScreen }">
		<div slot="header" class="header">
			<div class="name">{{ widget.name }}</div>
			<div class="btns">
				<span>
					<el-button type="text" size="small" @click="toggle">
						浮窗大小
					</el-button>
				</span>
				&nbsp;
				<div class="switch-full-screen-btn" @click.stop="handleSwitchWindow">
					<!-- 浮窗 -->
					<i class="iconfont icon-qiehuan1" v-if="isfullScreen"></i>
					<!-- 全屏 -->
					<i class="iconfont icon-quanping1" v-else></i>
				</div>
				&nbsp;
				<span>
					<el-button type="text" size="small" @click="onClose">关闭</el-button>
				</span>
			</div>

			<el-card v-show="showSizeChoice" class="size-choice">
				<div>
					<el-radio v-model="contentSize" label="small">小</el-radio>
				</div>
				<el-radio v-model="contentSize" label="medium">中</el-radio><br />
				<el-radio v-model="contentSize" label="large">大</el-radio><br />
			</el-card>
		</div>
		<div class="content" :class="contentSize + ' ' + widget.position">
			<div :id="widget.containerId"></div>
		</div>
	</el-card>
</template>

<script>
	export default {
		props: {
			widget: Object,
		},
		data() {
			return {
				showSizeChoice: false,
				contentSize: "small",
				isfullScreen: false,
			};
		},
		methods: {
			onClose() {
				sessionStorage.removeItem('backWidget')
				this.$tis.closeWidget(this.widget.id);
			},
			toggle() {
				this.showSizeChoice = !this.showSizeChoice;
			},
			handleSwitchWindow() {
				console.log('this.widget', this.widget, this.widget.id)
				let that = this;
				// this.isfullScreen = !this.isfullScreen;
				// 自定义浮窗
				// 关闭当前浮窗
				this.$tis.closeWidget(this.widget.id);
				//打开default_demo浮窗  isBackwidget用于标记是否是浮窗的最大化
				// this.$tis.openApp('default_demo',{isBackwidget:true,id:that.widget.id});

				//参数的方式行不通  用sessionStorage的方式实现
				sessionStorage.setItem('backWidget', this.widget.id);
				this.$tis.openApp('default_demo')
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		border: none;
		background: rgba(0, 0, 0, 0.6);

		&.full-screen {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.content {
				height: calc(100% - 50px) !important;
			}
		}

		.header {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.name {
				margin: 5px 0;
				font-size: 14px;
			}

			.size-choice {
				position: absolute;
				top: 34px;
				right: 28px;
				padding: 10px 0 10px 10px;
			}

			.btns {
				display: flex;
				align-items: center;
			}
		}

		.content {
			height: 230px;
			overflow: scroll;

			&.medium {
				height: 300px;
			}

			&.large {
				height: 400px;
			}

			.icon {
				width: 100%;
				display: block;
			}

			&.bottom {
				height: 20vh;

				&.medium {
					height: 50vh;
				}

				&.large {
					height: 70vh;
				}
			}
		}

		::v-deep {
			.el-card__header {
				padding: 5px 10px;
				cursor: pointer;
				color: #fff;
				border: none;

				.name {
					font-size: 15px;
				}
			}

			.el-card__body {
				padding: 0;
			}
		}
	}
</style>
