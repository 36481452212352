import axios from 'axios'
import Recorder from 'js-audio-recorder'
import syberh from '@syberos/jsbridge'
const baseURL = '/api/aisound'
const request = axios.create({
	baseURL
})
let aacFilePath = '';
const recorder = new Recorder({
	sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
	sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，
	numChannels: 1, // 声道，支持 1 或 2， 默认是1
})
window.recorder = recorder
window.syberh = syberh
const voicePlayer = window.document.createElement('AUDIO')

function getFormData(file, data = {}) {
	let fd = new FormData()
	fd.append('file', file)
	for (let o in data) {
		if (Object.prototype.hasOwnProperty.call(data, o)) {
			fd.append(o, data[o])
		}
	}

	return fd
}

export default function voicePlugin(tis) {
	//命令词识别
	async function voice(data) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/voice`, data, {
					headrs: {
						'content-type': 'multipart/form-data'
					},
				})
				.then(resolve)
				.catch(reject)
		})
	}

	//自由说识别
	async function freeVoice(data) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/freeVoice`, data, {
					headrs: {
						'content-type': 'multipart/form-data'
					},
				})
				.then(resolve)
				.catch(reject)
		})
	}

	// 语音合成
	async function getVoice(data) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/getVoice`, data)
				.then(res => {
					if (res && /\.wav$/i.test(res.data.data || '')) {
						// http://192.168.1.103:7007/voice2str/voice_file/1634971395871_tts_123.wav
						resolve(`${baseURL}/voice2str/voice_file/${res.data.data}`)
					} else {
						reject('合成失败')
					}
				})
				.catch(reject)
		})
	}

	// 自由说识别
	async function free_voice2str(file, data = {}) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/free_voice2str`, getFormData(file, data), {
					'Content-Type': 'multipart/form-data',
				})
				.then(res => {
					if (res && res.data) {
						resolve(res.data)
					} else {
						reject('识别失败')
					}
				})
				.catch(reject)
		})
	}

	// 获取命令词
	async function getCommandWord(file, data = {}) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/post_test`, getFormData(file, data), {
					'Content-Type': 'multipart/form-data',
				})
				.then(res => {
					if (res && res.data) {
						resolve(res.data)
					} else {
						reject('识别失败')
					}
				})
				.catch(reject)
		})
	}

	// 圆心/aos版语音识别
	async function aacVoice(data) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/aacVoice`, data)
				.then(res => {
					if (res && res.data) {
						resolve(res.data)
					} else {
						reject('识别失败')
					}
				})
				.catch(reject)
		})
	}

	// 获取bnf文件内容
	async function getBnf(params = {}) {
		return new Promise((resolve, reject) => {
			request
				.get(`/voice2str/getBnf`, {
					params
				})
				.then(res => {
					if (res && res.data && res.data.bnfState == 0) {
						resolve(res.data.bnfData)
					} else {
						reject('获取失败')
					}
				})
				.catch(reject)
		})
	}

	// 添加bnf文件内容
	async function addBnf(data) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/addCompName`, data)
				.then(res => {
					if (res && res.state) {
						resolve(res.state)
					} else {
						reject('添加失败')
					}
				})
				.catch(reject)
		})
	}

	// 更新bnf文件内容
	async function updateBnf(data) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/updateBnf`, data)
				.then(res => {
					if (res && res.data.state) {
						resolve(res.data.state)
					} else {
						reject('更新失败')
					}
				})
				.catch(reject)
		})
	}

	// 语义理解分词识别
	async function getBaseIE(file, data = {}) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/getBaseIE`, getFormData(file, data), {
					'Content-Type': 'multipart/form-data',
				})
				.then(res => {
					resolve((res && res.result_list) || [])
				})
				.catch(reject)
		})
	}

	// 命令词删除
	async function deleteBnf(bnfList) {
		return new Promise((resolve, reject) => {
			request
				.post(`/voice2str/deleteBnf`, bnfList)
				.then(res => {
					resolve(res && res.result)
				})
				.catch(reject)
		})
	}

	function debounce(func, wait) {
		let timeout
		return function() {
			let context = this
			let args = arguments

			if (timeout) clearTimeout(timeout)

			timeout = setTimeout(() => {
				func.apply(context, args)
			}, wait)
		}
	}

	let setSettingFn = debounce((data, resolve, reject) => {
		tis.bus.iatoneType = data['iatoneType']
		localStorage.setItem('voiceSetting', JSON.stringify(data))
		request
			.post(`/voice2str/updateAisoundConf`, {
				conf: data
			})
			.then(res => {
				localStorage.setItem('voiceSetting', JSON.stringify(data))
				resolve(res && res.result)
			})
			.catch(reject)
	}, 1000)

	/**
	 * 语音设置
	 * @param {*} data
	 * role: 4:男声 3:女生
	 * speed: 语速
	 * tone: 语调
	 * volume: 音量
	 *
	 * iatoneType:1：命令词模式，2：自由说模式，3：实时流模式
	 * broadcast:0：关，1：开
	 * @returns
	 */
	async function setSetting(data) {
		return new Promise((resolve, reject) => {
			setSettingFn(data, resolve, reject)
		})
	}

	/**
	 * 获取语音助手设置信息
	 * @returns
	 */
	async function getSetting() {
		return new Promise((resolve, reject) => {
			// let data = JSON.parse(localStorage.getItem('voiceSetting') || '""') || {}
			// tis.bus.iatoneType = data['iatoneType'] || 2
			// resolve(data)
			request
				.get(`/voice2str/getAisoundConf`)
				.then(res => {
					resolve({
						...(JSON.parse(localStorage.getItem('voiceSetting') || '""') || {}),
						...(JSON.parse(res.data.confData) || {}),
					})
				})
				.catch(reject)
		})
	}

	/**
	 * 获取合成记录
	 * @param {*} data
	 * @returns
	 */
	async function getFileName(data) {
		return request.post(`voice2str/getfilename`, data)
	}

	/**
	 * 清除识别记录
	 * @returns
	 */
	async function cleanTTSRes() {
		return request.get('/voice2str/cleanTTSRes')
	}

	/**
	 * 获取历史记录
	 * @returns
	 */
	async function getHistory() {
		return request.get('/voice2str/getHistory')
	}

	async function getttsFilesrc() {
		return request.get('/voice2str/getttsfilesrc')
	}

	/**
	 * 语音播报
	 * @param {*} data
	 * data.id 调用应用的ID标识
	 * data.level
	 * data.src
	 */
	async function voicePlay(data) {
		// 支持 队列 优先级
		voicePlayer.src = ''
		voicePlayer.play()

		let gAudio = document.getElementById('gAudio')
		if (gAudio) {
			gAudio = document.createElement('audio')
			gAudio.setAttribute('id', 'gAudio')
			gAudio.controls = true
			gAudio.onended = function() {
				let playList = JSON.parse(sessionStorage.getItem('playList') || '[]')
				if (playList.length) {
					let _data = playList.pop()
					gAudio.src = _data.src
					gAudio.play()
				}
			}
		}
		let playList = JSON.parse(sessionStorage.getItem('playList') || '[]')
		if (gAudio.ended && !playList.length) {
			gAudio.src = data.src
			gAudio.play()
		} else {
			playList.push(data)
			playList = playList.sort((a, b) => a.level - b.level)
			sessionStorage.setItem('playList', JSON.stringify(playList))
		}
	}

	// 开始录音
	function recordStart() {
		return new Promise((resolve, reject) => {
			try {
				console.log('张帅开始录音')
				if (navigator.userAgent.match(/(SyberOS)/i)) {
					//   // pad 圆心
					syberh.record.start({
						success: result => {
							aacFilePath = result.path
							resolve()
						},
						fail: reject,
					})
				} else if (this.device == 'mobile') {
					console.log('pass')
					// if(this.mobileVersion == 'mate20') {
					//     window.parent.GeoBOS.media.AudioRecorder.getInstance((err, recorder) => {
					//         if (err) {
					//             return console.log(err.message);
					//         }
					//         // 监听所有异常事件
					//         recorder.on('error', function (err) {
					//             console.log('error:' + err.message);
					//         });
					//         // 监听暂停录音事件
					//         recorder.on('pause', function () {
					//             console.log('pause:');
					//         });
					//         // 监听恢复录音事件
					//         recorder.on('resume', function () {
					//             console.log('resume:');
					//         });
					//         // 监听开始录音事件
					//         recorder.on('start', () => {
					//             this.recordTimeInterval = setInterval(() => {
					//                 this.duration = parseFloat(this.duration) + 1
					//                 console.log("setInterval this.duration:", this.duration)
					//                 // this.duration = parseFloat(this.duration).toFixed(2)
					//                 if (this.duration.toFixed(0) >= 60) {
					//                     // 结束录音
					//                     clearInterval(this.recordTimeInterval)
					//                     this.recordEnd();
					//                 }
					//             }, 1000)
					//         });
					//         // 监听停止录音事件
					//         recorder.on('stop', (filePath) => {
					//             console.log('stop with audio file:' + filePath);
					//             this.aacFilePath = filePath.replace("file:", "");
					//             console.log("停止录音，接收录音文件的绝对路径", this.aacFilePath)
					// syberh.record.start({
					// 	success: result => {
					// 		aacFilePath = result.path
					// 		resolve()
					// 	},
					// 	fail: reject,
					// })
					//         });
					//         // 开始录音
					//         recorder.record();
					//         // 暂停录音
					//         recorder.pause();
					//         // 恢复录音
					//         recorder.resume();
					//
					//     });
					// }else if(esapp.mobileVersion == 'mate10'){
					//     atelier.audiorecorder.create().then(function(){
					//         console.log("audio record crreate success");
					//     }).catch(function(res){
					//         console.log("audio recorder create fail:"+res.errorCode)
					//     })
					//
					//     atelier.audiorecorder.start({
					//         audioSource:"MIC",
					//         outputFormat:"AAC_ADTS",
					//         audioEncoder:"AAC"
					//     }).then((res)=>{
					//         console.log("audio recorder start file name:"+res)
					//         this.aacFilePath = res
					//     }).catch(function(err){
					//         console.log("audio recorder start fail:",err)
					//     })
					//
					// }
				} else {
					Recorder.getPermission()
						.then(() => {
							recorder
								.start()
								.then(resolve)
								.catch(reject)
						})
						.catch(reject)
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	// 录音结束
	function recordEnd() {
		return new Promise((resolve, reject) => {
			try {
				if (navigator.userAgent.match(/(SyberOS)/i)) {

					syberh.record.stop({
						success: resolve,
						fail: reject,
					})

					aacVoice({
							aacFilePath: aacFilePath,
							iatoneType: tis.bus.iatoneType
						})
						.then(resolve)
						.catch(reject)
				} else if (this.device == 'mobile') {
					console.log('pass')
					// if(esapp.mobileVersion == 'mate20') {
					//     window.parent.GeoBOS.media.AudioRecorder.getInstance((err, recorder) => {
					//         recorder.stop();
					//     })
					// }else if(esapp.mobileVersion == 'mate10'){
					//     atelier.audiorecorder.stop().then(()=>{
					//         console.log(" atelier.audiorecorder.stop")
					//
					//     })
					//   console.log("停止录音，接收录音文件的绝对路径", this.aacFilePath)
					// syberh.record.start({
					// 	success: result => {
					// 		aacFilePath = result.path
					// 		resolve()
					// 	},
					// 	fail: reject,
					// })
					//     .then(res => {
					//       this.resordStopHandle(res)
					//     })
					//     .catch(err => {
					//         console.log(err)
					//       this.resdata = "我不知道你在说什么";
					//       this.playAudio(this.resdata)
					//     })
					// }
				} else {
					recorder.stop()
					// recorder.stopStream()
					resolve(recorder.getWAVBlob())
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	/**
	 * 音频转文字
	 * @param {*} data
	 */
	function voide2Str(data, iatoneType = 2) {
		return new Promise((resolve, reject) => {
			try {
				if (navigator.userAgent.match(/(SyberOS)/i)) {
					aacVoice(data)
						.then(resolve)
						.catch(reject)
				} else if (this.device == 'mobile') {
					console.log('pass')
					aacVoice(data)
						.then(resolve)
						.catch(reject)
				} else {
					//  iatoneType: 0：实时流模式,1：命令词模式，2：自由说模式
					if (iatoneType == 2) {
						freeVoice(data)
							.then(resolve)
							.catch(reject)
					} else {
						voice(data)
							.then(resolve)
							.catch(reject)
					}
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	tis.voice = {
		getVoice,
		free_voice2str,
		getCommandWord,
		aacVoice,
		getBnf,
		addBnf,
		updateBnf,
		getBaseIE,
		deleteBnf,
		recorder,
		freeVoice,
		voice,
		getFileName,
		getSetting,
		setSetting,
		cleanTTSRes,
		getHistory,
		getttsFilesrc,
		voicePlayer,
		voicePlay,
		recordStart,
		recordEnd,
		voide2Str,
	}
}
