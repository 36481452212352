<template>
  <!-- <div class="widgets-view">
    <div class="right" @click="onActive('right')" :class="{active:activePos === 'right'}">
      <template v-for="(w, index) in list">
        <div v-if="w.position === 'right'" :key="index" class="item">
          <component :is="Widget" :widget="w" />
        </div>
      </template>
    </div>
    <div class="bottom" @click="onActive('bottom')" :class="{active:activePos === 'bottom'}">
      <template v-for="(w, index) in list">
        <div v-if="w.position === 'bottom'" :key="index" class="item">
          <component :is="Widget" :widget="w" />
        </div>
      </template>
    </div>
    <div class="custom" @click="onActive('custom')" :class="{active:activePos === 'custom'}">
      <template v-for="(w, index) in list">
        <div v-if="w.position === 'custom'" :key="index" class="item">
          <component :is="WidgetDR" :widget="w" />
        </div>
      </template>
    </div>
  </div> -->
  <div class="wrap">
    <div class="two" v-if="splitScreen == 2">
      <div class="item" v-for="(item, index) in splitScreen" :key="index">
        <component :is="WidgetDR" :widget="cusWidgets" />
      </div>
    </div>
    <div class="three" v-if="splitScreen == 3">
      <div class="item" v-for="(item, index) in splitScreen" :key="index">
        <component :is="WidgetDR" :widget="cusWidgets" />
      </div>
    </div>
    <div class="four" v-if="splitScreen == 4">
      <div class="item" v-for="(item, index) in splitScreen" :key="index">
        <component :is="WidgetDR" :widget="cusWidgets" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      splitScreen: 2,
      cusWidgets: [],
    };
  },
  computed: {
    list() {
      return this.$tis.getApp();
    },
    WidgetDR() {
      return this.$tis.layouts.WidgetDR;
    },
  },
  mounted() {
    //  this.$tis.openWidget('widget_demo_3');
    this.splitScreen = Number(localStorage.getItem("splitScreen"));
    let widgets = this.$tis.getWidgets();
    let cusWidgets = widgets.filter((item) => item.id == "widget_demo_1")[0];
    //  console.log('自定义浮窗——————————————-',this.cusWidgets)
    this.cusWidgets = { ...cusWidgets, opened: true };
  },
  methods: {},
};
</script>

<style scoped>
.wrap,
.two,
.three,
.four {
  width: 100%;
  height: 800px;
  display: flex;
}
.wrap {
  /* background:rgba(0, 0, 0, 0.3); */
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 999999;
}
.two .item {
  width: 40%;
  margin: 5%;
  height: 300px;
  border: 1px solid red;
}
</style>
