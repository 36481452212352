<template>
	<VueDragResize ref="VueDragResize" v-show="widget.opened" :isDraggable="isDraggable" parentLimitation
		:isResizable="isResizable" :parentW="parentW" :parentH="parentH" :minh="minh" :minw="minw" :x="left" :y="top"
		:w="width" :h="height" v-on:resizing="resize" v-on:dragging="resize" class="drag-container"
		:class="{ 'full-screen': isfullScreen }">
		<el-card class="card">
			<div slot="header" class="header">
				<div class="name">{{ widget.name }}</div>
				<div class="btns">
					<span>
						<el-button type="text" size="small" @click="toggle">
							浮窗大小
						</el-button>
					</span>
					&nbsp;
					<div class="switch-full-screen-btn" @click.stop="handleSwitchWindow">
						<!-- 浮窗 -->
						<i class="iconfont icon-qiehuan1" v-if="isfullScreen"></i>
						<!-- 全屏 -->
						<i class="iconfont icon-quanping1" v-else></i>
					</div>
					&nbsp;
					<span>
						<el-button type="text" size="small" @click="onClose">
							关闭
						</el-button>
					</span>
				</div>

				<el-card v-show="showSizeChoice" class="size-choice">
					<div>
						<el-radio v-model="quickSize" label="small">小</el-radio>
					</div>
					<el-radio v-model="quickSize" label="medium">中</el-radio><br />
					<el-radio v-model="quickSize" label="large">大</el-radio><br />
				</el-card>
			</div>
			<div class="content">
				<div :id="widget.containerId"></div>
			</div>
		</el-card>
	</VueDragResize>
</template>

<script>
	import VueDragResize from "vue-drag-resize";

	const SIZES = {
		small: {
			width: 280,
			height: 230
		},
		medium: {
			width: 280,
			height: 500
		},
		large: {
			width: 280,
			height: 800
		},
	};
	export default {
		props: {
			widget: Object,
		},
		components: {
			VueDragResize,
		},
		computed: {
			parentW() {
				return window.outerWidth;
			},
			parentH() {
				return window.outerHeight;
			},
			isDraggable() {
				return typeof this.widget.isDraggable === "boolean" ?
					this.widget.isDraggable :
					true;
			},
			isResizable() {
				return typeof this.widget.isResizable === "boolean" ?
					this.widget.isResizable :
					true;
			},
			minh() {
				return typeof this.widget.minh === "number" ? this.widget.minh : 40;
			},
			minw() {
				return typeof this.widget.minw === "number" ? this.widget.minw : 280;
			},
			remember() {
				return typeof this.widget.remember === "boolean" ?
					this.widget.remember :
					true;
			},
			quickSize: {
				set(s) {
					this.showSizeChoice = false;
					this.onChangeSize(s);
				},
				get() {
					for (let k in SIZES) {
						const {
							width,
							height
						} = SIZES[k];
						if (width === this.width && this.height === height) {
							return k;
						}
					}
					return "";
				},
			},
		},
		data() {
			let {
				width,
				height,
				top,
				left
			} = this.widget;
			width = typeof width === "number" ? width : 280;
			height = typeof height === "number" ? height : 272;
			top = typeof top === "number" ? top : 80; //window.outerHeight / 2 - height / 2 - 100;
			left = typeof left === "number" ? left : window.outerWidth / 2 - width / 2;

			if (this.remember !== false) {
				try {
					let value = localStorage.getItem(this.widget.id);
					if (value) {
						value = JSON.parse(value);
						width = value.width;
						height = value.height;
						top = value.top;
						left = value.left;
					}
				} catch (_) {
					console.error(_);
				}
			}

			return {
				isfullScreen: false,
				showSizeChoice: false,
				width,
				height,
				top,
				left,
			};
		},
		mounted() {
			window.vm = this;
		},
		methods: {
			syncSet(key, val) {
				return new Promise((resolve) => {
					this[key] = val;
					this.$nextTick(resolve);
				});
			},
			async handleSwitchWindow() {
				//关闭当前浮窗
				this.$tis.closeWidget(this.widget.id);
				//打开default_demo浮窗  backCustomizeWidget用于标记是否是浮窗的最大化
				//this.$tis.openApp('default_demo',{isBackwidget:true,id:that.widget.id});

				//参数的方式行不通  用sessionStorage的方式实现
				sessionStorage.setItem('backCustomizeWidget', this.widget.id);
				this.$tis.openApp('default_demo')

				// 以下是之前的代码。
				// this.isfullScreen = !this.isfullScreen;
				// let data = {};
				// if (this.isfullScreen) {
				//   data = {
				//     width: document.querySelector("body").offsetWidth,
				//     height: window.outerHeight,
				//     top: 0,
				//     left: 0,
				//   };
				//   let keys = Object.keys(data);
				//   for (let i = 0; i < keys.length; i++) {
				//     await this.syncSet(keys[i], data[keys[i]]);
				//   }
				//   this.$refs.VueDragResize.right = 0;
				// } else {
				//   data = {
				//     ...SIZES["medium"],
				//     top: 80,
				//   };
				//   data.left = window.outerWidth / 2 - data.width / 2;

				//   let keys = Object.keys(data);
				//   for (let i = 0; i < keys.length; i++) {
				//     await this.syncSet(keys[i], data[keys[i]]);
				//   }
				// }
			},
			resize(newRect) {
				this.width = newRect.width;
				this.height = newRect.height;
				this.top = newRect.top;
				this.left = newRect.left;
				if (this.remember !== false) {
					localStorage.setItem(
						this.widget.id,
						JSON.stringify({
							...newRect,
						})
					);
				}
			},
			onClose() {
				sessionStorage.removeItem('backCustomizeWidget');
				this.$tis.closeWidget(this.widget.id);
			},
			toggle() {
				this.showSizeChoice = !this.showSizeChoice;
			},
			onChangeSize(size) {
				const v = SIZES[size];
				this.resize({
					width: this.width,
					height: this.height,
					top: this.top,
					left: this.left,
					...v,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.drag-container {
		overflow: hidden;

		&.full-screen {
			::v-deep .content-container {
				background: rgba(0, 0, 0, 0.6);
			}
		}
	}

	.card {
		border: none;
		background: rgba(0, 0, 0, 0.6);

		.header {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.name {
				margin: 5px 0;
				font-size: 14px;
			}

			.size-choice {
				position: absolute;
				top: 34px;
				right: 28px;
				padding: 10px 0 10px 10px;
			}

			.btns {
				display: flex;
				align-items: center;
			}
		}

		::v-deep {
			.el-card__header {
				padding: 5px 10px;
				cursor: pointer;
				color: #fff;
				border: none;

				.name {
					font-size: 15px;
				}
			}

			.el-card__body {
				padding: 0;
			}
		}
	}

	.switch-full-screen-btn {
		display: flex;

		i {
			font-size: 20px;
		}
	}
</style>
