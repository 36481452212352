<template>
  <div class="task-kanban">
    <span class="task-title">球队各人员任务</span>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
      :key="item.lable"
      v-for="item in taskData"
      :label="item.lable"
      :name="item.lable"
    >
      <ul class="info-list">
        <li 
          :class="['item',current == index ? 'itemActive' : '']" 
          @click="handleShow(i,index)"  
          :key="index"
          v-for="(i,index) in item.data"
        >
          <p class="item-title">{{i.title}}</p>
          <p class="item-text">{{i.content}}</p>
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "待查看",//默认选中待查看
      isVisible: true, //是否显示消息框
      content:'', //回复的输入的内容
      taskData: [
        {
          lable: "待查看",
          id: 1,
          data:[
            {
            id:1,
            title: "球队各人员任务",
            content: "组织后卫负责对球队局势的掌控和运营1",
            isHasReply: false
            },
            {
            id:1,
            title: "球队各人员任务",
            content: "得分后卫要有持球单打能力或是作为一个极为稳定的接球跳投手2",
            isHasReply: true
            },
            {
            id:2,
            title: "球队各人员任务",
            content: "小前锋最根本的要求就是要能得分而且是较远距离的得分。3",
            isHasReply: true
            },
            {
            id:1,
            title: "球队各人员任务",
            content: "大前锋在队上担任的任务几乎都是以苦工为主要抢篮板防守卡位4",
            isHasReply: true
            },
            {
            id:2,
            title: "球队各人员任务",
            content: "中锋在禁区内活动篮板球阻攻盖帽是绝对不可或缺的5",
            isHasReply: true
            }
          ]
        },
        {
          lable: "待完成",
          id: 2,
          data:[
            {
            id:1,
            title: "球队各人员任务",
            content: "得分后卫要有持球单打能力或是作为一个极为稳定的接球跳投手2",
            isHasReply: true
            },
            {
            id:2,
            title: "球队各人员任务",
            content: "小前锋最根本的要求就是要能得分而且是较远距离的得分。3",
            isHasReply: true
            }
          ]
        },
        {
          lable: "历史任务",
          id: 3,
          data:[
            {
            id:1,
            title: "球队各人员任务",
            content: "大前锋在队上担任的任务几乎都是以苦工为主要抢篮板防守卡位4",
            isHasReply: true
            },
            {
            id:1,
            title: "球队各人员任务",
            content: "中锋在禁区内活动篮板球阻攻盖帽是绝对不可或缺的5",
            isHasReply: false
            },
            {
            id:1,
            title: "球队各人员任务",
            content: "主教练针对球队打法的指挥使球队赢下胜利拿总冠军戒指6",
            isHasReply: false
            }
          ]
        }
      ],
      objData: '',
      current: 1
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    //显示弹出层
    handleShow(){
      // obj,index
      // this.isVisible = true
      // console.log(obj.content, '2333')
      // this.objData = obj
      // this.current = index
      this.$tis.openDialogBox()
    },
    handleClick(tab, event) {
      console.log(tab, event);
       this.current = 10000
    },
  },
};
</script>

<style lang="scss" scoped>
.task-kanban{
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 0;
  height: 100%;
  width: 464px;
  border-top:1px solid #D1A311;
  // font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;
  .task-title {
    color: #fff;
    font-weight: bold;
    padding: 0 25px 10px;
    font-size: 20px;
    display: block;
  }
  .info-list {
      .item{
        padding: 10px 20px;
        font-size: 18px;
        line-height: 26px;
        border: 1px solid none;
        font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;
        border: 1px solid transparent;
        .item-title {
          font-weight: bold;
          color: #fff;
        }
        .item-text {
          font-size: 14px;
          color: rgba(255,255,255,0.38)
        }
      }
      .item:hover, .itemActive {
        // border: 1px solid #f7c829;
        background: rgba(247,200,41,0.27);
        border: 1px solid #F7C829;
        box-shadow: inset 0 0 16px 2px rgba(247,200,41,0.79);
      }
  }
}

::v-deep {

  .el-tabs__active-bar {
    background: none;
  }
  .el-tabs__item.is-active {
    background: #f7c829;
    color: #343434;
  }
  .el-tabs__nav-wrap::after{
    background: none;
  }
  .el-tabs__nav-scroll {
    background: rgba(0,0,0,0.3);
  }
  .el-tabs__nav-scroll {
    margin: 20px 20px 0;
  }
  .el-tabs__item{
    width: 33.33%;
    text-align: center;
    padding: 0;
    font-weight: bold;
    color: rgba(255,255,255,0.35);
    font-size: 16px;
  }
  .el-tabs__nav {
    width: 100%;
  }
  .el-tabs__content {
    padding: 8px 0 0;
  }
 
}
</style>
