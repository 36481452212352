<template>
  <div class="mining">
    <div class="miningpoint">
      <div class="mining-top">
        <img
          src="../assets/左箭头.png"
          alt=""
          class="img"
          @click.stop="gotoMining"
        />
        <div class="mining-topOne" @click.stop="gotoMining">返回</div>
      </div>
      <div class="mining-right">
        <img src="../assets/定位1.png" alt="" />
        <div class="mining-rightOne" @click="toggLocation">定位</div>
      </div>
    </div>
    <ul class="uu">
      <li @click="cur = 0" :class="{ active: cur == 0 }">
        <div class="choose">
          <label class="radio">经度坐标<input type="radio" name="radio" value="1" checked /><i></i></label>
        </div>
      </li>
      <li @click="cur = 1" :class="{ active: cur == 1 }">
        <div class="chooseOne">
          <label class="radio">纬度坐标<input type="radio" value="2" name="radio" /><i></i></label>
        </div>
      </li>
    </ul>

    <div class="uumaining">
      <ul>
        <li v-show="cur == 0">
          <!-- 经度 -->
          <div class="longitude-top">
            <div class="longitude-topOne">经度:</div>
            <div class="longitude-topTwo">
              <input type="text" placeholder="121" />
              <div>度</div>
            </div>
            <div class="longitude-topThree">
              <input type="text" placeholder="10" />
              <div>分</div>
            </div>
            <div class="longitude-topFour">
              <input type="text" placeholder="55" />
              <div>秒</div>
            </div>
            <div class="longitude-topFive">
              <input type="text" placeholder="648" />
            </div>
          </div>
          <!-- 纬度 -->
          <div class="longitude-bottom">
            <div class="longitude-bottomOne">纬度:</div>
            <div class="longitude-bottomTwo">
              <input type="text" placeholder="121" />
              <div>度</div>
            </div>
            <div class="longitude-bottomThree">
              <input type="text" placeholder="10" />
              <div>分</div>
            </div>
            <div class="longitude-bottomFour">
              <input type="text" placeholder="55" />
              <div>秒</div>
            </div>
            <div class="longitude-bottomFive">
              <input type="text" placeholder="648" />
            </div>
          </div>
          <!-- 高度 -->
          <div class="height">
            <div class="height-one">高度:</div>
            <input type="text" placeholder="0" />
            <div class="height-two">米</div>
          </div>
        </li>
        <li v-show="cur == 1">
          <div class="angle-top">
            <div class="angle-topOne">X:</div>
            <input type="text" placeholder="0" />
            <div class="angle-topTwo">米</div>
          </div>
          <div class="angle-middle">
            <div class="angle-middleOne">Y:</div>
            <input type="text" placeholder="0" />
            <div class="angle-middleTwo">米</div>
          </div>
          <div class="angle-bottom">
            <div class="angle-bottomOne">H:</div>
            <input type="text" placeholder="0" />
            <div class="angle-bottomTwo">米</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="bottomLine"></div>
    <!-- 速度 -->
    <div class="speed">
      <div class="speed-one">
        <div class="speed-onespeed">速度:</div>
        <input type="text" placeholder="0" />
        <div class="speed-twospeed">米/秒</div>
      </div>
      <!-- 风向 -->
      <div class="wind-two">
        <div class="wind-onewind">方向:</div>
        <input type="text" placeholder="0" />
        <div class="wind-twowind">密位</div>
      </div>
    </div>   
    
    <button class="miningBtn">确认</button>
    <div class="mining-one"></div>
    <div class="mining-two"></div>
    <div class="mining-three"></div>
    <div class="mining-fore"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      cur: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    gotoMining() {
      this.$tis.togMiningTo();
      this.$tis.togglelocationArtificial();
    },
    toggLocation() {
      alert("暂无定位信息");
    },
  },
};
</script>

<style lang='scss' scoped>
.mining {
  width: 368px;
  height: 430px;
  // background: red;
}
.miningpoint {
  display: flex;
  .mining-top {
    display: flex;
    margin-top: 10px;
    margin-left: 8px;
    .img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .mining-topOne {
      margin-left: 5px;
      width: 40px;
      font-size: 18px;
      color: #ffdd55;
      cursor: pointer;
    }
  }
  .mining-right {
    display: flex;
    margin-top: 10px;
    margin-left: 220px;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 5px;
    }
    .mining-rightOne {
      width: 50px;
      height: 20px;
      color: #ffdd55;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
.uu {
  list-style: none;
  margin-top: 50px;
  margin-left: 10px;
  li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
    .inputOne {
      margin-left: -2px;
      margin-right: 5px;
      cursor: pointer;
    }
    .inputTwo {
      margin-left: -2px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  li:hover {
    color: #ffdd55;
  }
}
.uumaining {
  position: relative;
  left: 10px;
  top: -50px;
  ul {
    list-style: none;
    li {
      // border: 1px solid red;
      width: 360px;
      height: 180px;
      // background: red;
    }
  }
}
.longitude-top {
  display: flex;
  margin-top: 30px;
  margin-left: 0px;
  .longitude-topOne {
    font-size: 16px;
    color: #ffffff;
    width: 50px;
    margin-top: 5px;
  }
  .longitude-topOne {
    font-size: 16px;
    color: #ffffff;
    width: 50px;
    margin-top: 5px;
  }
  .longitude-topTwo {
    display: flex;
    margin-left: 0px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
      margin-top: 3px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .longitude-topThree {
    display: flex;
    margin-left: 10px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
      margin-top: 3px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .longitude-topFour {
    display: flex;
    margin-left: 10px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
      margin-top: 3px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .longitude-topFive {
    display: flex;
    margin-left: 10px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
      margin-top: 3px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
  }
}
.longitude-bottom {
  display: flex;
  margin-top: 30px;
  margin-left: 0px;
  .longitude-bottomOne {
    font-size: 16px;
    color: #ffffff;
    margin-top: 5px;
    width: 50px;
  }
  .longitude-bottomTwo {
    display: flex;
    margin-left: 0px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      margin-top: 3px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .longitude-bottomThree {
    display: flex;
    margin-left: 10px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      margin-top: 3px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .longitude-bottomFour {
    display: flex;
    margin-left: 10px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      margin-top: 3px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .longitude-bottomFive {
    display: flex;
    margin-left: 10px;
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 45px;
      height: 20px;
      margin-top: 3px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
  }
}
.height {
  display: flex;
  margin-top: 30px;
  margin-left: 0px;
  .height-one {
    font-size: 16px;
    margin-top: 5px;
  }
  input {
    background: #292929;
    border: 1px solid #979797;
    width: 100px;
    height: 20px;
    font-size: 16px;
    text-align: center;
    color: #ffff;
    margin-top: 3px;
    margin-left: 10px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }
  .height-two {
    font-size: 16px;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.angle-top {
  display: flex;
  margin-top: 30px;
  .angle-topOne {
    margin-left: 5px;
    margin-top: 5px;
  }
  input {
    background: #292929;
    border: 1px solid #979797;
    width: 265px;
    height: 25px;
    font-size: 16px;
    text-align: center;
    color: #ffff;
    margin-left: 15px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }
  .angle-topTwo {
    margin-left: 15px;
    margin-top: 5px;
  }
}
.angle-middle {
  display: flex;
  margin-top: 30px;
  .angle-middleOne {
    margin-left: 5px;
    margin-top: 5px;
  }
  input {
    background: #292929;
    border: 1px solid #979797;
    width: 265px;
    height: 25px;
    font-size: 16px;
    text-align: center;
    color: #ffff;
    margin-left: 15px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }
  .angle-middleTwo {
    margin-left: 15px;
    margin-top: 5px;
  }
}
.angle-bottom {
  display: flex;
  margin-top: 30px;
  .angle-bottomOne {
    margin-left: 5px;
    margin-top: 5px;
  }
  input {
    background: #292929;
    border: 1px solid #979797;
    width: 265px;
    height: 25px;
    font-size: 16px;
    text-align: center;
    color: #ffff;
    margin-left: 15px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }
  .angle-bottomTwo {
    margin-left: 15px;
    margin-top: 5px;
  }
}
.bottomLine {
  border: 1px solid #ccc;
  width: 99.5%;
  position: relative;
  top: -50px;
}
.speed {  
  margin-top: -20px;
  margin-left: 2px;
  .speed-one {
    display: flex;
    .speed-onespeed {
      font-size: 16px;
      margin-left: 15px;
      margin-top: 3px;
    }
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 100px;
      height: 25px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
      margin-left: 18px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    .speed-twospeed {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 3px;
    }
  }
  .wind-two {
    display: flex;
    margin-top: 20px;
    .wind-onewind {
      font-size: 16px;
      margin-left: 15px;
      margin-top: 3px;
    }
    input {
      background: #292929;
      border: 1px solid #979797;
      width: 100px;
      height: 25px;
      font-size: 16px;
      text-align: center;
      color: #ffff;
      margin-left: 18px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
    }
    .wind-twowind {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 3px;
    }
  }
}
.miningBtn {
  width: 72px;
  height: 40px;
  background: #ffcc00;
  font-size: 16px;
  color: #313131;
  text-align: center;
  border: none;
  position: relative;
  top: -10px;
  left: 275px;
}
.mining-one {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-bottom: none;
  border-right: none;
}
.mining-two {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  right: 0.5px;
  border-bottom: none;
  border-left: none;
}
.mining-three {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 408px;
  right: 0.5px;
  border-top: none;
  border-left: none;
}
.mining-fore {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 408px;
  left: 0px;
  border-top: none;
  border-right: none;
}

.choose {
  position: relative;
  left: 80px;
  top: -30px;
}
.choose .radio {
  position: relative;
  display: inline-block;
  font-weight: 400;
  color: #0c4757;
  padding-left: 25px;
  cursor: pointer;
  width: 80px;
  color: #fff;
  font-size: 16px;
  padding-top: 5px;
}
.choose .radio input {
  position: absolute;
  left: -9999px;
}
.choose .radio i {
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 15px;
  height: 15px;
  outline: 0;
  border: 1px solid #e4e4e4;
  background: #ffffff;
  border-radius: 50%;
  transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
}
.choose .radio input + i:after {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color:#f7c829;
    opacity: 0;
    transition: opacity .1s;
    -webkit-transition: opacity .1s;
}
.choose .radio input:checked + i:after {
  opacity: 1;
}


.chooseOne {
  position: relative;
  left: 180px;
  top: -60px;
}
.chooseOne .radio {
  position: relative;
  display: inline-block;
  font-weight: 400;
  color: #0c4757;
  padding-left: 25px;
  cursor: pointer;
  width: 80px;
  color: #fff;
  font-size: 16px;
  padding-top: 5px;
}
.chooseOne .radio input {
  position: absolute;
  left: -9999px;
}
.chooseOne .radio i {
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 15px;
  height: 15px;
  outline: 0;
  border: 1px solid #e4e4e4;
  background: #ffffff;
  border-radius: 50%;
  transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
}
.chooseOne .radio input + i:after {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color:#f7c829;
    opacity: 0;
    transition: opacity .1s;
    -webkit-transition: opacity .1s;
}
.chooseOne .radio input:checked + i:after {
  opacity: 1;
}
</style>