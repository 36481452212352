<template>
  <div class="apps-panel">
    <div class="header">
      <div class="title">应用列表</div>
      <el-button
        @click="close"
        type="danger"
        size="mini"
        icon="el-icon-close"
      ></el-button>
    </div>
    <div v-for="(section, index) in sections" :key="index">
      <div class="title">{{ section.title }}</div>
      <div class="content">
        <div
          v-for="app in section.apps"
          :key="app.id"
          class="item"
          @click="onOpenApp(app)"
        >
          <div class="name">{{ app.name }}</div>
          <img :src="app.icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    allApps() {
      return this.$tis.getApps().filter((a) => !a.default);
    },
    recentOpenApps() {
      // 最近打开的5个
      return [...this.allApps]
        .filter((a) => a.lastOpenTime > 0)
        .sort((a, b) => {
          return b.lastOpenTime - a.lastOpenTime;
        })
        .slice(0, 5);
    },
    mostOpenApps() {
      // 最常打开的5个
      return [...this.allApps]
        .filter((a) => a.openTimes > 0)
        .sort((a, b) => {
          return b.openTimes - a.openTimes;
        })
        .slice(0, 5);
    },
    sections() {
      return [
        {
          title: "最近打开",
          apps: this.recentOpenApps,
        },
        {
          title: "最常使用",
          apps: this.mostOpenApps,
        },
        {
          title: "全部应用",
          apps: this.allApps,
        },
      ].filter((s) => s.apps.length > 0);
    },
  },
  methods: {
    close() {
      this.$tis.closeAppsPanel();
    },
    onOpenApp(app) {
      
      this.$tis.openApp(app.id);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.apps-panel {
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 18px;
      padding: 10px;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      cursor: pointer;
      .name {
        text-align: center;
        font-size: 15px;
        margin: 10px;
      }
      img {
        border: none;
        display: block;
        margin: 0;
        width: 150px;
        height: 150px;
      }
      margin: 10px;
      border: 1px solid #ffffff;
    }
  }
}
</style>