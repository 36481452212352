import { render, staticRenderFns } from "./TaskKanban.vue?vue&type=template&id=a99f6a98&scoped=true&"
import script from "./TaskKanban.vue?vue&type=script&lang=js&"
export * from "./TaskKanban.vue?vue&type=script&lang=js&"
import style0 from "./TaskKanban.vue?vue&type=style&index=0&id=a99f6a98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a99f6a98",
  null
  
)

export default component.exports