<template>
  <div class="notification-whole">
    <div class="caption">
      <span class="clear" @click="handleClear">清空</span>

      <span class="whole" @click="onTabfeiter">
        全部
        <i class="el-icon-caret-bottom" v-if="istab"></i>
        <i class="el-icon-caret-top" v-else></i>
      </span>


      <div class="tolist" v-if="istab">
        <div
          class="tolistbox"
          v-for="item in sodatalist"
          :key="item.id"
          @click="Tablist(item.id)"
        >
          {{ item.soname }}
        </div>
      </div>

      <!-- 
       <span @click="handleWhole" class="whole">
        全部
        <i class="el-icon-caret-bottom" v-if="isOpen"></i>
        <i class="el-icon-caret-top" v-else></i> 
      </span> -->

    </div>

    <ul class="listWrap" v-if="isOpen">
      <!-- item-gray  标题是待有下边框 -->


      <li
        :class="[
          'item',
          messages[0].delete || messages[0].reply ? 'item-gray' : '',
        ]"
        v-for="(messages, key) in messageData"
        :key="key"
      >
        <div class="item-title">
          <span class="item-title-time">{{
            messages[0].time | timeFormat
          }}</span>

          <span class="item-title-text">{{ messages[0].type }}</span>
        </div>
        <div v-if="messages && messages.length" class="message-wrap">
          <div
            class="item-content-wrap"
            v-for="msg in messages.slice(0, openMap[key] ? messages.length : 1)"
            :key="msg.id"
          >
            <span
              class="item-title-lable"
              :class="levelMap[messages[0].level || 1].color"
              >{{ levelMap[messages[0].level].name }}</span
            >
            <span class="item-content-title" v-if="msg.title">{{
              msg.title
            }}</span>
            <p class="item-content">{{ msg.text }}</p>
            <span class="button-wrap" v-if="msg.reply">
              <span class="replay" @click="handleReplay(msg)">回复</span>
              <span class="delete" @click="handleDelete(msg)">删除</span>
            </span>
            <span class="button-wrap" v-else>
              <span :class="['delete', 'change-lh']" @click="handleDelete(msg)"
                >删除</span
              >
            </span>
          </div>
        </div>
        <!-- 点击展开消息，需要陈迪出UI -->
        <div
          v-if="messages && messages.length > 1"
          class="more"
          @click="toggleFold(key)"
        >
          {{ openMap[key] ? "收起" : `展开更多${messages.length - 1}条消息` }}
        </div>
      </li>
    </ul>
    <div class="openNewsCoreWrap">
      <div class="openNewsCore">
        <span class="btnTxt" @click="openHistoricalNews">打开历史消息</span>
        <span class="btnSting" @click="openWidgetSettingPanel">
          <i class="el-icon-setting"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: true, //全部是否展开，默认展开状态
      istab: false,
      openMap: {},
      levelMap: {
        1: {
          name: "消息",
          color: "bg-gray",
        },
        2: {
          name: "通知",
          color: "bg-yellow",
        },
        3: {
          name: "告知",
          color: "bg-red",
        },
      },
      sodatalist: [
        {
          id: 0,
          soname: "全部",
        },
        {
          id: 1,
          soname: "篮网队",
        },
        {
          id: 2,
          soname: "火箭队",
        },
      ],
      messageList: [
        {
          title: "态势感知",
          level: 3,
          appName: "taishigaizhi",
          component: 1,
          time: "10分钟前",
          text: "",
          id: 1,
          messages: [
            {
              id: 11,
              title: "球员位置",
              text: "态势感知态势感知态势感知态势感知知态势感",
              reply: 1,
            },
            {
              id: 12,
              title: "球员位置",
              text: "态势感知态势感知态势感知态势感知知态势感",
            },
            {
              id: 13,
              text: "无标题样式，态势感知态势感知态势感知态势感知知态势感",
            },
          ],
        },
        {
          title: "态势感知",
          level: 2,
          appName: "taishigaizhi",
          component: 1,
          time: "10分钟前",
          text: "态势感知态势感知态势感知态势感知知态势感",
          id: 2,
          reply: 1,
        },
        {
          title: "态势感知",
          level: 1,
          appName: "taishigaizhi",
          component: 1,
          time: "10分钟前",
          text: "态势感知态势感知态势感知态势感知知态势感",
          id: 2,
          reply: 1,
        },
      ],
      newsData: [
        {
          id: 1,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知知态势感",
          type: "态势感知",
          level: 1, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "态势感知",
          replay: 0, // 是否有回复按钮
        },
        {
          id: 1,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知知态势感",
          type: "态势感知",
          level: 3, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "态势感知",
          replay: 0, // 是否有回复按钮
        },
        {
          id: 1,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知态势感感",
          type: "态势感知",
          level: 3, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "态势感知",
          replay: 0, // 是否有回复按钮
        },
        {
          id: 2,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知态势感知态势",
          type: "态势感知",
          level: 2, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "",
          replay: 0, // 是否有回复按钮
        },
      ],
    };
  },
  filters: {
    timeFormat(timeStamp) {
      timeStamp = isNaN(timeStamp)
        ? +new Date(timeStamp.replace(/-/g, "/"))
        : +timeStamp;
      if (/^\d{10}$/.test(timeStamp + "")) {
        timeStamp *= 1000;
      }
      const now = +new Date();
      let diff = now - timeStamp;
      const label = diff > 0 ? "前" : "后";
      diff = Math.abs(diff);

      if (diff < 5000) {
        return "刚刚";
      } else if (diff < 60 * 1000) {
        return `${Math.round(diff / 1000)}秒${label}`;
      } else if (diff < 60 * 60 * 1000) {
        return `${Math.round(diff / (60 * 1000))}分钟${label}`;
      } else if (diff < 24 * 60 * 60 * 1000) {
        return `${Math.round(diff / (60 * 60 * 1000))}小时${label}`;
      }

      const nowDate = new Date(now);
      const date = new Date(timeStamp);
      const nowYear = nowDate.getFullYear();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const min = date.getMinutes();

      const ret = [];
      if (nowYear !== year) {
        ret.push(year);
      }
      ret.push(month > 9 ? month : `0${month}`);
      ret.push(day > 9 ? day : `0${day}`);

      const foot = [];
      foot.push(hour > 9 ? hour : `0${hour}`);
      foot.push(min > 9 ? min : `0${min}`);

      return `${ret.join("-")} ${foot.join(":")}`;
    },
  },
  computed: {
    messageData() {
      let messageData = {};
      this.$tis.getMessageCenterData().forEach((val) => {
        if (messageData[val.appName]) {
          messageData[val.appName].unshift(val);
        } else {
          messageData[val.appName] = [val];
        }
      });
      return messageData;
    },
  },
  mounted() {},
  methods: {
    // 点击全部进行的操作
    // handleWhole() {
    //   this.isOpen = !this.isOpen;
    // },
    onTabfeiter() {
      this.istab = !this.istab;
      this.isOpen = false;
    },

    // 点击分类获取id
    Tablist(id) {
      console.log(id);
      this.istab = !this.istab;
      if (id == 0) {
        this.isOpen = true;
      }
    },

    //点击清除的时候进行的操作
    handleClear() {
      //this.isOpen = false
      this.$tis.emptyMessage();
      // this.$tis.closeNotificationWhole();
    },
    toggleFold(id) {
      this.openMap[id] = !this.openMap[id];
      this.$forceUpdate();
    },
    //点击删除
    handleDelete(w) {
      this.$tis.delMessage(w.id);
    },
    //点击回复
    handleReplay(message) {
      this.$tis.openDialogBox(message);
    },
    //打开历史消息
    openHistoricalNews() {
      this.$tis.openHistoricalNews();
    },
    //打开设置
    openWidgetSettingPanel() {
      this.$tis.openWidgetSettingPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-whole {
  background: #393939;
  padding: 0 12px 19px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  //font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;

  .tolist {
    width: 70px;
    position: absolute;
    background: #4a4a4a;
    z-index: 9999;
    text-align: center;
    .tolistbox {
      padding: 5px 5px;
    }
    .tolistbox:hover {
      background: chocolate;
    }
  }
  .caption {
    overflow: hidden;
    font-size: 18px;
    line-height: 26px;
    padding: 28px 0 16px;
    flex: none;
    .clear {
      float: right;
      font-size: 16px;
    }
    .clear,
    .whole {
      cursor: pointer;
    }
  }
  .listWrap {
    flex: 1;
    overflow-y: auto;
  }
  .item,
  .item-gray {
    margin: 0 0 10px 0;
    background: #4a4a4a;
    overflow: hidden;
    //min-height: 120px;
    overflow: hidden;
    position: relative;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .item-title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background: #515151;
    font-weight: bold;
    padding: 0 10px;
    &-lable {
      // padding: 2px 8px;
      margin-right: 10px;
      line-height: 18px;
      display: inline-block;
      vertical-align: middle;
      height: 22px;
      line-height: 22px;
      padding: 0 8px;
    }
    &-text {
      font-size: 16px;
    }
    &-time {
      float: right;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .item-gray {
    // background: #5b5b5b;
    // // padding-right: 60px;
    // position: relative;
    // margin: 0 0 10px 0;
    // height: 120px;
    // overflow: hidden;
    .item-title {
      background: none;
      border-bottom: 1px solid #6b6b6b;
    }
  }
  .bg-yellow {
    background: #e3c468;
  }
  .bg-red {
    background: #ca4f51;
  }
  .bg-gray {
    background: #a2a2a2;
  }
  .item-content-wrap {
    position: relative;
    padding: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    line-height: 28px;
    border-bottom: 1px solid #7c7c7c;
    &:last-child {
      border-bottom: 0;
    }
    min-height: 56px;
  }
  .item-content-title {
    color: #fff;
    font-weight: bold;
  }
  .item-title-lable {
    color: #fff;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, "微软雅黑", Arial, sans-serif;
  }
  .button-wrap {
    position: absolute;
    width: 60px;
    color: #fff;
    background: #6b6b6b;
    height: 100%;
    top: 0;
    right: 0;
    font-size: 14px;
    display: none;
    flex-direction: column;
    line-height: 16px;
    overflow: hidden;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
      cursor: pointer;
    }
  }
  .item-content-wrap:hover {
    cursor: pointer;
    background: #515151;
  }
  .item-content-wrap:hover .button-wrap {
    display: flex;
  }
  .item .more:hover {
    color: #dddddd;
    background: #515151;
  }
  .replay {
    border-bottom: 1px solid;
    border-left: 1px solid;
    background: #515151;
    color: #a4a4a4;
  }
  .delete {
    border-left: 1px solid #a4a4a4;
    background: #515151;
    color: #a4a4a4;
  }
  .replay:hover {
    color: #dddddd;
    border-color: #a4a4a4;
  }
  .delete:hover {
    color: #dddddd;
    border-color: #a4a4a4;
  }
  .change-lh {
    line-height: 120px !important;
  }
  .more {
    height: 40px;
    width: 100%;
    background: #4a4a4a;
    border-top: 1px solid #7c7c7c;
    line-height: 42px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    cursor: pointer;
  }
  .openNewsCoreWrap {
    //height: 40px;
    flex: none;
    // padding-top: 12px;
    // background-color: #393939;
    //padding: 12px 10px;
    background-color: #393939;
    right: 0;
    // padding: 12px 0 12px;
    padding-top: 12px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 10px;
  }
  .openNewsCore {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 42px;
    line-height: 42px;
    color: #fff;
    font-size: 18px;
    //padding: 0 12px;
    span {
      display: inline-block;
    }
    .btnTxt {
      width: 314px;
      background: url("../assets/open-msg-bg.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      cursor: pointer;
      margin-left: 11px;
    }
    .btnSting {
      width: 42px;
      height: 40px;
      margin-right: 10px;
      border: 1px solid rgba(251, 251, 251, 0.25);
      text-align: center;
      cursor: pointer;
      // &:hover {
      //   opacity: 1;
      // }
      .el-icon-setting {
        font-size: 26px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 42px;
      }
    }
    .btnSting:hover {
      background: rgba(216, 216, 216, 0.25);
      .el-icon-setting {
        color: #dddddd;
      }
    }
  }
  .message-wrap {
    max-height: 335px;
    overflow-y: auto;
  }
}
</style>
