import hotkeys from 'hotkeys-js';

export default function hotkeysPlugin(tis) {

  // 存储框架占用的快捷键
  tis._platformHotkeys = [];

  // 绑定框架的快捷键
  tis.bindPlatformHotkeys = (config) => {

    tis._platformHotkeys = Object.keys(config);

    for (let k in config) {
      hotkeys(k, (event) => {
        event.preventDefault();

        const cmd = config[k]
        if (typeof tis[cmd] !== "function") {
          alert(`快捷键调用失败，tis不存在${cmd}`)
        } else {
          tis[cmd]()
        }
      })
    }
  }

  // 绑定应用的快捷键
  tis.bindAppHotkeys = (key, handler) => {
    if (tis._platformHotkeys.includes(key)) {
      throw new Error(`${key}快捷键已被框架占用，请更换`)
    }
    
    hotkeys(key, (event) => {
      event.preventDefault();
      handler();
    })
  }

}