<template>
  <div class="footer">
    <div class="left">
      <img
        class="msg"
        :src="`/theme/${$tis.bus.theme}/消息按钮.png`"
        @click.stop="toggleBox"
      />
    </div>
    <div class="zhang-chat" v-if="open">
      <img src="../assets/聊天.png" alt="" v-if="open" class="img" />
    </div>

    <div class="menus">
      <div class="item" @click="onOpenApp('default_demo')">湖人队</div>

      <div class="item" @click="onOpenApp('default_demo')">篮网队</div>

      <div class="item" @click="onOpenApp('default_demo')">勇士队</div>

      <div class="item" @click="onOpenApp('default_demo')">雄鹿队</div>

      <div class="item" @click="onOpenApp()">组件主题</div>

      <div class="item" @click="onOpenApp('default_demo')">热火队</div>
    </div>
    <span class="widgets" @click="openWidgetPanel">
      <img
        :src="`/theme/${$tis.bus.theme}/浮窗图标.svg`"
        style="height: 70px; margin: -25px"
      />
      <span>浮窗</span>
    </span>
    <div class="more" @click="onShowMore">
      <div v-if="showMoreContent" class="content">
        <div v-for="(item, index) in menuList" :key="index">
          <span>{{ item.name }}</span>
          <i
            v-if="menuEditStatus"
            class="iconfont icon-anniu_guanbi"
            @click.stop="handleDeleteMenu(index)"
          ></i>
        </div>
        <div class="edit-btn" @click.stop="handleEditMenu">
          <span v-if="!menuEditStatus">编辑菜单</span>
          <span v-else>保存菜单</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      open: false,
      showMoreContent: false,
      message_array: [],
      menuEditStatus: false,
      menuList: [
        {
          name: "服务管控",
        },
        {
          name: "数据支撑",
        },
        {
          name: "运维监控",
        },
        {
          name: "应用市场",
        },
      ],
      message: "",
    };
  },
  mounted() {
    document.addEventListener("click", (e) => {
      console.log(e.target);
      if (e.target.className != "img") {
        this.open = false;
      }
    });
  },

  computed: {
    allApps() {
      return this.$tis.getApps().filter((a) => !a.default);
    },
    recentOpenApps() {
      // 最近打开的5个
      return [...this.allApps]
        .filter((a) => a.lastOpenTime > 0)
        .sort((a, b) => {
          return b.lastOpenTime - a.lastOpenTime;
        })
        .slice(0, 5);
    },
    mostOpenApps() {
      // 最常打开的5个
      return [...this.allApps]
        .filter((a) => a.openTimes > 0)
        .sort((a, b) => {
          return b.openTimes - a.openTimes;
        })
        .slice(0, 5);
    },
    apps() {
      return this.allApps;
    },
  },
  methods: {
    // 聊天
    dealMessage: function() {
      this.message_array.push(this.message);
      this.message = "";
    },
    //  聊天框显示隐藏
    toggleBox() {
      this.open = !this.open;
    },
    onShowMore() {
      this.showMoreContent = !this.showMoreContent;
    },
    onOpenApp(appId = "app_demo") {
      console.log(appId)
      console.log(localStorage.getItem("splitScreen"));
      // if (localStorage.getItem("splitScreen") == 1 || !localStorage.getItem("splitScreen")) {
      //   this.$tis.openApp(appId);
      // } else {
      //   console.log(222)
      //   this.$tis.openAppScreen();
      // }
      this.$tis.openApp(appId);
      // this.$tis.toggleSearchBox();
    },
    openWidgetPanel() {
      this.$tis.openWidgetPanel();
    },
    handleDeleteMenu(index) {
      this.menuList.splice(index, 1);
    },
    handleEditMenu() {
      this.menuEditStatus = !this.menuEditStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  opacity: var(--footer-opacity);
  border: var(--footer-border);
  background-image: var(--footer-background-image);
  height: 63px;
  display: flex;
  align-items: center;
  .left {
    flex: 1;
    padding-left: 20px;
  }
  .msg {
    width: 80px;
    position: relative;
    top: -20px;
    cursor: pointer;
    z-index: 999;
  }
  .menus {
    display: flex;
    align-items: center;
    .item {
      white-space: nowrap;
      // padding: 18px 30px;
      padding: 18px 2vw;
      font-size: 24px;
      color: var(--footer-item-color);
      position: relative;
      margin: 0 5px;
      cursor: pointer;
      &:hover {
        &:before {
          content: "";
          border: var(--footer-item-hover-border);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: var(--footer-item-hover-background-image);
          box-shadow: var(--footer-item-hover-box-shadow);
          transform: skewX(-26deg);
        }
      }
    }
  }
  .widgets {
    position: relative;
    z-index: 1;
    padding: 18px 30px;
    font-size: 24px;
    cursor: pointer;
    margin-left: 2px;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    span {
      margin-left: 10px;
      text-shadow: var(--status-bar-widget-btn-text-shadow);
      color: var(--status-bar-widget-btn-color);
    }
    &::before {
      border: var(--footer-item-hover-border);
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      transform: skewX(-26deg);
    }
    &:hover {
      &:before {
        background-image: var(--footer-item-hover-background-image);
        box-shadow: var(--footer-item-hover-box-shadow);
      }
    }
  }
  .more {
    margin: 0 20px;
    width: 0;
    height: 0;
    cursor: pointer;
    border-bottom: var(--footer-more-border-bottom);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: relative;
    cursor: pointer;
    .content {
      position: absolute;
      bottom: 50px;
      right: -40px;
      background: rgba(0, 0, 0, 0.8);
      width: 210px;
      text-align: center;
      padding: 10px 0;
      div {
        padding: 20px 0;
        font-size: 24px;
        color: #f1d252;
        position: relative;
        i {
          font-size: 24px;
          margin-left: 10px;
          position: absolute;
          top: 20px;
          color: #bbb;
          right: 20px;
        }
      }
      .edit-btn {
        border-top: 1px solid var(--footer-item-color);
      }
    }
  }
}
.zhang-chat {
  background: rgba(0, 0, 0, 0.7);
  width: 526px;
  height: 168px;
  position: absolute;
  top: -164px;
  left: 22px;
  img {
    width: 100%;
    height: 100%;
  }
}

//css的图标闪烁样式
// .msg {
//     // width: 300px;                                    /* 宽度 */
//     // height: 300px;                                    /* 高度 */
// 	// border:#666 1px solid;
// 	// border-radius:50%;
//     opacity: 0.1;                                   /* 不透明度 */
//     overflow: hidden;                               /* 溢出隐藏 */
//     // background:#FF3;                            /* 背景色 */
//     // margin: 25% auto;                               /* 外边距 */

//     /* IE10、Firefox and Opera，IE9以及更早的版本不支持 */
//     animation-name: breath;                         /* 动画名称 */
//     animation-duration: 1s;                         /* 动画时长3秒 */
//     animation-timing-function: ease-in-out;         /* 动画速度曲线：以低速开始和结束 */
//     animation-iteration-count: infinite;            /* 播放次数：无限 */

//     /* Safari and Chrome */
//     -webkit-animation-name: breath;                 /* 动画名称 */
//     -webkit-animation-duration: 1s;                 /* 动画时长3秒 */
//     -webkit-animation-timing-function: ease-in-out; /* 动画速度曲线：以低速开始和结束 */
//     -webkit-animation-iteration-count: infinite;    /* 播放次数：无限 */
// }

// @keyframes breath {
//     from { opacity: 0.1; }                          /* 动画开始时的不透明度 */
//     50%  { opacity:   1; }                          /* 动画50% 时的不透明度 */
//     to   { opacity: 0.1; }                          /* 动画结束时的不透明度 */
// }

// @-webkit-keyframes breath {
//     from { opacity: 0.1; }                          /* 动画开始时的不透明度 */
//     50%  { opacity:   1; }                          /* 动画50% 时的不透明度 */
//     to   { opacity: 0.1; }                          /* 动画结束时的不透明度 */
// }
</style>
