<template>
  <div class="OperationalInformation-container">
    <div class="status-bar-location reverse">
      <img :src="`/theme/${$tis.bus.theme}/定位.png`" />
    </div>
    <div class="minlu"></div>
    <div class="status-bar-info reverse">
      <div class="wrap">
        <p>
          <span class="item-info">东经</span>
          <span class="item-info">121°10′ 55.648″</span>
        </p>
        <p>
          <span class="item-info">北纬</span>
          <span class="item-info">121°10′ 55.648″</span>
        </p>
      </div>
    </div>

    <div class="status-bar-location reverse">
      <img :src="`/theme/${$tis.bus.theme}/时统.png`" />
    </div>
    <div class="minlu">
      <transition name="slide-fade">
        <div class="pop" v-if="this.$tis.bus.visiblediwd">
          <div class="timeSetting">
            <div class="timeSetting-top">时间设置</div>
            <div class="timeSetting-source">天文时间来源</div>
            <div class="timeSetting-source-one">
              <div
                class="sourceOne"
                v-for="(item, index) in radiolist"
                :key="index"
              >
                <!-- <input type="radio" name="toList" id=""> -->
                <div :class="radio == index ? 'radioOnes' : 'radioOne'"></div>
                <div class="sourceOne-one">{{ item.name }}</div>
              </div>
            </div>
            <div class="timeSetting-timeFight">校准作战时间</div>
            <div class="timeFight">
              <div
                class="timeFight-one"
                v-for="(item1, index) in listTab"
                :key="index"
              >
                <div :class="tabList == index ? 'radioTwos' : 'radioTwo'"></div>
                <div class="timeFight-Astronomy">{{ item1.name }}</div>
              </div>
            </div>
            <div class="time-setup">
              <div class="time-setup-one">设置作战时间</div>
              <div class="time-setup-two">
                通知校时
              </div>
            </div>
            <!-- <div class="time-Choose-one">选择时间</div> -->
            <!-- <input type="text" class="time-Choose-one" placeholder="选择时间" /> -->
            <el-time-select
              v-model="value"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '18:30',
              }"
              placeholder="选择时间"
            >
            </el-time-select>
            <div class="broadcast">
              <div class="broadcast-one">
                <input type="checkbox" name="" checked class="radioSixe" />
                <div class="inCar">车内广播</div>
              </div>
              <button class="time-Btn">确定</button>
            </div>
            <div class="time-one"></div>
            <div class="time-two"></div>
            <div class="time-three"></div>
            <div class="time-fore"></div>
          </div>

          <!-- <img src="../assets/header/时统设置.png"/> -->
          <!-- @click="onToggleTimerSet" -->
        </div>
      </transition>
    </div>
    <div class="status-bar-info reverse">
      <div class="wrap">
        <p>
          <span class="item-info">天文</span>
          <span class="item-info">2013/01/01 14:51:47</span>
        </p>
        <p>
          <span class="item-info">作战</span>
          <span class="item-info">2013/01/01 14:51:47</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
const clickoutside = {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el._vueClickOutside_ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el) {
    document.removeEventListener("click", el._vueClickOutside_);
    delete el._vueClickOutside_;
  },
};
export default {
  props: {},
  data() {
    return {
      value: "",
      dialog1Visible: false,
      dialogVisible: false,
      dialogVisible1: false,
      appCardsVisible: false,
      mingluVisible: false,
      dingweiVisible: false,
      timerSetVisible: false,
      radio: 1,
      radiolist: [
        {
          id: 0,
          name: "上级时间",
        },
        {
          id: 1,
          name: "本机系统时间",
        },
        {
          id: 2,
          name: "北斗",
        },
      ],
      tabList: null,
      listTab: [
        {
          id: 0,
          name: "同步天文时间",
        },
        {
          id: 1,
          name: "同步上级作战时间",
        },
      ],
    };
  },
  directives: { clickoutside },
  computed: {
    isMainApp() {
      // 当前是否是主界面应用
      return this.$tis.isMainApp();
    },
    currentApp() {
      return this.$tis.getCurrentApp();
    },
    messageCounts() {
      return this.$tis.getMessageCount();
    },
  },
  mounted() {
    // this.$tis.openApp('app_demo');
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.OperationalInformation-container {
  display: flex;
  height: 100%;
  width: calc(100vw - 1217px);
  .status-bar-location {
    min-width: 37px;
    cursor: pointer;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    > img {
      height: 56px;
      position: relative;
      top: 0px;
      z-index: 1;
    }
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      background: var(--status-bar-icon-bg);
      width: 2px;
      transform: skewX(-26deg);
    }
    &.reverse {
      &::after {
        transform: skewX(333deg);
      }
    }
  }
  .status-bar-info {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 24px;
    margin: 0 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
      margin: 0;
      &:last-child {
        margin-top: 6px;
      }
    }
    .item-info {
      margin: 0 2px;
    }
  }
}
</style>
