<template>
	<div class="status-bar-2" v-if="isMainApp">
		<div class="left">
			<!-- @click="toggleAppCards" -->
			<div class="action" @click="toggleAppCards">
				<img v-if="appCardsVisible" :src="`/theme/${$tis.bus.theme}/收起.png`" />
				<img v-else src="../assets/home/展开.png" />
			</div>
			<div class="status-bar-location reverse">
				<img :src="`/theme/${$tis.bus.theme}/定位.png`" @click.stop="togglelocationArtificial" />
			</div>
			<div class="minlu">
				<!-- <transition name="slide-fade">
          <div class="pop" v-clickoutside="handleClose">
          
          </div>
        </transition> -->
			</div>
			<div class="status-bar-info reverse" @click.stop="togglelocationArtificial">
				<p>
					<span class="item-info">东经{{ $tis.bus.theme }}</span>
					<span class="item-info">121°10′ 55.648″</span>
				</p>
				<p>
					<span class="item-info">北纬</span>
					<span class="item-info">121°10′ 55.648″</span>
				</p>
			</div>

			<div class="status-bar-location reverse">
				<img :src="`/theme/${$tis.bus.theme}/时统.png`" @click="onToggleTimerSet" />
			</div>
			<div class="minlu">
				<transition name="slide-fade">
					<div class="pop" v-if="this.$tis.bus.visiblediwd" v-clickoutside="handleClose">
						<div class="timeSetting">
							<div class="timeSetting-top">时间设置</div>
							<div class="timeSetting-source">天文时间来源</div>
							<div class="timeSetting-source-one">
								<div class="sourceOne" v-for="(item, index) in radiolist" :key="index">
									<!-- <input type="radio" name="toList" id=""> -->
									<div :class="radio == index ? 'radioOnes' : 'radioOne'" @click="tabraido(index)">
									</div>
									<div class="sourceOne-one">{{ item.name }}</div>
								</div>
							</div>
							<div class="timeSetting-timeFight">校准作战时间</div>
							<div class="timeFight">
								<div class="timeFight-one" v-for="(item1, index) in listTab" :key="index">
									<div :class="tabList == index ? 'radioTwos' : 'radioTwo'" @click="tabListTo(index)">
									</div>
									<div class="timeFight-Astronomy">{{ item1.name }}</div>
								</div>
							</div>
							<div class="time-setup">
								<div class="time-setup-one">设置作战时间</div>
								<div class="time-setup-two" @click.stop="toggToListMail">
									通知校时
								</div>
							</div>
							<!-- <div class="time-Choose-one">选择时间</div> -->
							<!-- <input type="text" class="time-Choose-one" placeholder="选择时间" /> -->
							<el-time-select v-model="value" :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }" placeholder="选择时间">
							</el-time-select>
							<div class="broadcast">
								<div class="broadcast-one">
									<input type="checkbox" name="" checked class="radioSixe" />
									<div class="inCar">车内广播</div>
								</div>
								<button class="time-Btn">确定</button>
							</div>
							<div class="time-one"></div>
							<div class="time-two"></div>
							<div class="time-three"></div>
							<div class="time-fore"></div>
						</div>

						<!-- <img src="../assets/header/时统设置.png"/> -->
						<!-- @click="onToggleTimerSet" -->
					</div>
				</transition>
			</div>
			<div class="status-bar-info reverse" @click="onToggleTimerSet">
				<p>
					<span class="item-info">天文</span>
					<span class="item-info">2013/01/01 14:51:47</span>
				</p>
				<p>
					<span class="item-info">作战</span>
					<span class="item-info">2013/01/01 14:51:47</span>
				</p>
			</div>
		</div>

		<div class="mid">
			<div class="status-bar-seat" @click="dialogVisible = true">
				<SeatPc />
				<div class="seat-info">英雄联盟 王建国<span class="seat-arrow" /></div>
			</div>
			<!-- 弹框 -->
			<el-dialog :visible.sync="dialogVisible" width="15%" :modal="false">
				<span class="zhang-span">
					<div class="zhang-name">
						<div>姓名</div>
						<div class="zhang-divone-one">王建国</div>
					</div>
					<div class="zhang-divone">
						<div>编号</div>
						<div class="zhang-divone-one">00000000000</div>
					</div>
					<div class="zhang-divone">
						<div>球队</div>
						<div class="zhang-divone-one">0000湖人队</div>
					</div>
					<div class="zhang-divone">
						<div>等级</div>
						<div class="zhang-divone-one">二等</div>
					</div>
					<div class="zhang-state">
						<button class="zhang-btn1" @click="goSwitch">切换用户</button><span
							class="zhang-span1">/</span><button class="zhang-btn2" @click="goSign">退出登录</button>
					</div>
					<div class="zhang-one"></div>
					<div class="zhang-two"></div>
					<div class="zhang-three"></div>
					<div class="zhang-fore"></div>
				</span>
			</el-dialog>
		</div>

		<div class="right">
			<div class="grow" />
			<div class="info" @click="openWidgetSettingPanel">
				<span>球队状态</span>
				<span>球员状态</span>
			</div>
			<div class="tools-box">
				<span class="item-tool">
					<img src="../assets/home/搜索.svg" style="height: 23px" @click.stop="toggleSearchBox" />
				</span>
				<span class="item-tool">
					<img src="../assets/home/实时流模式.png" style="height: 23px" @click.stop="toggleVoiceBox" />
				</span>
			</div>

			<div class="tools-box">
				<span class="item-tool">
					<img src="../assets/home/名录.svg" @click.stop="toggleMessageBox" />
				</span>
				<div class="minlu">
					<!-- <transition name="slide-fade">
            <div v-if="mingluVisible" class="pop">
       
            </div>
          </transition> -->
				</div>
				<span class="item-tool">
					<img src="../assets/home/收发文件.svg" />
				</span>
				<span class="item-tool" @click="onOpenAlertSettingPage">
					<img src="../assets/home/警报.svg" style="height: 22px" />
					<span>29</span>
				</span>
				<span class="item-tool" @click="(e) => openNotificationWhole(e)">
					<img src="../assets/home/信封.svg" style="height: 20px" />
					<span>{{ messageCounts }}</span>
				</span>
				<span class="item-tool" @click="openWidgetSettingPanel">
					<img src="../assets/home/设置.svg" style="height: 20px" />
				</span>
			</div>

			<div class="tools-box cut">
				<span class="item-tool" @click="onFullScreen">
					<img src="../assets/home/全屏.svg" style="height: 20px" />
				</span>
			</div>
		</div>
	</div>
	<div class="status-bar" v-else>
		<img class="back" :src="`/theme/${$tis.bus.theme}/左上角返回.png`" @click="backLast" />
		<div class="title">{{ currentApp.name }}</div>
		<div class="status-bar-location"></div>
		<div class="status-bar-info">
			<p>
				<span class="item-info">东经</span>
				<span class="item-info">121°10′ 55.648″</span>
			</p>
			<p>
				<span class="item-info">北纬</span>
				<span class="item-info">121°10′ 55.648″</span>
			</p>
		</div>
		<div class="status-bar-location">
			<img :src="`/theme/${$tis.bus.theme}/时统.png`" @click.stop="onToggleTimerSet" />
			<div class="minlu">
				<transition name="slide-fade">
					<div v-if="timerSetVisible" class="pop">
						<img src="../assets/header/时统设置.png" v-if="timerSetVisible" class="img" />
						<!-- @click="onToggleTimerSet" -->
					</div>
				</transition>
			</div>
		</div>
		<div class="status-bar-info">
			<p>
				<span class="item-info">天文</span>
				<span class="item-info">2013/01/01 14:51:47</span>
			</p>
			<p>
				<span class="item-info">作战</span>
				<span class="item-info">2013/01/01 14:51:47</span>
			</p>
		</div>
		<div class="status-bar-location">
			<img :src="`/theme/${$tis.bus.theme}/定位.png`" @click.stop="togglelocationArtificial" />
			<div class="minlu"></div>
		</div>
		<div class="tools">
			<span class="item-tool">
				<img src="../assets/home/搜索.svg" style="height: 23px" @click.stop="toggleSearchBox" />
			</span>
			<span class="item-tool not-allowed">
				<img src="../assets/home/语音.svg" style="height: 23px" />
			</span>
			<span class="item-tool">
				<img src="../assets/home/名录.svg" @click.stop="toggleMessageBox" />
			</span>
			<div class="minlu"></div>
			<span class="item-tool">
				<img src="../assets/home/收发文件.svg" />
			</span>
			<span class="item-tool" @click="onOpenAlertSettingPage">
				<img src="../assets/home/警报.svg" />
				<span>29</span>
			</span>
			<span class="item-tool" @click="(e) => openNotificationWhole(e)">
				<img src="../assets/home/信封.svg" style="width: 23px" />
				<span>{{ messageCounts }}</span>
			</span>
		</div>

		<span class="widgets" @click="openWidgetPanel">
			<img :src="`/theme/${$tis.bus.theme}/浮窗图标.svg`" style="height: 70px; margin: -25px" />
			<span>浮窗</span>
		</span>

		<span class="close" @click="closeCurrentApp">
			<img :src="`/theme/${$tis.bus.theme}/关闭图标.svg`" style="height: 40px" />
		</span>
	</div>
</template>

<script>
	import SeatPc from './SeatPc'
	const clickoutside = {
		bind(el, binding) {
			function documentHandler(e) {
				if (el.contains(e.target)) {
					return false;
				}
				if (binding.expression) {
					binding.value(e);
				}
			}
			el._vueClickOutside_ = documentHandler;
			document.addEventListener("click", documentHandler);
		},
		update() {},
		unbind(el) {
			document.removeEventListener("click", el._vueClickOutside_);
			delete el._vueClickOutside_;
		},
	};
	export default {
		props: {
			params: Object,
		},
		components: {
			SeatPc
		},
		data() {
			return {
				value: "",
				dialogVisible: false,
				appCardsVisible: true,
				mingluVisible: false,
				dingweiVisible: false,
				timerSetVisible: false,
				radio: 1,
				radiolist: [{
						id: 0,
						name: "上级时间",
					},
					{
						id: 1,
						name: "本机系统时间",
					},
					{
						id: 2,
						name: "北斗",
					},
				],
				tabList: null,
				listTab: [{
						id: 0,
						name: "同步天文时间",
					},
					{
						id: 1,
						name: "同步上级作战时间",
					},
				],
			};
		},
		directives: {
			clickoutside
		},
		computed: {
			isMainApp() {
				// 当前是否是主界面应用
				return this.$tis.isMainApp();
			},
			currentApp() {
				return this.$tis.getCurrentApp();
			},
			messageCounts() {
				return this.$tis.getMessageCount();
			},
		},
		mounted() {
			console.log('mounted', this.params)
		},
		created() {
			console.log('created', this.params)
		},
		methods: {
			tabListTo(index) {
				this.tabList = index;
			},
			tabraido(index) {
				this.radio = index;
			},
			handleClose() {
				this.$tis.visiblediwdfalse();
			},
			onToggleTimerSet(e) {
				e.preventDefault();
				e.stopPropagation();
				this.timerSetVisible = !this.timerSetVisible;
				this.$tis.visiblediwddae();
				// 关闭人工定位
				this.$tis.tggtolocationArtificial();
				// 关闭通讯录
				this.$tis.togtomeslist();
				// 关闭通知校时
				this.$tis.togToListMail();
				//关闭采点
				this.$tis.togMiningTo();
			},
			//切换用户
			goSwitch() {
				sessionStorage.removeItem('token');
				window.location.reload();
			},
			//退出登录
			goSign() {
				sessionStorage.removeItem('token');
				window.location.reload();
			},
			backLast() {
				console.log(this.$tis.getOpenedWidgets())
				console.log('触发时间', this.params)
				// if(this.params.isBackwidget){
				// 	// 加载最小化浮窗
				// 	this.$tis.openWidget(this.params.id)
				// 	this.$tis.closeCurrentApp();
				// }else{
				// 	// 关闭当前浮窗
				// 	this.$tis.closeCurrentApp();
				// }
				if (sessionStorage.getItem('backWidget')) {
					this.$tis.openWidget(sessionStorage.getItem('backWidget'))
					sessionStorage.removeItem('backWidget');
				} else if (sessionStorage.getItem('backCustomizeWidget')) {
					this.$tis.openWidget(sessionStorage.getItem('backCustomizeWidget'))
					sessionStorage.removeItem('backCustomizeWidget');
				} else {
					console.log('判断打开浮窗，关闭当前应用')
				}
				this.$tis.closeCurrentApp();
			},
			closeCurrentApp() {
				if (sessionStorage.getItem('backWidget')) {
					sessionStorage.removeItem('backWidget');
				} else if (sessionStorage.getItem('backCustomizeWidget')) {
					sessionStorage.removeItem('backCustomizeWidget');
				} else {
					console.log('判断移除缓存，关闭当前应用')
				}
				this.$tis.closeCurrentApp();
			},
			openWidgetPanel() {
				this.$tis.openWidgetPanel();
			},
			openWidgetSettingPanel() {
				this.$tis.openWidgetSettingPanel();
			},
			openWidgetLoginPanel() {},
			openNotificationWhole(e) {
				e.preventDefault();
				e.stopPropagation();
				this.$tis.toggleNotifycationWhole();
			},
			//搜索
			toggleSearchBox() {
				this.$tis.toggleSearchBox();
			},
			toggleVoiceBox() {
				this.$tis.toggleVoiceBox();
			},
			//人工定位
			togglelocationArtificial() {
				this.$tis.togglelocationArtificial();
				// 关闭通知校时
				this.$tis.togToListMail();
				// 关闭通讯录
				this.$tis.togtomeslist();
				//关闭时间设置
				this.$tis.visiblediwdfalse();
				//关闭采点
				this.$tis.togMiningTo();
			},
			//通讯录
			toggleMessageBox() {
				this.$tis.toggleMessageBox();
				// 关闭人工定位
				this.$tis.tggtolocationArtificial();
				// 关闭通知校时
				this.$tis.togToListMail();
				//关闭时间设置
				this.$tis.visiblediwdfalse();
				//关闭采点
				this.$tis.togMiningTo();
			},
			//通知校时
			toggToListMail() {
				this.$tis.toggToListMail();
				// 关闭通讯录
				this.$tis.togtomeslist();
				// 关闭人工定位
				this.$tis.tggtolocationArtificial();
				//点击通知校时，隐藏此弹框
				this.$tis.visiblediwdfalse();
				//关闭采点
				this.$tis.togMiningTo();
			},
			//

			toggleAppCards() {
				window.api.minimize();
				// this.appCardsVisible = !this.appCardsVisible;
				// this.$tis.changeAppCardsVisible(this.appCardsVisible);
			},
			onFullScreen() {
				this.$tis.fullScreen(true);
			},
			onOpenAlertSettingPage() {
				this.$tis.bus.alertSettingPageVisible = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	$barHeight: 56px;

	.status-bar {
		height: $barHeight;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		color: var(--status-bar-text-color);
		background-image: var(--status-app-bar-bg-img);
		background-repeat: no-repeat;
		background-size: cover;

		.back {
			height: 100%;
			cursor: pointer;
		}

		.title {
			font-size: 28px;
			margin: 0 43px 0 27px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			flex-grow: 1;
		}

		.status-bar-sys {
			margin-left: 20px;
			margin-right: 10px;
			font-size: 12px;
			color: rgba(255, 255, 255, 0.5);

			.item-signal {
				margin: 5px;
				padding-left: 22px;
				background-image: url(/signal.svg);
				background-repeat: no-repeat;
				background-size: 12px 14px;
				background-position: 0 center;
			}

			.item-battery {
				margin: 5px;
				padding-left: 26px;
				background-image: url(/battery.png);
				background-repeat: no-repeat;
				background-size: 18px 11px;
				background-position: 0 center;
			}
		}

		.tools {
			margin-left: 20px;
			display: flex;
			align-items: center;

			.item-tool {
				cursor: pointer;
				margin: 0 10px;
				display: flex;
				align-items: center;

				img {
					display: block;
				}

				span {
					font-size: 24px;
					margin-left: 8px;
					color: rgba(255, 255, 255, 0.8);
				}
			}

			margin-right: 15px;
		}

		.email {
			position: relative;
			padding: 0 28px;
			height: 100%;
			cursor: pointer;
			margin-left: 2px;
			opacity: 0.8;
			color: #fff;
			font-size: 24px;
			display: flex;
			align-items: center;

			span {
				margin-left: 10px;
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				box-shadow: inset 1px 0 1px 0 #4c4a48;
				background-image: linear-gradient(180deg,
						rgba(115, 110, 102, 0.02) 0%,
						rgba(63, 59, 54, 0.18) 100%);
				transform: skewX(-26deg);
			}
		}

		.widgets {
			position: relative;
			z-index: 1;

			padding: 0 28px;
			height: 100%;
			cursor: pointer;
			margin-left: 2px;
			color: #fff;
			font-size: 24px;
			display: flex;
			align-items: center;

			span {
				margin-left: 10px;
				color: var(--status-bar-widget-btn-color);
				text-shadow: var(--status-bar-widget-btn-text-shadow);
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-image: var(--status-bar-widget-btn-bg-img);
				box-shadow: var(--status-bar-widget-box-shadow);
				transform: skewX(-26deg);
			}
		}

		.close {
			flex-grow: 0;
			position: relative;
			height: 100%;
			cursor: pointer;
			color: #fff;
			font-size: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-left: 2px;
			position: relative;
			margin-right: -20px;
			padding: 0 20px 0 10px;

			img {
				z-index: 1;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				transform: skewX(-26deg);
				background-image: var(--status-bar-close-bg-image);
			}
		}
	}

	.status-bar-2 {
		height: $barHeight;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		color: var(--status-bar-text-color);
		opacity: var(--status-bar-bg-opacity);
		background-color: var(--status-bar-bg-color);
		background-image: var(--status-bar-bg-img);
		box-shadow: var(--status-bar-box-shadow);
		border-bottom: var(--status-bar-border);

		>.mid {
			width: 396px;
		}

		>.left {
			width: calc((100% - 396px) / 2);
			display: flex;
			height: 100%;
			align-items: center;

			>.action {
				flex: 1;
				position: relative;
				min-width: 88px;
				height: 100%;

				img {
					width: 88px;
					position: absolute;
					left: -2px;
					bottom: -13px;
				}
			}

			>.status-bar-info:last-child {
				margin-right: 0;
			}
		}

		>.right {
			width: calc((100% - 396px) / 2);
			display: flex;
			height: 100%;
			align-items: center;
			position: relative;

			>.grow {
				flex-grow: 1;
			}

			>.info {
				position: absolute;
				top: 2px;
				cursor: pointer;

				right: 20px;

				span {
					font-size: 12px;
					margin: 0px 15px;
				}
			}

			.tools-box {
				display: flex;
				margin: 0 4px;
				position: relative;
				bottom: -20px;
				height: 48px;
				padding: 0 10px;
				align-items: center;

				&.cut {
					padding-right: 30px;
					margin-right: -10px;
					border-right: 1px solid #4c4a48;

					&::after {
						content: "";
						position: absolute;
						right: 25px;
						top: 0;
						bottom: 0;
						background: #4c4a48;
						width: 1px;
						height: 100%;
					}
				}

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: var(--status-bar-tools-bg);
					border: var(--status-bar-tools-border);
					// box-shadow: inset 1px -1px 1px 0px #4c4a48;
					transform: skewX(-26deg);
				}

				.item-tool {
					cursor: pointer;
					z-index: 1;
					margin: 0 12px;
					display: flex;
					align-items: center;

					img {
						display: block;
					}

					span {
						font-size: 24px;
						margin-left: 8px;
						color: rgba(255, 255, 255, 0.8);
					}
				}
			}
		}
	}

	.status-bar-info {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 12px;
		margin: 0 25px;
		cursor: pointer;

		p:first-child {
			margin-left: 10px;
		}

		p {
			margin: 0;

			&:last-child {
				margin-top: 6px;
			}

			span:last-child {
				margin-left: 12px;
			}
		}

		&.reverse {
			p:first-child {
				margin-left: 0px;
			}

			p:last-child {
				margin-left: 10px;
			}
		}
	}

	.status-bar-location {
		min-width: 37px;
		cursor: pointer;
		position: relative;
		height: $barHeight;

		>img {
			height: $barHeight * 0.66;
			position: relative;
			top: $barHeight * 0.17;
			z-index: 1;
		}

		position: relative;

		&::after {
			content: " ";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			background: var(--status-bar-icon-bg);
			// height: $barHeight;
			width: 2px;
			transform: skewX(-26deg);
		}

		&.reverse {
			&::after {
				transform: skewX(26deg);
			}
		}
	}

	.status-bar-seat {
		width: 100%;
		position: relative;

		&:before {
			//content: "";
			background-image: var(--status-bar-center-img);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;
			position: absolute;
			width: 100%;
			height: 63px;
		}

		.seat-info {
			position: relative;
			height: $barHeight;
			line-height: $barHeight;
			text-align: center;
			cursor: pointer;
			font-size: 28px;
			color: #313131;
		}

		.seat-arrow {
			display: inline-block;
			border: 4px solid #313131;
			width: 5px;
			height: 5px;
			border-top: 0 none;
			border-right: 0 none;
			transform: translate(0, -6px) rotate(-45deg);
			margin-left: 12px;
			// color: #313131;
		}
	}

	.zhang-name {
		display: flex;
		font-family: HYk2gj;
		font-size: 14px;
		color: #ffffff;
	}

	.zhang-divone {
		display: flex;
		margin-top: 20px;
		font-family: HYk2gj;
		font-size: 14px;
		color: #ffffff;
	}

	.zhang-divone-one {
		font-size: 14px;
		// color: #8e908e;
		color: #8e908e;
		margin-left: 20px;
	}

	.zhang-state {
		display: flex;
		margin-top: 50px;
		margin-left: -10px;
	}

	.zhang-btn1 {
		width: 80px;
		font-family: HYk2gj;
		font-size: 14px;
		color: #ffffff;
		cursor: pointer;
		border: none;
		background: none;
	}

	.zhang-btn2 {
		width: 80px;
		font-family: HYk2gj;
		cursor: pointer;
		font-size: 14px;
		color: #ffffff;
		// margin-left: 1px;
		border: none;
		background: none;
		margin-left: 10px;
	}

	.zhang-span1 {
		opacity: 0.3;
		font-family: HYk2gj;
		font-size: 14px;
		color: #ffffff;
		margin-left: 10px;
	}

	.zhang-one {
		border: 2px solid #f7dd29;
		width: 10px;
		height: 10px;
		position: absolute;
		top: -1px;
		left: -1px;
		border-bottom: none;
		border-right: none;
	}

	.zhang-two {
		border: 2px solid #f7dd29;
		width: 10px;
		height: 10px;
		position: absolute;
		top: -1px;
		right: 0.5px;
		border-bottom: none;
		border-left: none;
	}

	.zhang-three {
		border: 2px solid #f7dd29;
		width: 10px;
		height: 10px;
		position: absolute;
		top: 230px;
		right: 0.5px;
		border-top: none;
		border-left: none;
	}

	.zhang-fore {
		border: 2px solid #f7dd29;
		width: 10px;
		height: 10px;
		position: absolute;
		top: 230px;
		left: 0.5px;
		border-top: none;
		border-right: none;
	}

	.not-allowed {
		cursor: not-allowed !important;
	}

	.minlu {
		position: relative;
		background: red;

		.pop {
			position: absolute;
			top: 60px;
			left: -100px;
			z-index: 200;

			img {
				width: 300px;
			}
		}
	}

	.slide-fade-enter-active {
		transition: all 0.2s ease;
	}

	.slide-fade-leave-active {
		transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter,
	.slide-fade-leave-to {
		transform: translateY(-20px);
		opacity: 0;
	}

	::v-deep .el-dialog {
		margin-top: 80px !important;
		background: rgba(0, 0, 0, 0);
		top: 20px;
		width: 240px;
		height: 240px;
	}

	::v-deep .el-dialog__body {
		background: rgba(0, 0, 0, 0.85);
		// background: #000000;
		// opacity: 85%;
		color: white;
		padding: 24px 30px;
		margin-top: -30px;
	}

	::v-deep .el-dialog__headerbtn {
		position: absolute;
		top: -20px;
		right: 12px;
		padding: 0;
		background: 0 0;
		border: none;
		outline: 0;
		font-size: 10px;
		display: none;
	}

	::v-deep .status-bar-info {
		cursor: pointer;
	}

	.timeSetting {
		background: rgba(0, 0, 0, 0.85);
		// border: 1px solid red;
		width: 368px;
		height: 368px;
		margin-top: 12px;
	}

	.timeSetting-top {
		color: #ffdd55;
		padding-left: 15px;
		padding-top: 10px;
		font-size: 18px;
	}

	.timeSetting-source {
		font-size: 16px;
		color: white;
		padding-left: 15px;
		margin-top: 20px;
	}

	.timeSetting-source-one {
		display: flex;
		margin-top: 15px;

		.sourceOne {
			display: flex;
			margin-left: 15px;

			.radioOne {
				border: 2px solid #d8d8d8;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				cursor: pointer;
			}

			.radioOnes {
				border: 2px solid #d8d8d8;
				background: #f7c829;
				width: 15px;
				height: 15px;
				border-radius: 50%;
			}

			.sourceOne-one {
				font-size: 16px;
				color: #ffffff;
				margin-left: 8px;
			}
		}
	}

	.timeSetting-timeFight {
		font-size: 16px;
		color: white;
		padding-left: 15px;
		margin-top: 30px;
	}

	.timeFight {
		display: flex;
		margin-top: 15px;

		.timeFight-one {
			display: flex;
			margin-left: 15px;

			.radioTwo {
				border: 2px solid #d8d8d8;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				cursor: pointer;
			}

			.radioTwos {
				border: 2px solid #d8d8d8;
				width: 15px;
				height: 15px;
				background: #f7c829;
				border-radius: 50%;
			}

			.timeFight-Astronomy {
				font-size: 16px;
				color: #ffffff;
				margin-left: 8px;
			}
		}

		.timeFight-two {
			display: flex;
			margin-left: 35px;

			.radioFive {
				border: 2px solid #d8d8d8;
				width: 15px;
				height: 15px;
				border-radius: 50%;
			}

			.timeFight-superior {
				font-size: 16px;
				color: #ffffff;
				margin-left: 8px;
			}
		}
	}

	.time-setup {
		display: flex;
		margin-left: 15px;
		margin-top: 30px;

		.time-setup-one {
			font-size: 16px;
			color: #ffffff;
		}

		.time-setup-two {
			font-size: 16px;
			color: #ffdd55;
			text-align: center;
			margin-left: 178px;
			cursor: pointer;
		}
	}

	.broadcast {
		display: flex;
		margin-top: 20px;

		.broadcast-one {
			display: flex;
			margin-left: 15px;

			input[type="checkbox"] {
				cursor: pointer;
				position: relative;
				width: 15px;
				height: 15px;
				font-size: 14px;
			}

			input[type="checkbox"]::after {
				position: absolute;
				top: 0;
				color: #000;
				width: 15px;
				height: 15px;
				display: inline-block;
				visibility: visible;
				padding-left: 0px;
				text-align: center;
				content: " ";
				border-radius: 3px;
			}

			input[type="checkbox"]:checked::after {
				content: "✓";
				color: #fff;
				font-size: 12px;
				font-weight: bold;
				background-color: #f7c829;
			}

			.inCar {
				font-family: PingFangSC-Regular;
				font-size: 16px;
				color: #ffffff;
				margin-left: 10px;
			}
		}

		.time-Btn {
			width: 72px;
			height: 40px;
			background: #ffcc00;
			font-size: 16px;
			color: #313131;
			text-align: center;
			border: none;
			margin-left: 176px;
			cursor: pointer;
		}

		.time-Btn:hover {
			color: #ffdd55;
		}
	}

	.time-one {
		border: 2px solid #f7dd29;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 11px;
		left: 0px;
		border-bottom: none;
		border-right: none;
	}

	.time-two {
		border: 2px solid #f7dd29;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 11px;
		right: 0px;
		border-bottom: none;
		border-left: none;
	}

	.time-three {
		border: 2px solid #f7dd29;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 360px;
		right: 0px;
		border-top: none;
		border-left: none;
	}

	.time-fore {
		border: 2px solid #f7dd29;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 360px;
		left: 0px;
		border-top: none;
		border-right: none;
	}

	::v-deep .el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 336px;
		margin-top: 15px;
		margin-left: 15px;
	}

	::v-deep .el-input__inner {
		-webkit-appearance: none;
		background-image: none;
		background: #292929;
		border: 1px solid #979797;
		box-sizing: border-box;
		display: inline-block;
		font-size: 16px;
		height: 40px;
		line-height: 40px;
		outline: 0;
		padding: 0 30px;
		transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		width: 100%;
	}
</style>
