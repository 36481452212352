import "./assets/alicon/download/font_nbzp70p4cqq/iconfont.css";
import "./theme.css";
// 引入框架启动函数
import tis, { setup } from "./tis";
// layouts 导入布局组件
import StatusBar from "./components/StatusBar";
import SiderMenu from "./components/SiderMenu";
import NavBar from "./components/NavBar";
import AppBar from "./components/AppBar";
import WidgetsPanel from "./components/WidgetsPanel";
import Widget from "./components/Widget";
import WidgetDR from "./components/WidgetDR";
import WidgetsView from "./components/WidgetsView";
import AppView from "./components/AppView";
import AppsPanel from "./components/AppsPanel";
import TaskKanban from "./components/TaskKanban";
import SettingSys from "./components/SettingSys";
import Login from "./components/Login";
import SearchBox from "./components/SearchBox";
import NotificationWhole from "./components/NotificationWhole";
import HistoricalNews from "./components/HistoricalNews";
import Footer from "./components/Footer";
import axios from "axios";
import hotkeys from "./hotkeys.config";
import locationArtificial from "./components/locationArtificial";
import MessageBox from "./components/MessageBox";
import ToListMail from "./components/ToListMail";
import Miningpoint from "./components/Miningpoint";
import screenView from "./components/screenView";

const config = {
  // 配置layouts布局
  layouts: {
    StatusBar,
    SiderMenu,
    Footer,
    NavBar,
    AppBar,
    WidgetsPanel,
    WidgetsView,
    Widget,
    WidgetDR,
    AppView,
    AppsPanel,
    TaskKanban,
    SettingSys,
    NotificationWhole,
    HistoricalNews,
    Login,
    SearchBox,
    locationArtificial,
    MessageBox,
    ToListMail,
    Miningpoint,
    screenView,
  },
  // 应用id白名单，这些应用在关闭时DOM不会被销毁
  whitelist: [],
  // websocket server地址
  wsUrl: "",
  // 注册tis-app应用
  apps: [],
  // 注册浮窗
  widgets: [],
};
async function start() {
  const manifest = await axios.get("/manifest.json").then((r) => r.data);
  const apps_widgets = await axios
    .get("/apps_widgets.json")
    .then((r) => r.data);

  config.whitelist = [...config.whitelist, ...manifest.whitelist];
  config.wsUrl = config.wsUrl || manifest.wsUrl;

  config.apps = [...config.apps, ...apps_widgets.apps];
  config.widgets = [...config.widgets, ...apps_widgets.widgets];

  config.hotkeys = hotkeys;

  // 启动
  setup(config);
  // 初始化消息中心的数据。
  tis.initMessageBoxData();
}
start();
window.tis = tis;
// 处理消息推送的三种机制
tis.$on("message", { system: true }, async (payload) => {
  const { msgType, appId, openAppParams, message } = payload;
  if (msgType === "OPEN_APP") {
    // 框架收到消息即打开对应应用界面
    tis.openApp(appId, openAppParams);
  } else if (msgType === "NONTIFY_APP") {
    // 框架收到消息不打开应用页面但调起应用逻辑进行处理
    tis.postMessage(appId, openAppParams);
  } else if (msgType === "SILENT_APP") {
    // 通过消息中心打开应用页面
    // 消息中心组件里监听 message事件， 处理这个逻辑
    // 这个payLoad 需要确认下数据字段, 这里假设payload下
    // 消息入库到消息中心。
    tis.addMessageData(message);
    // 进行notifycation提示
    // 这里需要根据消息设置里的逻辑来进行，如果用户配置了免打扰等消息，则不在这里进行提示。
    !tis.isVisibleNotificationWhole() && tis.Notifition.show(message);
  }
});
