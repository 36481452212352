<template>
  <StatusBarPad v-if="deviceType === 'pad'" />
  <StatusBarMobile v-else-if="deviceType === 'mobile'" />
  <StatusBarPc v-else />
</template>

<script>
import StatusBarMobile from "./StatusBarMobile.vue";
import StatusBarPad from "./StatusBarPad.vue";
import StatusBarPc from "./StatusBarPc.vue";
export default {
  components: {
    StatusBarPad,
    StatusBarMobile,
    StatusBarPc,
  },
  data() {
    return {};
  },
  computed: {
    deviceType() {
      return this.$tis.bus.deviceType;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
