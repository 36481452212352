
<template>
<div></div>
</template>
<script>
import '../notification.css'

export default {
  name:'Notification',
	data() {
    return{
      notifications: {}, //使用userNotifyId作为弹窗的key，用来获取弹窗的实例，以对对应弹窗进行操作
      timer: null
    }
	},
	created() {
	},
	methods: {
    renderClass(i) {
      if(i == 2 ){
        return 'noticeWrapYellow'
      }else if(i == 3){
        return 'noticeWraRed'
      }else {
         return 'noticeWrap'
      }
    },
    handleBtn(type,obj){
      alert(type);
      // type 1主按钮，2次按钮 3，视频接听，4语音接听，5关闭
      console.log(type,obj)
    },
    // 关闭单个通知
    closeNotification(id){
      console.log(this.notifications, 'weee', id)
      this.notifications[id].close();
      delete this.notifications[id];
    },
    // show(data){

    //   const {id,appName,text,type,level,compent,time,title,reply,btn=1} = data;

    //   // let  obj  = data;
    //   console.log(appName,type,compent,time,title,reply);
    //   // console.log("====="+btn)
    //     // console.log("====="+data)
    //   // let obj = {
    //   //     id: 1,
    //   //     text: '2333',
    //   //     type: 1,
    //   //     level: 3, // 3警报，2告警 1生化  根据level 来区分这个消息的类型，也可以换成能区分的字段
    //   //     btn: 1    //btn 表示这个通知有几个按钮，按钮不同展示的也不同
    //   // };
    //   let h = this.$createElement;
    //   let _this = this
    //   let notify = this.$notify({
    //     message: h("div",{ class: this.renderClass(level)},[
    //       h('div',{class:'notice-content', style: {display: btn == 3 ? 'block' : 'none'}}, text),
    //       h('div',{ class:'buttonOne',style:{display: btn == 1 ?' flex' : 'none'}},[
    //         // h('span',{ class:'closeWrap', style: {display: obj.btn == 1 ? 'block' : 'none'}}),
    //         h('span',{ class:'content'},text)
    //       ]),
    //       h('div',{ class:'groupButtonTwoWrap',style:{display: btn ==2 ?' flex' : 'none'}},[
    //           h('span',{ class:'groupButtonTwo'},[
    //             h('span',{
    //               on:{
    //                 click:()=>{ _this.handleBtn(1,data) } 
    //               } 
    //             },'主按钮'),
    //             h('span',{
    //               on:{
    //                 click: ()=>{ _this.handleBtn(2,data) }
    //               } 
    //             },'次按钮')
    //           ]),
    //           h('span', {class: 'content-wrap'},[
    //             h('span',{ class:'content-title'}, title ),
    //             h('span',{ class:'content'}, text ),
    //           ])
    //       ]),
    //       h('div',{ class:'groupButtontThird', style:{display: btn ==3 ?' flex' : 'none'}},[
    //         h('span',{ class:'closeWrap',
    //           on:{
    //             click: ()=>{ _this.closeNotification(id) }
    //           } },'关闭'),
    //         h('span',{
    //           on:{
    //             click: ()=>{ _this.handleBtn(3,data) }
    //           } 
    //         },[
    //           h('i',{ class:'el-icon-video-camera'}),
    //           h('em',{},'视频接听')
    //         ]),
    //         h('span',{
    //           on:{
    //             click: ()=>{ _this.handleBtn(4,data) }
    //           } 
    //         },[
    //           h('i',{ class:'el-icon-chat-dot-square'}),
    //           h('em',{},'语音接听')
    //         ])
    //       ])
    //     ]),
    //     dangerouslyUseHTMLString: true,
    //     duration: 5000,
    //     showClose: btn ==1 ? true : false,
    //     offset:100
    //   })
    //   this.notifications[id] = notify 
    // }
	}
};
</script>


