<template>
  <div class="Messagebox">
    <div class="minlu">
      <transition name="slide-fade">
        <div class="pop">
          <div class="topFrame">
            <div class="topleft"></div>
            <div class="topright"></div>

            <div class="tit-TX" @click.stop="returnClick">
              <img src="../assets/左箭头.png" alt="">
              返回
              </div>
            <div class="tab-TX">
              <span :class="activeleft" @click="tabactive1">全部</span> /
              <span @click="tabactive2" :class="activeright">常用</span>
            </div>
            <div class="scobox-TX">
              <input type="text" /><i class="el-icon-search"></i>
            </div>
            <div  class="box-TX">
            <el-tree :data="data"
             show-checkbox           
             :props="defaultProps"
             node-key="id"           
             :default-expanded-keys="[0,2]"                        
              >
             </el-tree>
            </div>
            <button class="mailBtn">发送通知</button>
            <div class="btnleft"></div>
            <div class="btnright"></div>           
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>

export default {
  name: "MessageBox",
  data() {
    return {
      activeleft: "tab-TX-left-hove",
      activeright: "tab-TX-right",
        data: [{
           id:0, 
          label: '组00000旅',
          children: [{
            id:1,
            label: '基本指挥所',
            children: [{
               id:2,
              label: '轻型通用指挥车',
              children:[{
                label: '王建国',                
              },{
                label: '王建国',
              },{
                label: '王建国',
              },{
                label: '王建国',
              },{
                label: '王建国',
              },{
                 id:3,
                label:'预备指挥所',
                 children:[{
                label: '王建国',
                 }]
              }]
            }]
          }]
        }],
          defaultProps: {
          children: 'children',
          label: 'label'
        }
    };
  },
  mounted() {},
  methods: {
 
    // tab切换
    tabactive1() {
      this.activeleft = "tab-TX-left-hove";
      this.activeright = "tab-TX-right";
      // 点击全部触发得事件
    },
    tabactive2() {
      this.activeleft = "tab-TX-left";
      this.activeright = "tab-TX-right-hove";
      // 点击常用触发得事件
    },
    returnClick(){
       this.$tis.togToListMail()
       this.$tis.visiblediwddae()
    }
  },
};
</script>
<style lang='scss' scoped>
.topFrame {
  .tit-TX {   
    font-size: 18px;
    color: #ffdd55;
    padding: 0 16px;
    cursor: pointer;
    padding-top: 10px;
    display: flex;
    img{      
      margin-right: 7px;
      width: 15px;
      height: 18px;
    }
  }
  .tab-TX {
    font-size: 16px;
    color: #CBCBCB;
    letter-spacing: 0;
    line-height: 54px;
    cursor: pointer;
    .tab-TX-left {
      padding-right: 15px;
      padding-left: 16px;
    }
    .tab-TX-left-hove {
      padding-right: 15px;
      padding-left: 16px;
      color: #ffdd55;
    }
    .tab-TX-right {
      padding-left: 15px;
    }
    .tab-TX-right-hove {
      padding-left: 15px;
      color: #ffdd55;
    }
  }
  .scobox-TX {
    margin-top: 3px;
    padding-left: 16px;
    position: relative;
    input {
      width: 296px;
      height: 40px;
      padding-left: 40px;
      background: #292929;
      border: 1px solid #979797;
      color: #ffffff;
    }
    i {
      position: absolute;
      top: 10px;
      font-size: 22px;
      color: #655e62;
      left: 20px;
    }
  }
 .box-TX{
  padding: 20px 16px;
   
 }
  .topleft {
    position: absolute;
    width: 20px;
    height: 20px;
    border-left: solid 2px yellow;
    border-top: solid 2px yellow;
  }
  .topright {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    border-right: solid 2px yellow;
    border-top: solid 2px yellow;
  }

  .btnleft {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    left: 0;
    border-left: solid 2px yellow;
    border-bottom: solid 2px yellow;
  }
  .btnright {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    border-right: solid 2px yellow;
    border-bottom: solid 2px yellow;
  }
}
::v-deep .el-tree {
    position: relative;
    cursor: default;    
    color: #fff;
    background: rgba(0, 0, 0, 0); 
}
::v-deep .el-tree-node__content {
    display: flex;
    align-items: center;
    // border: 1px solid rgba(0, 0, 0, .85);
    height: 34px;
    cursor: pointer;  
    // background: rgba(0, 0, 0, .85); 
}
::v-deep .el-tree-node__content:hover {
   background: rgba(0, 0, 0, 0);
}
.mailBtn{
  width: 98px;
  height: 40px;
  background: #F7C829;
  font-size: 16px;
  color: #313131;
  border: none;
  position: absolute;
  top: 491px;
  left: 245px;
  z-index: 999;
  cursor: pointer;
}
.mailBtn:hover{
  color: #ffdd55;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #F7C829;
    border-color: #979797;    
}

::v-deep .el-tree-node__label {
    font-size: 16px;
}
::v-deep .el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #979797;
    border-radius: 2px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background-color: #FFF;
    z-index: 1;
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
}

</style>