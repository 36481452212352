import Vue from "vue";
import { loadMicroApp } from "qiankun";
import "normalize.css";
import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import axios from "axios";
import Notifition from "./notification.js";
import hotkeysPlugin from "./plugins/hotkeysPlugin";
import workerpool from "workerpool";
import voicePlugin from "./plugins/voice";
import qtPlugin from "./plugins/qtPlugin";
import router from "./router/index";

const worker = workerpool.pool();

// 休眠应用队列最大长度
const APPS_SLEEPING_LEN = 5;

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$Notifition = Notifition; //可以在任意地方调用

const bus = new Vue({
  data: {
    whitelist: [], // 白名单应用id
    globalState: {}, // 全局变量
    appsSleeping: [], // 休眠应用队列 最长暂时为5
    apps: [], // 所有应用
    widgets: [], // 所有浮窗
    deviceType: "pc",
    visibleWidgetPanel: false, // 选择浮窗的视图
    visibleAppsPanel: false, // 选择浮窗的视图
    widgetListViewVisible: false, // 展示所有浮窗的列表视图
    visibleWidgetSysSetting: false, // 展示设置浮窗
    visibleWidgetSysSettingMenuIndex: 0,
    visibleWidgetLogin: false, //登录窗口
    visibleHistoricalNews: false, //是否显示历史消息`
    visibleNotificationWhole: false, //是否显示右侧全部消息
    visibleSearchBox: false, // 搜索框
    visibleVoiceBox: false,
    visibleDialogBox: false,
    visibleLookAtMsgBox: false,
    visibleAppScreen: false, //分屏组件
    isFullScreen: false, //浮窗是否充满全屏`
    messageItem: {}, // 消息面板的信息逻辑
    messageBoxList: [], // 收件箱数据面板数据存储，初始化时请求接口，
    alertSettingPageVisible: false,
    locationArtificial: false, // 人工定位弹框
    visibleMessageBox: false, //消息
    visibleToListMail: false, //通知校时
    visiblediwd: false, //事件通知
    headerVisible: true,
    theme: document.documentElement.getAttribute("data-theme") || "default",
    // pad 应用界面
    applicationInterface: {
      visible: false,
      name: null,
    },
    applicationInterfaceApps: [
      "messageSettingVisible",
      "alarmMainWineowVisible",
      "alarmSendVisible",
      "messageCenterVisible",
    ],
    controlPanelPadVisible: false, // 全部应用
    alarmMainWineowVisible: false, // pad报警页面
    alarmSendVisible: false, // pad报警发送抽屉
    searchBoxPadVisible: false, // pad搜索组件
    messageSettingVisible: false, // pad消息设置
    messageCenterVisible: false, // pad消息中心
    messageDetailVisible: false, // 回复消息
    registerVisible: {},
    iatoneType:
      (JSON.parse(localStorage.getItem("voiceSetting") || '""') || {})[
        "iatoneType"
      ] || 2,
    // 消息中心数据
    unReadMessageList: [
      {
        id: 0,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知知态势感",
        type: "态势感知",
        level: 3, // 3警报，2告警 1生化
        compnent: 1,
        time: 1619827200,
        title: "态势感知",
        reply: 0, // 是否有回复按钮
      },
      {
        id: 1,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知知态势感",
        type: "态势感知",
        level: 3, // 3警报，2告警 1生化
        compnent: 1,
        time: 1622505600000,
        title: "态势感知",
        reply: 1, // 是否有回复按钮
      },
      {
        id: 11,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知知态势感",
        type: "态势感知",
        level: 3, // 3警报，2告警 1生化
        compnent: 1,
        time: 1622505600000,
        title: "态势感知",
        reply: 1, // 是否有回复按钮
      },
      {
        id: 12,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知知态势感",
        type: "态势感知",
        level: 3, // 3警报，2告警 1生化
        compnent: 1,
        time: 1622505600000,
        title: "态势感知",
        reply: 1, // 是否有回复按钮
      },
      {
        id: 13,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知知态势感",
        type: "态势感知",
        level: 3, // 3警报，2告警 1生化
        compnent: 1,
        time: 1622505600000,
        title: "态势感知",
        reply: 1, // 是否有回复按钮
      },
      {
        id: 14,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知知态势感",
        type: "态势感知",
        level: 3, // 3警报，2告警 1生化
        compnent: 1,
        time: 1622505600000,
        title: "态势感知",
        reply: 1, // 是否有回复按钮
      },
      {
        id: 2,
        appName: "kongzhanbaojing",
        text: "态势感知态势感知态势感知态势感知态势感知态势",
        type: "球队天王山",
        level: 1, // 3警报，2告警 1生化
        compnent: 1,
        time: 1622972400000,
        title: "",
        reply: 1, // 是否有回复按钮
      },
    ],
    /**
     * 单条消息 item 数据格式 
     * {
        id:1,
        appName: 'taishiganzhi',
        text: '态势感知态势感知态势感知态势感知知态势感',
        type: '态势感知',
        level:3, // 3警报，2告警 1生化
        compnent:1,
        time: '10分钟',
        title:'态势感知',
        reply:0 // 是否有回复按钮
     * }
     */
    messageCenterData: {
      taishigaizhi: [
        {
          id: 1,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知态势感感",
          type: "态势感知",
          level: 3, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "态势感知",
          reply: 1, // 是否有回复按钮
        },
        {
          id: 11,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知态势感感",
          type: "态势感知",
          level: 3, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "态势感知",
          reply: 0, // 是否有回复按钮
        },
        {
          id: 12,
          appName: "taishiganzhi",
          text: "态势感知态势感知态势感知态势感知态势感感",
          type: "态势感知",
          level: 3, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "态势感知",
          reply: 0, // 是否有回复按钮
        },
      ],
      kongzhanbaojing: [
        {
          id: 2,
          appName: "kongzhanbaojing",
          text: "态势感知态势感知态势感知态势感知态势感知态势",
          type: "空战报警",
          level: 1, // 3警报，2告警 1生化
          compnent: 1,
          time: "10分钟",
          title: "",
          reply: 1, // 是否有回复按钮
        },
      ],
    }, // 消息中心数据结构，初始化时，需要从meaasgaeBox中过滤出未读数据，后续从websocket 推送过来
    /**
     * messageCenterData数据格式参考：按应用名称appName进行聚合。
     * {
     *   appName1: [{消息item}, {消息item}],
     *   appName2: [{消息item}]
     * }
     */
  },
});

// 创建appId的或框架的（system=true）EventBus的API接口
function createEventBus(appId = "", system = false) {
  return {
    $emit(eventName, payload) {
      bus.$emit(eventName, {
        payload,
        source: {
          appId,
          system,
        },
      });
    },
    $on(eventName, { appIds = [], system }, callback) {
      const _callback = ({ payload, source }) => {
        const matchAppIds = appIds.includes(source.appId);
        const matchSystem = system === true && source.system === true;
        if (matchAppIds || matchSystem) {
          callback(payload, source);
        }
      };
      bus.$on(eventName, _callback);

      function off() {
        bus.$off(eventName, _callback);
      }
      return off;
    },
  };
}

// 获取应用
async function getApp(appId) {
  const apps = [...bus.apps, ...bus.widgets];
  const app = apps.find((a) => a.id === appId);
  if (!app) {
    throw new Error(`应用或浮窗不存在(id: ${appId})`);
  }
  if (!app.instance) {
    // 从未加载过
    const start = Date.now();
    app.instance = await loadApp(app);
    app.loadTime = Date.now() - start;
  }
  return app;
}

// 加载应用
async function loadApp(app) {
  const onMessage = {
    ref: null,
  };
  let container = app.containerId;
  if (app.parentId) {
    const appDocument = window.document.querySelector(`#${app.parentId}`)
      .firstChild.shadowRoot;
    container = appDocument.getElementById(app.containerId);
  }

  const instance = loadMicroApp(
    {
      name: app.id,
      container: typeof container === "string" ? "#" + container : container,
      //container: typeof app.containerId === 'string' ? '#' + app.containerId : app.containerId,
      entry: app.entry,
      props: {
        tis: {
          ...tis,
          ...createEventBus(app.id),
          appId: app.id,
          entry: app.entry,
        },
        onMessage,
      },
    },
    {
      sandbox: {
        strictStyleIsolation: true,
        experimentalStyleIsolation: true,
      },
    }
  );
  await instance.bootstrapPromise.catch((err) => {
    console.error(`boostrap: [${app.name} ${app.id}] 出错`, err);
    throw err;
  });

  return {
    ...instance,
    show(params) {
      instance.update({
        show: true,
        params,
      });
    },
    hide() {
      instance.update({
        show: false,
      });
    },
    postMessage(params) {
      if (typeof onMessage.ref === "function") {
        return onMessage.ref(params);
      }
    },
  };
}
async function showApp(app, params) {
  const status = app.instance.getStatus();
  if (status === "NOT_MOUNTED") {
    try {
      if (!app.instance.mountPromise) {
        console.log("instance.mountPromise");
        await app.instance.mount(params);
      } else {
        console.log("instance.mountPromise111");
        await app.instance.mountPromise;
      }
    } catch (err) {
      console.error(`mount: [${app.name} ${app.id}] 出错`, err);
      app.instance = null;
      app.opened = false;
      app.instance.mountPromise = null;
      throw err;
    }
    app.instance.mountPromise = null;
  }
  app.opened = true;
  console.log("showlllll", params);
  app.instance.show(params);
}

const tis = {
  // 手动挂载axios
  axios: axios,
  Notifition: Notifition,

  layouts: {},
  // 获取全局变量
  getGlobalState(k) {
    return k ? bus.globalState[k] : bus.globalState;
  },
  // 设置全局变量
  setGlobalState(k, v) {
    Vue.set(bus.globalState, k, v);
    return bus.globalState[v];
  },
  // 获取所有应用
  getApps() {
    return bus.apps;
  },
  // 添加应用
  addApps(apps = []) {
    const newApss = apps.map((app) => {
      return {
        ...app,
        containerId: "app_" + app.id,
        opened: false,
      };
    });
    bus.apps = [...bus.apps, ...newApss];
  },
  // 获取当前打开的应用
  getCurrentApp() {
    const apps = bus.apps;
    const found = apps.find((a) => a.opened);
    return found;
    // const defaultApp = apps.find(a => a.default)
    // return found || defaultApp || apps[0]
  },
  getRunningApps() {
    return bus.apps.filter((a) => {
      return a.instance && a.instance.getStatus() === "MOUNTED";
    });
  },
  // 当前是否在主界面（应用）
  isMainApp() {
    const apps = bus.apps;
    const found = apps.find((a) => a.opened);
    return !found || found.default;
  },
  setApps(apps = []) {
    bus.apps = apps;
  },
  // 给应用/浮窗发消息
  async postMessage(appId, params = {}) {
    const app = await getApp(appId);
    return app.instance.postMessage(params);
  },
  // 打开应用, appId: 应用id，打开时传入的参数，会在应用的show生命周期收到
  async openApp(appId, params = {}) {
    bus.visibleSearchBox = false;
    bus.visibleNotificationWhole = false;
    const app = await getApp(appId);
    app.lastOpenTime = Date.now(); // 记录最后一次打开时间
    app.openTimes = (app.openTimes || 0) + 1; // 记录打开次数
    await showApp(app, params);
    // 休眠队列处理逻辑
    const index = bus.appsSleeping.indexOf(app.id);
    if (index !== -1) {
      bus.appsSleeping.splice(index, 1);
      bus.appsSleeping.unshift(app.id);
    } else {
      // 白名单里没这个才加入休眠队列
      if (!bus.whitelist.includes(app.id)) {
        // 队列过长，需要去除多余的
        if (bus.appsSleeping.length >= APPS_SLEEPING_LEN) {
          bus.appsSleeping.pop();
        }
        bus.appsSleeping.unshift(app.id);
      }
    }
    // 关闭其他显示的
    bus.apps.forEach((a) => {
      if (a !== app && a.opened && a.instance) {
        a.opened = false;
        // 休眠队列和白名单里的进行隐藏不卸载
        if (
          bus.whitelist.includes(a.id) ||
          bus.appsSleeping.indexOf(a.id) !== -1
        ) {
          a.instance.hide();
        } else {
          // 非白名单的进行卸载
          a.instance.unmount();
        }
      }
    });
    return app;
  },
  // 打开主界面（应用）
  async openDefaultApp() {
    const apps = bus.apps;
    const defaultApp = apps.find((a) => a.default);
    if (defaultApp) {
      tis.openApp(defaultApp.id);
    }
  },
  onHeaderVisible(val) {
    bus.headerVisible = val;
  },
  getHeaderVisible() {
    return bus.headerVisible;
  },
  // 关闭当前应用
  closeCurrentApp() {
    const a = this.getCurrentApp();
    if (!a) {
      return;
    }

    a.opened = false;
    // 休眠队列和白名单里的进行隐藏不卸载
    if (bus.whitelist.includes(a.id) || bus.appsSleeping.indexOf(a.id) !== -1) {
      a.instance.hide();
    } else {
      // 非白名单的进行卸载
      a.instance.unmount();
    }

    bus.appsSleeping.shift();
    // 把上一个应用重新显示出来
    const app = bus.apps.find((a) => a.id === bus.appsSleeping[0]);
    if (app) {
      app.opened = true;
      app.instance.show();
    }
  },
  // 显示打开的组件
  restoreOpenComponent() {
    JSON.parse(this.getSessionComponent()).map((item) => (bus[item] = true));
  },
  getSessionComponent() {
    // 获取session的组件
    return sessionStorage.getItem("openComponent") || JSON.stringify([]);
  },
  addSessionComponent(item) {
    // 添加session的组件
    let arr = JSON.parse(this.getSessionComponent());
    arr.push(item);
    sessionStorage.setItem("openComponent", JSON.stringify(arr));
  },
  delSessionComponent(item) {
    // 删除session的组件
    let arr = JSON.parse(this.getSessionComponent());
    if (arr.indexOf(item) !== -1) {
      arr.splice(arr.indexOf(item), 1);
    }
    sessionStorage.setItem("openComponent", JSON.stringify(arr));
  },
  // 关闭浮窗控制台
  closeAppsPanel() {
    this.delSessionComponent("visibleAppsPanel");
    bus.visibleAppsPanel = false;
  },
  // 打开浮窗控制台
  openAppsPanel() {
    this.addSessionComponent("visibleAppsPanel");
    bus.visibleAppsPanel = true;
  },
  // 浮窗控制台是否可见
  isVisibleAppsPanel() {
    return bus.visibleAppsPanel;
  },
  // 关闭浮窗控制台
  closeWidgetPanel() {
    this.delSessionComponent("visibleWidgetPanel");
    bus.visibleWidgetPanel = false;
  },
  // 打开浮窗控制台
  openWidgetPanel() {
    this.addSessionComponent("visibleWidgetPanel");
    bus.visibleWidgetPanel = true;
  },
  toggleWidgetPanel() {
    // 组件显示隐藏切换
    if (bus.visibleWidgetPanel) {
      this.delSessionComponent("visibleWidgetPanel");
    } else {
      this.addSessionComponent("visibleWidgetPanel");
    }
    bus.visibleWidgetPanel = !bus.visibleWidgetPanel;
  },
  // 浮窗控制台是否可见
  isVisibleWidgetsPanel() {
    return bus.visibleWidgetPanel;
  },

  // 关闭设置窗口
  closeWidgetSettingPanel() {
    this.delSessionComponent("visibleWidgetSysSetting");
    bus.visibleWidgetSysSetting = false;
  },
  // 打开设置窗口
  openWidgetSettingPanel() {
    this.addSessionComponent("visibleWidgetSysSetting");
    bus.visibleWidgetSysSetting = true;
  },
  // 设置窗口是否可见
  isVisibleWidgetsSettingPanel() {
    return bus.visibleWidgetSysSetting;
  },
  // 关闭登录窗口
  closeWidgetLoginPanel() {
    this.delSessionComponent("visibleWidgetLogin");
    bus.visibleWidgetLogin = false;
  },
  // 打开登录窗口
  openWidgetLoginPanel() {
    this.addSessionComponent("visibleWidgetLogin");
    bus.visibleWidgetLogin = true;
  },
  // 登录窗口是否可见
  isVisibleWidgetsLoginPanel() {
    return bus.visibleWidgetLogin;
  },
  // 关闭历史消息
  closeHistoricalNews() {
    this.delSessionComponent("visibleHistoricalNews");
    bus.visibleHistoricalNews = false;
  },
  // 打开历史消息
  openHistoricalNews() {
    this.addSessionComponent("visibleHistoricalNews");
    this.delSessionComponent("visibleNotificationWhole");
    bus.visibleHistoricalNews = true;
    bus.visibleNotificationWhole = false;
  },
  // 历史消息是否可见
  isVisibleHistoricalNews() {
    return bus.visibleHistoricalNews;
  },
  // 关闭全部消息
  closeNotificationWhole() {
    this.delSessionComponent("visibleNotificationWhole");
    bus.visibleNotificationWhole = false;
  },
  // 打开全部消息
  openNotificationWhole() {
    this.addSessionComponent("visibleNotificationWhole");
    bus.visibleNotificationWhole = true;
  },
  toggleNotifycationWhole() {
    if (bus.visibleNotificationWhole) {
      this.delSessionComponent("visibleNotificationWhole");
    } else {
      this.addSessionComponent("visibleNotificationWhole");
    }
    bus.visibleNotificationWhole = !bus.visibleNotificationWhole;
  },
  // 全部消息是否可见
  isVisibleNotificationWhole() {
    return bus.visibleNotificationWhole;
  },
  // 搜索框
  isVisibleSearchBox() {
    return bus.visibleSearchBox;
  },
  toggleSearchBox() {
    if (bus.visibleSearchBox) {
      this.delSessionComponent("visibleSearchBox");
    } else {
      this.addSessionComponent("visibleSearchBox");
    }
    bus.visibleSearchBox = !bus.visibleSearchBox;
  },
  toggleVoiceBox() {
    bus.visibleVoiceBox = !bus.visibleVoiceBox;
  },
  //点击关闭搜索框
  tggtoList() {
    bus.visibleSearchBox = false;
  },
  // togToListMail
  //时间设置
  visiblediwddae() {
    bus.visiblediwd = !bus.visiblediwd;
  },
  visiblediwdfalse() {
    bus.visiblediwd = false;
  },
  //人工定位弹框
  isVisiblelocationArtificial() {
    return bus.locationArtificial;
  },
  togglelocationArtificial() {
    bus.locationArtificial = !bus.locationArtificial;
  },
  //点击关闭人工定位框
  tggtolocationArtificial() {
    bus.locationArtificial = false;
  },
  // 通讯录弹框
  isVisibleMessageBox() {
    return bus.visibleMessageBox;
  },
  toggleMessageBox() {
    bus.visibleMessageBox = !bus.visibleMessageBox;
  },
  //点击关闭通讯录弹框
  togtomeslist() {
    bus.visibleMessageBox = false;
  },
  // 通知校时弹框
  isvisibleToListMail() {
    return bus.visibleToListMail;
  },
  toggToListMail() {
    bus.visibleToListMail = !bus.visibleToListMail;
  },
  //点击关闭通知校时弹框
  togToListMail() {
    bus.visibleToListMail = false;
  },
  //采点弹框
  isvisiMiningpoint() {
    return bus.visiMiningpoint;
  },
  toggMiningpoint() {
    bus.visiMiningpoint = !bus.visiMiningpoint;
  },
  //点击关闭采点弹框
  togMiningTo() {
    bus.visiMiningpoint = false;
  },
  // 关闭回复消息弹框
  closeDialogBox() {
    this.delSessionComponent("visibleDialogBox");
    bus.messageItem = {};
    bus.visibleDialogBox = false;
  },
  // 打开回复消息弹框，消息回复框全局只能有一个
  openDialogBox(message) {
    this.addSessionComponent("visibleDialogBox");
    bus.messageItem = message;
    bus.visibleDialogBox = true;
  },
  // 显示回复消息弹框
  isVisibleDialogBox() {
    return bus.visibleDialogBox;
  },
  changeTheme(theme) {
    if (bus.theme !== theme) {
      bus.theme = theme;
      this.$emit("changeTheme", theme);
    }
  },
  changeAppCardsVisible(visible) {
    this.$emit("visibleAppCards", visible);
  },
  // !!  注意 下面三个组件不能存放Session中
  // 关闭分屏组件
  closeAppScreen() {
    // this.delSessionComponent("visibleAppScreen");
    bus.visibleAppScreen = false;
  },
  // 打开分屏组件
  openAppScreen() {
    // this.addSessionComponent("visibleAppScreen");
    bus.visibleAppScreen = true;
  },
  // 查看分屏组件
  isAppScreen() {
    return bus.visibleAppScreen;
  },
  // 关闭查看消息弹框
  closeLookAtMsgBox() {
    this.delSessionComponent("visibleLookAtMsgBox");
    bus.visibleLookAtMsgBox = false;
  },
  // 打开查看消息弹框
  openLookAtMsgBox() {
    this.addSessionComponent("visibleLookAtMsgBox");
    bus.visibleLookAtMsgBox = true;
  },
  // 显示查看消息弹框
  isVisibleLookAtMsgBox() {
    return bus.visibleLookAtMsgBox;
  },
  // 获取当前要查看的消息
  getCurrMessage() {
    return bus.messageItem;
  },
  // 添加消息，按照 appName 的 key 进行添加
  addMessageData(message) {
    bus.unReadMessageList.push(message);
  },
  // 返回messageCenterData;
  getMessageCenterData() {
    return bus.unReadMessageList;
  },
  delMessage(id) {
    bus.unReadMessageList = bus.unReadMessageList.filter((v) => v.id !== id);
  },
  // 清空消息
  emptyMessage() {
    bus.unReadMessageList = [];
  },
  // 收到消息后进行提示
  showNotifyCation() {
    //展现右上角的逻辑。
  },
  addWidgets(widgets) {
    let widgetMap = {};
    for (let w of bus.widgets || []) {
      widgetMap[w.id] = true;
    }
    const newWidgets = widgets.map((w) => {
      let config = {
        containerId: "widget_" + w.id,
        ...w,
        opened: false,
      };
      if (widgetMap[w.id]) config.repeat = true;
      widgetMap[w.id] = true;
      return config;
    });
    bus.widgets = [...bus.widgets, ...newWidgets].filter((v) => !v.repeat);
  },
  // 获取所有浮窗
  getWidgets() {
    return bus.widgets;
  },
  // 获取当前打开的浮窗
  getOpenedWidgets() {
    return bus.widgets.filter((w) => w.opened);
  },

  //初始化消息中心的数据，该方法仅调用一次。
  initMessageBoxData() {
    // 请求数据接口，初始化bus中message相关的数据。
    // 请 207 的同学请求一下自己的消息列表接口，这块我们不知道详细的数据格式和协议。
    // 完成之后调用addMessage 方法，初始化消息中心首版数据。
    /**
    fetch(xxx).then(res => {
      // 自己按照需求处理了一下后端返回的数据逻辑
    })
     */
  },
  // 获取未读接口消息数量
  getMessageCount() {
    return bus.unReadMessageList.length;
  },
  getOpenedWidgetIds() {
    return bus.widgets.filter((w) => w.opened).map((w) => w.id);
  },
  _saveOpenedWidgets() {
    sessionStorage.setItem(
      "openedWidgets",
      JSON.stringify(this.getOpenedWidgetIds())
    );
  },
  restoreWidgetsFromSession() {
    try {
      const ids = sessionStorage.getItem("openedWidgets") || JSON.stringify([]);
      JSON.parse(ids).forEach((widgetId) => {
        this.openWidget(widgetId);
      });
    } catch (_) {
      console.error(_);
    }
  },
  // 打开浮窗组件
  async openWidget(widgetId, params = {}) {
    const widget = await getApp(widgetId);
    console.log("openWidget", widget);
    await showApp(widget, params);
    this._saveOpenedWidgets();
    return widget;
  },
  // 关闭浮窗组件
  closeWidget(widgetId) {
    const found = bus.widgets.find((w) => w.id === widgetId);
    if (found) {
      found.opened = false;
      found.instance.hide();
    }
    this._saveOpenedWidgets();
    return found;
  },
  worker,
  // 消息总线
  bus,
  ...createEventBus("", true),
  isFullScreen() {
    return bus.isFullScreen;
  },
  fullScreen(isFullScreen) {
    bus.isFullScreen = isFullScreen;
    if (isFullScreen) {
      // tis.hiddenAllVisible();
    }
  },
  hiddenAllVisible() {
    Object.keys(bus.registerVisible).map((key) => {
      bus[key] = false;
    });
    bus.applicationInterface.visible = false;
  },
};

hotkeysPlugin(tis);
voicePlugin(tis);
qtPlugin(tis);

Vue.prototype.$tis = new Proxy(
  {
    loadApp,
  },
  {
    get: function(o, k) {
      return o[k] || tis[k];
    },
  }
);

export function setup(options = {}) {
  const {
    layouts = {},
    whitelist = [],
    widgets = [],
    apps = [],
    wsUrl,
    hotkeys,
  } = options;
  tis.layouts = layouts;
  bus.whitelist = [...whitelist];

  const socket = new WebSocket(wsUrl);
  tis.socket = socket;

  // Connection opened
  socket.addEventListener("open", function(event) {
    console.log("open ws", event);
  });

  // Listen for messages
  socket.addEventListener("message", function(event) {
    const data = JSON.parse(event.data);
    if (data.type === "message") {
      tis.$emit("message", data.payload);
    }
  });

  // TODO remove me!!!
  setTimeout(() => {
    tis.$emit("message", {
      msgType: "SILENT_APP",
      message: {
        id: 1,
        appName: "taishiganzhi",
        text: "态势感知态势感知态势感知态势感知态势感感",
        type: "态势感知",
        level: Math.ceil(Math.random() * 3), // 3警报，2告警 1生化
        btn: Math.ceil(Math.random() * 3),
        compnent: 1,
        time: "10分钟",
        title: "态势感知",
        reply: 1, // 是否有回复按钮
      },
    });
  }, 2000);

  tis.addApps(apps);
  tis.addWidgets(widgets);
  // 打开之前的组件
  if (localStorage.getItem("placeList")) {
    let obj = JSON.parse(localStorage.getItem("placeList"));
    let key = JSON.parse(localStorage.getItem("user"));
    let list = obj[key];
    let actId = localStorage.getItem("actId");
    console.log(actId);
    list.map((item) => {
      if (item.id == actId && item.isOpenAppDemo) {
        // 打开应用
        tis.openApp("app_demo");
      }
    });
  } else {
    tis.openDefaultApp();
  }
  tis.openDefaultApp();
  // 打开之前的组件
  tis.restoreOpenComponent();
  tis.restoreWidgetsFromSession();
  tis.bindPlatformHotkeys(hotkeys);

  new Vue({
    render: (h) => h(App),
    router,
  }).$mount("#app");

  return tis;
}

export default tis;
