<template>
  <div class="status-bar-2" v-if="isMainApp">
    <div class="col col1">
      <div class="status-bar-seat" @click="dialogVisible = true">
        <div class="seat-info">指挥控制席<span class="seat-arrow" /></div>
      </div>
      <!-- 弹框 -->
      <el-dialog :visible.sync="dialogVisible" :modal="false">
        <span class="zhang-span">
          <div class="user-info">
            <div class="zhang-name">
              <div>姓名</div>
              <div class="zhang-divone-one">王建国</div>
            </div>
            <div class="zhang-divone">
              <div>席位</div>
              <div class="zhang-divone-one">-</div>
            </div>
            <div class="zhang-divone">
              <div>车辆</div>
              <div class="zhang-divone-one">-</div>
            </div>
            <div class="zhang-divone">
              <div>编号</div>
              <div class="zhang-divone-one">-</div>
            </div>
            <div class="zhang-divone">
              <div>兵团</div>
              <div class="zhang-divone-one">-</div>
            </div>
            <div class="zhang-divone">
              <div>等级</div>
              <div class="zhang-divone-one">-</div>
            </div>
          </div>
          <div class="menu-list">
            <div class="item" @click="onSystemSetting">
              <div class="left">系统设置</div>
              <div class="right"></div>
            </div>
            <div class="item" @click="goSwitch">
              <div class="left">切换用户</div>
              <div class="right"></div>
            </div>
          </div>
          <div class="footer">
            <button class="zhang-btn1" @click="goSign">退出登录</button>
          </div>
          <!-- <div class="zhang-state">
        <button class="zhang-btn1" @click="goSwitch">切换用户</button
        ><span class="zhang-span1">/</span
        ><button class="zhang-btn2" @click="goSign">退出登录</button>
      </div> -->
        </span>
      </el-dialog>
    </div>

    <div class="col col2">
      <OperationalInformationPad />
    </div>

    <div class="col col3">
      <ActionBarMobile />
    </div>
  </div>
</template>
<script>
const clickoutside = {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el._vueClickOutside_ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el) {
    document.removeEventListener("click", el._vueClickOutside_);
    delete el._vueClickOutside_;
  },
};
import OperationalInformationPad from "./OperationalInformationMobile.vue";
import ActionBarMobile from "./ActionBarMobile.vue";

export default {
  components: { OperationalInformationPad, ActionBarMobile },
  data() {
    return {
      value: "",
      dialog1Visible: false,
      dialogVisible: false,
      dialogVisible1: false,
      appCardsVisible: false,
      mingluVisible: false,
      dingweiVisible: false,
      timerSetVisible: false,
      radio: 1,
      radiolist: [
        {
          id: 0,
          name: "上级时间",
        },
        {
          id: 1,
          name: "本机系统时间",
        },
        {
          id: 2,
          name: "北斗",
        },
      ],
      tabList: null,
      listTab: [
        {
          id: 0,
          name: "同步天文时间",
        },
        {
          id: 1,
          name: "同步上级作战时间",
        },
      ],
    };
  },
  directives: { clickoutside },
  computed: {
    isMainApp() {
      // 当前是否是主界面应用
      return this.$tis.isMainApp();
    },
    currentApp() {
      return this.$tis.getCurrentApp();
    },
    messageCounts() {
      return this.$tis.getMessageCount();
    },
  },
  mounted() {},
  methods: {
    onSystemSetting() {
      this.dialogVisible = false;
      this.$tis.bus.registerVisible.messageSettingVisible = true;

      this.$tis.bus.messageSettingVisible = true;
      this.$tis.bus.applicationInterface = {
        visible: true,
        name: "系统设置",
      };
    },
    //切换用户
    goSwitch() {
      sessionStorage.removeItem('token');
      window.location.reload();
    },
    //退出登录
    goSign() {
      sessionStorage.removeItem('token');
      window.location.reload();
    },
    closeCurrentApp() {
      this.$tis.closeCurrentApp();
    },
    openWidgetPanel() {
      this.$tis.openWidgetPanel();
    },

    openNotificationWhole(e) {
    },
    //搜索
    toggleSearchBox() {},

    //通讯录
    toggleMessageBox() {},
  },
};
</script>
<style lang="scss" scoped>
$barHeight: 43px;
.status-bar {
  height: $barHeight;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: var(--status-bar-text-color);
  background-image: var(--status-app-bar-bg-img);
  background-repeat: no-repeat;
  background-size: cover;
  .back {
    height: 100%;
    cursor: pointer;
  }
  .title {
    font-size: 21px;
    margin: 0 33px 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
  }
  .status-bar-sys {
    margin-left: 15px;
    margin-right: 7px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.5);
    .item-signal {
      margin: 3px;
      padding-left: 16px;
      background-image: url(/signal.svg);
      background-repeat: no-repeat;
      background-size: 9px 10px;
      background-position: 0 center;
    }
    .item-battery {
      margin: 3px;
      padding-left: 20px;
      background-image: url(/battery.png);
      background-repeat: no-repeat;
      background-size: 13px 8px;
      background-position: 0 center;
    }
  }
  .tools {
    margin-left: 15px;
    display: flex;
    align-items: center;
    .item-tool {
      cursor: pointer;
      margin: 0 7px;
      display: flex;
      align-items: center;
      img {
        display: block;
      }
      span {
        font-size: 18px;
        margin-left: 6px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    margin-right: 11px;
  }
  .email {
    position: relative;
    padding: 0 21px;
    height: 100%;
    cursor: pointer;
    margin-left: 1px;
    opacity: 0.8;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    span {
      margin-left: 7px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 1px 0 1px 0 #4c4a48;
      background-image: linear-gradient(
        180deg,
        rgba(115, 110, 102, 0.02) 0%,
        rgba(63, 59, 54, 0.18) 100%
      );
      transform: skewX(-26deg);
    }
  }
  .widgets {
    position: relative;
    z-index: 1;

    padding: 0 21px;
    height: 100%;
    cursor: pointer;
    margin-left: 1px;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    span {
      margin-left: 7px;
      color: var(--status-bar-widget-btn-color);
      text-shadow: var(--status-bar-widget-btn-text-shadow);
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: var(--status-bar-widget-btn-bg-img);
      box-shadow: var(--status-bar-widget-box-shadow);
      transform: skewX(-26deg);
    }
  }

  .close {
    flex-grow: 0;
    position: relative;
    height: 100%;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 1px;
    position: relative;
    margin-right: -15px;
    padding: 0 15px 0 7px;

    img {
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: skewX(-26deg);
      background-image: var(--status-bar-close-bg-image);
    }
  }
}

.status-bar-2 {
  height: 80px;
  // margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: var(--status-bar-text-color);
  opacity: var(--status-bar-bg-opacity);
  background-color: var(--status-bar-bg-color);
  background-image: var(--status-bar-bg-img);
  box-shadow: var(--status-bar-box-shadow);
  border-bottom: var(--status-bar-border);
  display: flex;
  > .col1 {
    min-width: 169px;
    height: 100%;
  }
  > .col2 {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    > .action {
      flex: 1;
      position: relative;
      min-width: 67px;
      height: 100%;
      img {
        width: 67px;
        position: absolute;
        left: -1px;
        bottom: -10px;
      }
    }
    > .status-bar-info:last-child {
      margin-right: 0;
    }
  }
  > .col3 {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    > .grow {
      flex-grow: 1;
    }
    > .info {
      position: absolute;
      top: 1px;
      cursor: pointer;

      right: 15px;
      span {
        font-size: 9px;
        margin: 0px 11px;
      }
    }
  }
}

.status-bar-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
  p {
    margin: 0;
    &:last-child {
      margin-top: 4px;
    }
  }
  .item-info {
    margin: 0 1px;
  }
}

.status-bar-seat {
  width: 100%;
  height: 100%;
  // padding-right: 64px;
  position: relative;
  &:before {
    content: "";
    background-image: var(--status-bar-mobile-left-img);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .seat-info {
    position: relative;
    height: 100%;
    line-height: 80px;
    text-align: center;
    cursor: pointer;
    font-size: 30px;
    color: #313131;
    padding-left: 23px;
    padding-right: 77px;
  }
  .seat-arrow {
    display: inline-block;
    border: 6px solid #313131;
    width: 7px;
    height: 7px;
    border-top: 0 none;
    border-right: 0 none;
    transform: translate(0, -9px) rotate(-45deg);
    margin-left: 18px;
    position: relative;
    top: 3px;
    // color: #313131;
  }
}
.zhang-name {
  display: flex;
  font-size: 10px;
  color: #ffffff;
}
.zhang-divone {
  display: flex;
  margin-top: 20px;
  font-size: 10px;
  color: #ffffff;
}
.zhang-divone-one {
  font-size: 10px;
  // color: #8e908e;
  color: #8e908e;
  margin-left: 15px;
}
.zhang-state {
  display: flex;
  margin-top: 38px;
  margin-left: -7px;
}
.zhang-btn1 {
  width: 61px;
  font-size: 10px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  background: none;
}

.minlu {
  position: relative;
  background: red;
  .pop {
    position: absolute;
    top: 46px;
    left: -77px;
    z-index: 200;
    img {
      width: 231px;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}

::v-deep .el-dialog {
  margin-top: 0px !important;

  top: 0px;
  width: 328px;
  margin: initial;
  margin-left: 0px;
  height: 100vh;

  .el-dialog__body {
    background: #262626;
    // background: #000000;
    // opacity: 85%;
    color: white;
    padding: 30px 23px;
    margin-top: 0px;
    height: 100vh;
    position: relative;
    box-sizing: border-box;

    .user-info {
      font-size: 25px;
      padding-top: 10px;
      padding-bottom: 25px;
      border-bottom: 1px solid #fff;
      div {
        font-size: 21px;
      }
    }
    .menu-list {
      font-size: 21px;
      margin-top: 15px;
      .item {
        display: flex;
        padding: 23px 0;
        justify-content: space-between;
        .right {
          position: relative;
          &:after {
            content: "";
            display: inline-block;
            position: relative;
            top: 10px;
            border: 3px solid #fff;
            width: 8px;
            height: 8px;
            border-top: 0 none;
            border-right: 0 none;
            transform: translate(0, -9px) rotate(224deg);
            margin-left: 18px;
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 15px;
      width: 90%;
      left: 5%;
      height: 58px;
      line-height: 38px;
      text-align: center;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      button {
        width: 100%;
        font-size: 21px;
      }
    }
  }
  .el-dialog__headerbtn {
    position: absolute;
    top: -15px;
    right: 9px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    font-size: 7px;
    display: none;
  }
  .el-dialog__header {
    padding: 0;
  }
  .status-bar-info {
    cursor: pointer;
  }
}
.timeSetting {
  background: rgba(0, 0, 0, 0.85);
  // border: 1px solid red;
  width: 283px;
  height: 283px;
  margin-top: 9px;
}
.timeSetting-top {
  color: #ffdd55;
  padding-left: 11px;
  padding-top: 7px;
  font-size: 13px;
}
.timeSetting-source {
  font-size: 12px;
  color: white;
  padding-left: 11px;
  margin-top: 15px;
}
.timeSetting-source-one {
  display: flex;
  margin-top: 11px;
  .sourceOne {
    display: flex;
    margin-left: 11px;
    .radioOne {
      border: 1px solid #d8d8d8;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      cursor: pointer;
    }
    .radioOnes {
      border: 1px solid #d8d8d8;
      background: #f7c829;
      width: 11px;
      height: 11px;
      border-radius: 50%;
    }
    .sourceOne-one {
      font-size: 12px;
      color: #ffffff;
      margin-left: 6px;
    }
  }
}
.timeSetting-timeFight {
  font-size: 12px;
  color: white;
  padding-left: 11px;
  margin-top: 23px;
}
.timeFight {
  display: flex;
  margin-top: 11px;
  .timeFight-one {
    display: flex;
    margin-left: 11px;
    .radioTwo {
      border: 1px solid #d8d8d8;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      cursor: pointer;
    }
    .radioTwos {
      border: 1px solid #d8d8d8;
      width: 11px;
      height: 11px;
      background: #f7c829;
      border-radius: 50%;
    }
    .timeFight-Astronomy {
      font-size: 12px;
      color: #ffffff;
      margin-left: 6px;
    }
  }
  .timeFight-two {
    display: flex;
    margin-left: 26px;
    .radioFive {
      border: 1px solid #d8d8d8;
      width: 11px;
      height: 11px;
      border-radius: 50%;
    }
    .timeFight-superior {
      font-size: 12px;
      color: #ffffff;
      margin-left: 6px;
    }
  }
}
.time-setup {
  display: flex;
  margin-left: 11px;
  margin-top: 23px;
  .time-setup-one {
    font-size: 12px;
    color: #ffffff;
  }
  .time-setup-two {
    font-size: 12px;
    color: #ffdd55;
    text-align: center;
    margin-left: 137px;
    cursor: pointer;
  }
}

.broadcast {
  display: flex;
  margin-top: 15px;
  .broadcast-one {
    display: flex;
    margin-left: 11px;
    input[type="checkbox"] {
      cursor: pointer;
      position: relative;
      width: 11px;
      height: 11px;
      font-size: 10px;
    }

    input[type="checkbox"]::after {
      position: absolute;
      top: 0;
      color: #000;
      width: 11px;
      height: 11px;
      display: inline-block;
      visibility: visible;
      padding-left: 0px;
      text-align: center;
      content: " ";
      border-radius: 2px;
    }

    input[type="checkbox"]:checked::after {
      content: "✓";
      color: #fff;
      font-size: 9px;
      font-weight: bold;
      background-color: #f7c829;
    }
    .inCar {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #ffffff;
      margin-left: 7px;
    }
  }
  .time-Btn {
    width: 55px;
    height: 30px;
    background: #ffcc00;
    font-size: 12px;
    color: #313131;
    text-align: center;
    border: none;
    margin-left: 135px;
    cursor: pointer;
  }
  .time-Btn:hover {
    color: #ffdd55;
  }
}
.time-one {
  border: 1px solid #f7dd29;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  left: 0px;
  border-bottom: none;
  border-right: none;
}
.time-two {
  border: 1px solid #f7dd29;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  right: 0px;
  border-bottom: none;
  border-left: none;
}
.time-three {
  border: 1px solid #f7dd29;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 277px;
  right: 0px;
  border-top: none;
  border-left: none;
}
.time-fore {
  border: 1px solid #f7dd29;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 277px;
  left: 0px;
  border-top: none;
  border-right: none;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 258px;
  margin-top: 11px;
  margin-left: 11px;
}
::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-image: none;
  background: #292929;
  border: 1px solid #979797;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  outline: 0;
  padding: 0 23px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.w-dialog {
  position: fixed;
  width: 462px;
  background: rgba(0, 0, 0, 0.8);
  padding: 0 18px;
  z-index: 999;
  .angle-mark {
    border: 3px solid #f7dd29;
    width: 15px;
    height: 15px;
    position: absolute;

    &.left-top {
      top: -1px;
      left: -1px;
      border-bottom: none;
      border-right: none;
    }
    &.right-top {
      right: -1px;
      top: -1px;
      border-left: none;
      border-bottom: none;
    }
    &.left-bottom {
      bottom: -1px;
      left: -1px;
      border-top: none;
      border-right: none;
    }
    &.right-bottom {
      right: -1px;
      bottom: -1px;
      border-left: none;
      border-top: none;
    }
  }
  .title {
    height: 61px;
    line-height: 61px;
    font-size: 23px;
    color: #f7dd29;
    display: flex;
    justify-content: space-between;
    .right.close-btn {
      position: relative;
      width: 30px;
      text-align: center;
      &::after {
        content: "";
        height: 1px;
        width: 15px;
        background: #f7dd29;
        position: absolute;
        top: 50%;
      }
    }
  }
  &.w-dialog1 {
    width: 462px;
    top: 107px;
    right: 38px;
    padding-bottom: 23px;
    .item {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #ffcb00;
      margin-bottom: 18px;
      padding: 15px;
      .row1 {
        font-size: 21px;
        color: #ffffff;
      }
      .row2 {
        font-family: EurostileRegular;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
</style>
