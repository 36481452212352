import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    name: 'app',
    path: '/',
    redirect: '/index',
    component: () => import('../App.vue'),
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: 'index',
    },
    component: () => import('../index.vue'),
  },
  {
    path: '/pad',
    name: 'pad',
    meta: {
      title: 'pad',
    },
    component: () => import('../pad.vue'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    meta: {
      title: 'mobile',
    },
    component: () => import('../mobile.vue'),
  },
];

const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash
        };
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    }
  }
});

export default router;
