<template>
  <div class="widgets-view">
    <div class="right" @click="onActive('right')" :class="{active:activePos === 'right'}">
      <template v-for="(w, index) in list">
        <div v-if="w.position === 'right'" :key="index" class="item">
          <component :is="Widget" :widget="w" />
        </div>
      </template>
    </div>
    <div class="bottom" @click="onActive('bottom')" :class="{active:activePos === 'bottom'}">
      <template v-for="(w, index) in list">
        <div v-if="w.position === 'bottom'" :key="index" class="item">
          <component :is="Widget" :widget="w" />
        </div>
      </template>
    </div>
    <div class="custom" @click="onActive('custom')" :class="{active:activePos === 'custom'}">
      <template v-for="(w, index) in list">
        <div v-if="w.position === 'custom'" :key="index" class="item">
          <component :is="WidgetDR" :widget="w" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    Widget() {
      return this.$tis.layouts.Widget;
    },
    WidgetDR() {
      return this.$tis.layouts.WidgetDR;
    },
    list() {
      return this.$tis.getWidgets();
    },
  },
  data() {
    return {
      activePos: 'right'
    }
  },
  methods: {
    onActive(pos) {
      this.activePos = pos;
    }
  },
};
</script>

<style lang="scss" scoped>
.widgets-view {
  .custom {
    z-index: 220;
    position: fixed;
    position: fixed;
    left: 0;
    top: 0;
    &.active {
      z-index: 250;
    }
  }
  .right {
    &.active {
      z-index: 250;
    }
    position: fixed;
    right: 20px;
    top: 70px;
    width: 300px;
    // bottom: 160px;
    overflow: hidden;
    z-index: 200;
  }
  .bottom {
    &.active {
      z-index: 250;
    }
    position: fixed;
    overflow: hidden;
    bottom: 55px;
    right: 20px;
    left: 100px;
    z-index: 210;
  }
  .item {
    // pointer-events: initial;
    margin: 10px;
  }
}
</style>