<template>
	<div class="widgets-panel">
		<div class="header">
			<div class="title">添加浮窗到窗口</div>
			<div>
				<el-button @click="onClose" type="default">关闭</el-button>
				<el-button @click="onConfirm" type="primary">确认添加</el-button>
			</div>
		</div>
		<div class="content">
			<div v-for="w in list" :key="w.id" class="item" @click="() => onClickItem(w)">
				<div class="name">
					<span>{{ w.name }}</span>
					<el-checkbox :value="w.openedModel" @change="() => onClickItem(w)" />
				</div>
				<img :src="w.icon" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
			};
		},
		computed: {
			Widget() {
				return this.$tis.layouts.Widget;
			},
		},
		created() {
			this.list = this.$tis.getWidgets().map((w) => {
				return {
					...w,
					opened: !!w.opened,
					openedModel: !!w.opened,
				};
			});
			console.log(this.list)
		},
		methods: {
			onConfirm() {
				this.list.forEach((w) => {
					if (w.openedModel !== w.opened) {
						// 有变化
						if (w.openedModel) {
							this.$tis.openWidget(w.id);
						} else {
							this.$tis.closeWidget(w.id);
						}
					}
				});
				this.$tis.closeWidgetPanel();
			},
			onClose() {
				this.$tis.closeWidgetPanel();
			},
			onClickItem(w) {
				w.openedModel = !w.openedModel;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.widgets-panel {
		padding: 20px;
		background: rgba(0, 0, 0, 0.9);
		height: 100%;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				font-size: 18px;
				padding: 10px;
			}

			.add {
				padding: 6px 20px;
				font-size: 15px;
			}
		}

		.content {
			display: flex;
			flex-wrap: wrap;

			.item {
				cursor: pointer;

				.name {
					text-align: left;
					font-size: 15px;
					margin: 10px;
					display: flex;
					justify-content: space-between;
				}

				img {
					border: none;
					border-top: 1px solid #ffffff;
					display: block;
					margin: 0;
					width: 300px;
					height: 170px;
				}

				margin: 10px;
				border: 1px solid #ffffff;
			}
		}
	}
</style>
<style lang="scss">
	// .el-button--primary {
	//   background-color: #ffdd55;
	//   border-color: #ffdd55;
	//   border-radius: 0;
	//   color: #333;
	//   &:hover {
	//     background-color: #ffdd55;
	//     border-color: #ffdd55;
	//     border-radius: 0;
	//     color: #333;
	//   }
	// }
	.el-button--default {
		border-radius: 0;
		background-color: white;
		border-color: white;
		color: #333;

		&:hover {
			background-color: white;
			border-color: white;
			color: #333;
		}
	}
</style>
