<template>
  <div class="people">
    <div class="zhang-topframeone">
      <div class="topframeone-top">
        <div class="topframeone-topone">人工定位</div>
        <div style="display: flex">
          <img src="../assets/定位.png" class="dingTop"/>
          <div class="topframeone-toptwo" @click.stop="toggMiningpoint">采点</div>
        </div>
      </div>
      <div class="frameoneCoordinate">经纬坐标</div>
      <div class="longitude">
        <div class="longitude-one">经度</div>
        <input type="text" class="longitude-two" placeholder="121°10′55.648″" />
        <!-- <div class="longitude-two">121°10′55.648″</div> -->
      </div>
      <div class="latitude">
        <div class="latitude-one">纬度</div>
        <!-- <div class="latitude-two">121°10′55.648″</div> -->
        <input type="text" class="latitude-two" placeholder="121°10′55.648″" />
      </div>
      <div class="frameoneCoordinate">直角坐标</div>
      <div class="frameangle">
        <div class="frameX">
          <div class="frameX-one">X</div>
          <!-- <div class="frameX-two">121.000000</div> -->
          <input type="text" class="frameX-two" placeholder="121.000000" />
        </div>
        <div class="frameY">
          <div class="frameY-one">Y</div>
          <!-- <div class="frameY-two">121.000000</div> -->
          <input type="text" class="frameY-two" placeholder="121.000000" />
        </div>
      </div>
      <div class="frameH">
        <div class="frameH-one">H</div>
        <!-- <div class="frameH-two">121.000000</div> -->
        <input type="text" class="frameH-two" placeholder="121.000000" />
      </div>
      <div class="frameSpeed">
        <div class="frameSpeed-one">速度</div>
        <!-- <div class="frameSpeed-two">121.000000</div> -->
        <input type="text" class="frameSpeed-two" placeholder="121.000000" />
        <div class="frameSpeed-three">m/s</div>
      </div>
      <div class="frameDirection">
        <div class="frameDirection-one">方向</div>
        <!-- <div class="frameDirection-two">121.000000</div> -->
        <input
          type="text"
          class="frameDirection-two"
          placeholder="121.000000"
        />
        <div class="frameDirection-three">密位</div>
      </div>
      <div class="frameBottom">
        <div class="frameBottom-one">
          <div class="frameBottom-oneyi">定位来源：北斗</div>
          <div class="frameBottom-oneer">定位时间：2021/04/04 11:22:22</div>
        </div>
        <button class="frameBtn">确定</button>
      </div>
      <div class="frame-one"></div>
      <div class="frame-two"></div>
      <div class="frame-three"></div>
      <div class="frame-fore"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    toggMiningpoint(){
      this.$tis.toggMiningpoint();
       // 关闭人工定位
      this.$tis.tggtolocationArtificial();
      // 关闭通讯录
      this.$tis.togtomeslist();
      // 关闭通知校时
      this.$tis.togToListMail();
    }
  
  },
};
</script>

<style lang='scss' scoped>
.people {
  width: 368px;
  height: 552px;
  // background:rgba(0, 0, 0, .85);
}
.zhang-topframeone {
  width: 368px;
  height: 552px;
  background: rgba(0, 0, 0, 0.85);
}
.topframeone-top {
  display: flex;
  justify-content: space-between;
  .topframeone-topone {
    font-size: 18px;
    color: #ffdd55;
    margin-top: 10px;
    margin-left: 15px;
  }
  .topframeone-toptwo {
    color: #ffdd55;
    font-size: 16px;
    margin-top: 10px;
    margin-right: 15px;
    cursor: pointer;
    .toptwoimg {
      width: 30px;
      height: 30px;
      color: #ffdd55;
    }
  }
}
.frameoneCoordinate {
  color: white;
  margin-top: 25px;
  margin-left: 17px;
  font-size: 16px;
}
.longitude {
  display: flex;
  margin-top: 20px;
  .longitude-one {
    margin-left: 42px;
    font-size: 16px;
  }
  .longitude-two {
    background: #292929;
    border: 1px solid #979797;
    width: 147px;
    height: 40px;
    text-align: center;
    margin-left: 10px;
    margin-top: -10px;
    font-size: 14px;
    color: #ffffff;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }
}
.longitude-two:hover {
  border: 1px solid #f7c829;
  // color:red;
  cursor: pointer;
}
.latitude {
  display: flex;
  margin-top: 25px;
  .latitude-one {
    margin-left: 42px;
    font-size: 16px;
  }
  .latitude-two {
    background: #292929;
    border: 1px solid #979797;
    width: 147px;
    height: 40px;
    text-align: center;
    margin-left: 10px;
    margin-top: -10px;
    font-size: 14px;
    color: #ffffff;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }
  .latitude-two:hover {
    border: 1px solid #f7c829;
    cursor: pointer;
  }
}
.frameangle {
  display: flex;
  margin-top: 15px;
  .frameX {
    display: flex;

    .frameX-one {
      font-size: 16px;
      margin-left: 53px;
      margin-top: 8px;
    }
    .frameX-two {
      background: #292929;
      border: 1px solid #979797;
      width: 109px;
      height: 40px;
      margin-left: 17px;
      line-height: 40px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
      padding-left:28px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      padding-left:28px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      padding-left:28px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
      padding-left:28px;
    }
    .frameX-two:hover {
      border: 1px solid #f7c829;
      cursor: pointer;
    }
  }
  .frameY {
    display: flex;
    .frameY-one {
      font-size: 16px;
      margin-top: 8px;
      margin-left: 15px;
    }
    .frameY-two {
      background: #292929;
      border: 1px solid #979797;
      width: 109px;
      height: 40px;
      margin-left: 10px;
      line-height: 40px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #fff;
      padding-left:28px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      padding-left:28px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      padding-left:28px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #fff;
      padding-left:28px;
    }
    .frameY-two:hover {
      border: 1px solid #f7c829;
      cursor: pointer;
    }
  }
}
.frameH {
  display: flex;
  margin-top: 15px;
  .frameH-one {
    font-size: 16px;
    margin-top: 8px;
    margin-left: 54px;
  }
  .frameH-two {
    background: #292929;
    border: 1px solid #979797;
    width: 109px;
    height: 40px;
    margin-left: 17px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
    padding-left:28px;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    padding-left:28px;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    padding-left:28px;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
    padding-left:28px;
  }
  .frameH-two:hover {
    border: 1px solid #f7c829;
    cursor: pointer;
  }
}
.frameSpeed {
  display: flex;
  margin-top: 15px;
  .frameSpeed-one {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 36px;
  }
  .frameSpeed-two {
    background: #292929;
    border: 1px solid #979797;
    width: 109px;
    height: 40px;
    margin-left: 17px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
    padding-left:28px;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    padding-left:28px;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    padding-left:28px;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
    padding-left:28px;
  }
  .frameSpeed-two:hover {
    border: 1px solid #f7c829;
    cursor: pointer;
  }
  .frameSpeed-three {
    font-size: 16px;
    color: #cbcbcb;
    margin-left: 10px;
    margin-top: 10px;
  }
}
.frameDirection {
  display: flex;
  margin-top: 15px;
  .frameDirection-one {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 36px;
  }
  .frameDirection-two {
    background: #292929;
    border: 1px solid #979797;
    width: 109px;
    height: 40px;
    margin-left: 17px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
    padding-left:28px;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    padding-left:28px;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    padding-left:28px;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
    padding-left:28px;
  }
  .frameDirection-two:hover {
    border: 1px solid #f7c829;
    cursor: pointer;
  }
  .frameDirection-three {
    font-size: 16px;
    color: #cbcbcb;
    margin-left: 10px;
    margin-top: 10px;
  }
}
.frameBottom {
  display: flex;
  margin-top: 35px;
  margin-left: 20px;
  .frameBottom-one {
    .frameBottom-oneyi {
      opacity: 0.38;
      font-size: 14px;
      color: #ffffff;
    }
    .frameBottom-oneer {
      opacity: 0.38;
      font-size: 14px;
      color: #ffffff;
      margin-top: 3px;
    }
  }
  .frameBtn {
    background: #ffcc00;
    color: #36342f;
    border: none;
    margin-left: 55px;
    width: 72px;
    height: 40px;
    // margin-top: 15px;
    font-size: 16px;
    color: #313131;
    text-align: center;
  }
  .frameBtn:hover {
    color: #ffdd55;
    cursor: pointer;
  }
}
.frame-one {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-bottom: none;
  border-right: none;
}
.frame-two {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  right: 0.5px;
  border-bottom: none;
  border-left: none;
}
.frame-three {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 530px;
  right: 0.5px;
  border-top: none;
  border-left: none;
}
.frame-fore {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 530px;
  left: 0px;
  border-top: none;
  border-right: none;
}
.dingTop {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  margin-top: 10px;
  cursor: pointer;
}
</style>