import { QWebChannel, QObject } from './qwebchannel'

export default function qtPlugin(tis) {

  //   const callMeParams = {
  //     "name": "wechat", // QT程序名称
  //     "path": "/path/wechat.exe", // QT程序放置路径
  //     "op": 1, // 0-关闭应用；1-启动应用
  //     "cmd":"-dis", // 执行参数-附带命令行
  //     "other":"", // 预留
  // }

  function jscallme(callMeParams) {
    return new Promise((resolve, reject) => {
      if (!window.qt) {
        reject(new Error('当前不在QT环境里'))
      }
      new QWebChannel(window.qt.webChannelTransport,
        (channel) => {
          const webobj = channel.objects.webobj;
          const resp = webobj.jscallme(JSON.stringify(callMeParams));
          resolve(resp);
        });
    })
  }

  tis.qt = {
    QWebChannel,
    QObject,
    jscallme
  }
}