<template>
  <div class="sider" v-show="show">    
    <div class="menu-item active">
      <div
        class="icon"
        :style="{ maskImage: 'url(/check-icon.svg)', maskSize: '2.2em' }"
      ></div>
      <div class="title">球队筹划</div>
    </div>
    <div class="menu-item" @click="onShowAppsPanel">
      <div class="icon" :style="{ maskImage: 'url(/menu-icon.svg)' }"></div>
      <div class="title">全部应用</div>
    </div>
    <div class="menu-item" @click="onShowAppsPanel">
      <div class="icon" :style="{ maskImage: 'url(/compass-icon.svg)' }"></div>
      <div class="title">最近打开</div>
    </div>

    <div class="menu-item">
      <div class="icon" :style="{ maskImage: 'url(/shield-icon.svg)' }"></div>
      <div class="title">待定</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    show() {
      // 只有在主界面应用时，才显示
      return this.$tis.isMainApp();
    },
  },
  methods: {
    onShowAppsPanel() {
      this.$tis.openAppsPanel();
    },
    onShowWidgetsPanel() {
      this.$tis.openWidgetPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  position: relative;
  font-size: 23px;
  cursor: pointer;
  margin: 10px 0;
  height: 4.5em;
  width: 4.5em;
  overflow: hidden;

  background-image: url("~@/assets/normal-menu-item-bg.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 80%;

  .icon {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    mask-repeat: no-repeat;
    mask-position: 50% 35%;
    mask-size: 1.3em;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .title {
    position: absolute;
    bottom: 0.8em;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 0.72em;
    color: #888;
  }
  &:hover,
  &.active {
    background-image: url("~@/assets/active-menu-item-bg.svg");
    background-size: 100%;
    .icon {
      background: #fff;
    }
  }
}

.sider {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
}
</style>
