<template>
  <div class="status-bar-2" v-if="isMainApp">
    <div class="col col1">
      <div class="status-bar-seat" @click="dialogVisible = true">
        <div class="seat-info">王建国<span class="seat-arrow" /></div>
      </div>
      <!-- 弹框 -->
      <el-dialog :visible.sync="dialogVisible" :modal="false">
        <span class="zhang-span">
          <div class="user-info">
            <div class="zhang-name">
              <div>姓名</div>
              <div class="zhang-divone-one">王建国</div>
            </div>
            <div class="zhang-divone">
              <div>编号</div>
              <div class="zhang-divone-one">00000000000</div>
            </div>
            <div class="zhang-divone">
              <div>球队</div>
              <div class="zhang-divone-one">0000湖人队</div>
            </div>
            <div class="zhang-divone">
              <div>等级</div>
              <div class="zhang-divone-one">二等</div>
            </div>
          </div>
          <div class="menu-list">
            <div class="item" @click="onSystemSetting">
              <div class="left">系统设置</div>
              <div class="right"></div>
            </div>
            <div class="item" @click="goSwitch">
              <div class="left">切换用户</div>
              <div class="right"></div>
            </div>
          </div>
          <div class="footer">
            <button class="zhang-btn1" @click="goSign">退出登录</button>
          </div>
          <!-- <div class="zhang-state">
            <button class="zhang-btn1" @click="goSwitch">切换用户</button
            ><span class="zhang-span1">/</span
            ><button class="zhang-btn2" @click="goSign">退出登录</button>
          </div> -->
          <div class="zhang-one"></div>
          <div class="zhang-two"></div>
          <div class="zhang-three"></div>
          <div class="zhang-fore"></div>
        </span>
      </el-dialog>
    </div>

    <div class="col col2">
      <OperationalInformationPad />
    </div>

    <div class="col col3">
      <ActionBarPad />
    </div>
  </div>
</template>

<script>
const clickoutside = {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el._vueClickOutside_ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el) {
    document.removeEventListener("click", el._vueClickOutside_);
    delete el._vueClickOutside_;
  },
};
import OperationalInformationPad from "./OperationalInformationPad.vue";
import ActionBarPad from "./ActionBarPad.vue";

export default {
  components: { OperationalInformationPad, ActionBarPad },
  data() {
    return {
      value: "",
      dialog1Visible: false,
      dialogVisible: false,
      dialogVisible1: false,
      appCardsVisible: false,
      mingluVisible: false,
      dingweiVisible: false,
      timerSetVisible: false,
      radio: 1,
      radiolist: [
        {
          id: 0,
          name: "上级时间",
        },
        {
          id: 1,
          name: "本机系统时间",
        },
        {
          id: 2,
          name: "北斗",
        },
      ],
      tabList: null,
      listTab: [
        {
          id: 0,
          name: "同步天文时间",
        },
        {
          id: 1,
          name: "同步上级作战时间",
        },
      ],
    };
  },
  directives: { clickoutside },
  computed: {
    isMainApp() {
      // 当前是否是主界面应用
      return this.$tis.isMainApp();
    },
    currentApp() {
      return this.$tis.getCurrentApp();
    },
    messageCounts() {
      return this.$tis.getMessageCount();
    },
  },
  mounted() {},
  methods: {
    onSystemSetting() {
      this.dialogVisible = false;
      this.$tis.bus.registerVisible.messageSettingVisible = true;

      this.$tis.bus.messageSettingVisible = true;
      this.$tis.bus.applicationInterface = {
        visible: true,
        name: "系统设置",
      };
    },
    //切换用户
    goSwitch() {
      sessionStorage.removeItem('token');
      window.location.reload();
    },
    //退出登录
    goSign() {
      sessionStorage.removeItem('token');
      window.location.reload();
    },
    closeCurrentApp() {
      this.$tis.closeCurrentApp();
    },
    openWidgetPanel() {
      this.$tis.openWidgetPanel();
    },

    openNotificationWhole(e) {
    },
    //搜索
    toggleSearchBox() {},

    //通讯录
    toggleMessageBox() {},
  },
};
</script>

<style lang="scss" scoped>
$barHeight: 56px;
.status-bar {
  height: $barHeight;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: var(--status-bar-text-color);
  background-image: var(--status-app-bar-bg-img);
  background-repeat: no-repeat;
  background-size: cover;
  .back {
    height: 100%;
    cursor: pointer;
  }
  .title {
    font-size: 28px;
    margin: 0 43px 0 27px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
  }
  .status-bar-sys {
    margin-left: 20px;
    margin-right: 10px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    .item-signal {
      margin: 5px;
      padding-left: 22px;
      background-image: url(/signal.svg);
      background-repeat: no-repeat;
      background-size: 12px 14px;
      background-position: 0 center;
    }
    .item-battery {
      margin: 5px;
      padding-left: 26px;
      background-image: url(/battery.png);
      background-repeat: no-repeat;
      background-size: 18px 11px;
      background-position: 0 center;
    }
  }
  .tools {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .item-tool {
      cursor: pointer;
      margin: 0 10px;
      display: flex;
      align-items: center;
      img {
        display: block;
      }
      span {
        font-size: 24px;
        margin-left: 8px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    margin-right: 15px;
  }
  .email {
    position: relative;
    padding: 0 28px;
    height: 100%;
    cursor: pointer;
    margin-left: 2px;
    opacity: 0.8;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 1px 0 1px 0 #4c4a48;
      background-image: linear-gradient(
        180deg,
        rgba(115, 110, 102, 0.02) 0%,
        rgba(63, 59, 54, 0.18) 100%
      );
      transform: skewX(-26deg);
    }
  }
  .widgets {
    position: relative;
    z-index: 1;

    padding: 0 28px;
    height: 100%;
    cursor: pointer;
    margin-left: 2px;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      color: var(--status-bar-widget-btn-color);
      text-shadow: var(--status-bar-widget-btn-text-shadow);
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: var(--status-bar-widget-btn-bg-img);
      box-shadow: var(--status-bar-widget-box-shadow);
      transform: skewX(-26deg);
    }
  }

  .close {
    flex-grow: 0;
    position: relative;
    height: 100%;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 2px;
    position: relative;
    margin-right: -20px;
    padding: 0 20px 0 10px;

    img {
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: skewX(-26deg);
      background-image: var(--status-bar-close-bg-image);
    }
  }
}

.status-bar-2 {
  height: 104px;
  // margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: var(--status-bar-text-color);
  opacity: var(--status-bar-bg-opacity);
  background-color: var(--status-bar-bg-color);
  background-image: var(--status-bar-bg-img);
  box-shadow: var(--status-bar-box-shadow);
  border-bottom: var(--status-bar-border);
  display: flex;
  > .col1 {
    min-width: 220px;
    height: 100%;
  }
  > .col2 {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    > .action {
      flex: 1;
      position: relative;
      min-width: 88px;
      height: 100%;
      img {
        width: 88px;
        position: absolute;
        left: -2px;
        bottom: -13px;
      }
    }
    > .status-bar-info:last-child {
      margin-right: 0;
    }
  }
  > .col3 {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    > .grow {
      flex-grow: 1;
    }
    > .info {
      position: absolute;
      top: 2px;
      cursor: pointer;

      right: 20px;
      span {
        font-size: 12px;
        margin: 0px 15px;
      }
    }
  }
}

.status-bar-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 24px;
  margin: 0 14px;
  cursor: pointer;
  p {
    margin: 0;
    &:last-child {
      margin-top: 6px;
    }
  }
  .item-info {
    margin: 0 2px;
  }
}

.status-bar-seat {
  width: 100%;
  height: 100%;
  // padding-right: 84px;
  position: relative;
  &:before {
    content: "";
    background-image: var(--status-bar-mobile-left-img);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .seat-info {
    position: relative;
    height: 100%;
    line-height: 104px;
    text-align: center;
    cursor: pointer;
    font-size: 48px;
    color: #313131;
    padding-left: 30px;
    padding-right: 100px;
  }
  .seat-arrow {
    display: inline-block;
    border: 8px solid #313131;
    width: 10px;
    height: 10px;
    border-top: 0 none;
    border-right: 0 none;
    transform: translate(0, -12px) rotate(-45deg);
    margin-left: 24px;
    // color: #313131;
  }
}
.zhang-name {
  display: flex;
  font-size: 14px;
  color: #ffffff;
}
.zhang-divone {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  color: #ffffff;
}
.zhang-divone-one {
  font-size: 14px;
  // color: #8e908e;
  color: #8e908e;
  margin-left: 20px;
}
.zhang-state {
  display: flex;
  margin-top: 50px;
  margin-left: -10px;
}
.zhang-btn1 {
  width: 80px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  background: none;
}
.zhang-btn2 {
  width: 80px;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  // margin-left: 1px;
  border: none;
  background: none;
  margin-left: 10px;
}
.zhang-span1 {
  opacity: 0.3;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;
}
.zhang-one {
  border: 4px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-bottom: none;
  border-right: none;
}
.zhang-two {
  border: 4px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  right: -1px;
  border-bottom: none;
  border-left: none;
}
.zhang-three {
  border: 4px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 1px;
  right: 1px;
  border-top: none;
  border-left: none;
}
.zhang-fore {
  border: 4px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 1px;
  left: 1px;
  border-top: none;
  border-right: none;
}
.not-allowed {
  cursor: not-allowed !important;
}
.minlu {
  position: relative;
  background: red;
  .pop {
    position: absolute;
    top: 60px;
    left: -100px;
    z-index: 200;
    img {
      width: 300px;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

::v-deep .el-dialog {
  margin-top: 80px !important;
  background: rgba(0, 0, 0, 0);
  top: 20px;
  width: 24vw;
  margin: initial;
  margin-left: 36px;
}
::v-deep .el-dialog__body {
  background: rgba(0, 0, 0, 0.85);
  // background: #000000;
  // opacity: 85%;
  color: white;
  padding: 40px 30px;
  margin-top: 0px;
  height: calc(100vh - 56px - 200px);
  position: relative;

  .user-info {
    font-size: 28px;
    div {
      font-size: 28px;
    }
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
  }
  .menu-list {
    font-size: 28px;
    .item {
      display: flex;
      padding: 30px 0;
      justify-content: space-between;
      .right {
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          position: relative;
          top: 13px;
          border: 4px solid #fff;
          width: 14px;
          height: 14px;
          border-top: 0 none;
          border-right: 0 none;
          transform: translate(0, -12px) rotate(224deg);
          margin-left: 24px;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 20px;
    width: 90%;
    left: 5%;
    height: 76px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    button {
      width: 100%;
      font-size: 28px;
    }
  }
}
::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: -20px;
  right: 12px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  font-size: 10px;
  display: none;
}
::v-deep .status-bar-info {
  cursor: pointer;
}
.timeSetting {
  background: rgba(0, 0, 0, 0.85);
  // border: 1px solid red;
  width: 368px;
  height: 368px;
  margin-top: 12px;
}
.timeSetting-top {
  color: #ffdd55;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 18px;
}
.timeSetting-source {
  font-size: 16px;
  color: white;
  padding-left: 15px;
  margin-top: 20px;
}
.timeSetting-source-one {
  display: flex;
  margin-top: 15px;
  .sourceOne {
    display: flex;
    margin-left: 15px;
    .radioOne {
      border: 2px solid #d8d8d8;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      cursor: pointer;
    }
    .radioOnes {
      border: 2px solid #d8d8d8;
      background: #f7c829;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
    .sourceOne-one {
      font-size: 16px;
      color: #ffffff;
      margin-left: 8px;
    }
  }
}
.timeSetting-timeFight {
  font-size: 16px;
  color: white;
  padding-left: 15px;
  margin-top: 30px;
}
.timeFight {
  display: flex;
  margin-top: 15px;
  .timeFight-one {
    display: flex;
    margin-left: 15px;
    .radioTwo {
      border: 2px solid #d8d8d8;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      cursor: pointer;
    }
    .radioTwos {
      border: 2px solid #d8d8d8;
      width: 15px;
      height: 15px;
      background: #f7c829;
      border-radius: 50%;
    }
    .timeFight-Astronomy {
      font-size: 16px;
      color: #ffffff;
      margin-left: 8px;
    }
  }
  .timeFight-two {
    display: flex;
    margin-left: 35px;
    .radioFive {
      border: 2px solid #d8d8d8;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
    .timeFight-superior {
      font-size: 16px;
      color: #ffffff;
      margin-left: 8px;
    }
  }
}
.time-setup {
  display: flex;
  margin-left: 15px;
  margin-top: 30px;
  .time-setup-one {
    font-size: 16px;
    color: #ffffff;
  }
  .time-setup-two {
    font-size: 16px;
    color: #ffdd55;
    text-align: center;
    margin-left: 178px;
    cursor: pointer;
  }
}

.broadcast {
  display: flex;
  margin-top: 20px;
  .broadcast-one {
    display: flex;
    margin-left: 15px;
    input[type="checkbox"] {
      cursor: pointer;
      position: relative;
      width: 15px;
      height: 15px;
      font-size: 14px;
    }

    input[type="checkbox"]::after {
      position: absolute;
      top: 0;
      color: #000;
      width: 15px;
      height: 15px;
      display: inline-block;
      visibility: visible;
      padding-left: 0px;
      text-align: center;
      content: " ";
      border-radius: 3px;
    }

    input[type="checkbox"]:checked::after {
      content: "✓";
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      background-color: #f7c829;
    }
    .inCar {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #ffffff;
      margin-left: 10px;
    }
  }
  .time-Btn {
    width: 72px;
    height: 40px;
    background: #ffcc00;
    font-size: 16px;
    color: #313131;
    text-align: center;
    border: none;
    margin-left: 176px;
    cursor: pointer;
  }
  .time-Btn:hover {
    color: #ffdd55;
  }
}
.time-one {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 11px;
  left: 0px;
  border-bottom: none;
  border-right: none;
}
.time-two {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 11px;
  right: 0px;
  border-bottom: none;
  border-left: none;
}
.time-three {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 360px;
  right: 0px;
  border-top: none;
  border-left: none;
}
.time-fore {
  border: 2px solid #f7dd29;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 360px;
  left: 0px;
  border-top: none;
  border-right: none;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 336px;
  margin-top: 15px;
  margin-left: 15px;
}
::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-image: none;
  background: #292929;
  border: 1px solid #979797;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.w-dialog {
  position: fixed;
  width: 600px;
  background: rgba(0, 0, 0, 0.8);
  padding: 0 24px;
  z-index: 999;
  .angle-mark {
    border: 4px solid #f7dd29;
    width: 20px;
    height: 20px;
    position: absolute;

    &.left-top {
      top: -1px;
      left: -1px;
      border-bottom: none;
      border-right: none;
    }
    &.right-top {
      right: -1px;
      top: -1px;
      border-left: none;
      border-bottom: none;
    }
    &.left-bottom {
      bottom: -1px;
      left: -1px;
      border-top: none;
      border-right: none;
    }
    &.right-bottom {
      right: -1px;
      bottom: -1px;
      border-left: none;
      border-top: none;
    }
  }
  .title {
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    color: #f7dd29;
    display: flex;
    justify-content: space-between;
    .right.close-btn {
      position: relative;
      width: 40px;
      text-align: center;
      &::after {
        content: "";
        height: 2px;
        width: 20px;
        background: #f7dd29;
        position: absolute;
        top: 50%;
      }
    }
  }
  &.w-dialog1 {
    width: 600px;
    top: 140px;
    right: 50px;
    padding-bottom: 30px;
    .item {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #ffcb00;
      margin-bottom: 24px;
      padding: 20px;
      .row1 {
        font-size: 28px;
        color: #ffffff;
      }
      .row2 {
        font-family: EurostileRegular;
        font-size: 24px;
        color: #ffffff;
      }
    }
  }
}
</style>
