<template>
  <!-- <el-dialog
    title="历史消息"
    :visible.sync="isVisible"
    custom-class="dialogBox"
    @close="onClose"
    width="30%"
  >
    <div slot="title">登录</div>
    <div>
      <el-input class="input" placeholder="用户名" v-model="username">
      </el-input>

      <el-input
        type="password"
        placeholder="密码"
        v-model="password"
      ></el-input>

      <el-row>
        <el-button class="btn-cancel" @click="onClose">取 消</el-button>
        <el-button class="btn-login" @click="onClose">登 录</el-button>
      </el-row>
    </div>
  </el-dialog> -->
  <div class="login">
    <div class="login-wrap">
      <div class="title">登录</div>
      <div class="form-wrap">
        <el-input class="input" placeholder="用户名" v-model="username">
        </el-input>

        <el-input
          type="password"
          placeholder="密码"
          v-model="password"
        ></el-input>
        <el-row>
          <el-button class="btn-cancel" @click="onClose">取 消</el-button>
          <el-button class="btn-login" @click="onLogin">登 录</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: false,
      username: "",
      password: "",
    };
  },

  created() {
    // this.isVisible = this.$tis.isVisibleWidgetsLoginPanel();
  },

  methods: {
    onClose() {
      this.$tis.closeWidgetLoginPanel();
    },
    onLogin() {
      //请求接口 
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  // background: rgba(0,0,0,0.9);
  background: #000;
  height: 100%;
}
.login-wrap {
  background: url("../assets/loginbg.png") no-repeat;
  width: 496px;
  height: 324px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -248px;
  margin-top: -162px;
}
.title {
  height: 36px;
  line-height: 36px;
  color: #fff;
  font-size: 24px;
  text-align: center;
}
// .title:hover{
//    color: #FFE88B;
// }
.form-wrap {
  padding: 34px 67px 0;
}
.el-button {
  width: 168px;
  font-size: 20px;
  border: 1px solid #aaa;
  border-radius: 0;
  -webkit-border-radius: 0;
  padding: 0;
  text-align: center;
  height: 48px;
  line-height: 48px;
}
.btn-cancel {
  color: #fff;
  background: none;
}
.btn-login {
  margin-left: 50px;
  background: url("../assets/login.png") no-repeat;
  color: #382C0C;
  border-color: #FFD443;
}
.el-row {
  text-align: center;
  margin-top: 30px;
  display: flex;
}
// .btn-login:hover{
//  color: #FFE88B;
// }

::v-deep .el-input__inner {
  margin-top: 15px;
  width: 100%;
  background: rgba(59,59,55,0.50);
  border: 1px solid #AAAAAA;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  border-radius: 0;
  -webkit-border-radius: 0;
}





// .el-button {
//   width: 120px;
// }
// .btn-login {
//   margin-left: 50px;
//   background: url("../assets/login.png") no-repeat;
// }
// .el-row {
//   text-align: center;
//   margin-top: 30px;
// }
// ::v-deep .el-button {
//   background-color: #333;
//   border: 1px solid #aaa;
//   color: #fff;
// }

// ::v-deep .el-input__inner {
//   margin-top: 20px;
//   width: 80%;
//   background-color: #333;
//   border: 1px solid #aaa;
// }
// ::v-deep .dialogBox {
//   text-align: center;
//   background: #1d1c18;
//   border: 2px solid rgba(203, 185, 111, 0.39);
//   border-image: -webkit-linear-gradient(#ccc, #f7c829) 30 30;
//   height: 300px;
// }
// ::v-deep .el-dialog__header {
//   height: 32px;
//   padding: 0;
//   // color: black;
//   background: url("../assets/border-top.jpg") no-repeat;
//   background-size: 100% 100%;
//   background-color: black;
//   text-align: center;
//   line-height: 32px;
// }
// ::v-deep .el-dialog__headerbtn {
//   top: 16px;
//   .el-dialog__close {
//     color: #fddb5c;
//     font-weight: bold;
//     font-size: 24px;
//   }
// }
</style>
