import Vue from "vue";
import Notifition from "../components/NewsNotification.vue";
let  notifitionConstructor  = Vue.extend(Notifition);
let  instance ;
const  newsNotifitions = ()=>{
    if (instance) {
        return instance
    }
    instance = new  notifitionConstructor()
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    return instance 
}
newsNotifitions.show = (params)=>{

    const notifitions = new newsNotifitions();
    notifitions.show(params);
}
export default newsNotifitions

