<template>
  <div class="SettingSys" v-if="isVisible">
    <div class="title">系统设置</div>
    <span class="close" @click="onClose"></span>
    <div class="right-container">
      <!-- 显示设置 -->
      <div class="news-setting" v-if="menuIndex == 1">
        <div class="titlePanle">
          <span class="bg-gray"></span>
          <span class="bg"></span>
          <span class="text">显示设置</span>
        </div>
        <div class="newSetingContent">
          <div class="rowItem">
            <span class="title-label">主题</span>
            <div class="right-content">
              <el-radio-group v-model="radio_theme">
                <el-radio label="">默认主题</el-radio>
                <el-radio label="blue">蓝色主题</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">分屏</span>
            <div class="right-content">
              <el-radio-group v-model="splitScreen">
                <el-radio label="1" class="splitScreen_class">不分屏</el-radio>
                <el-radio label="2" class="splitScreen_class">二分屏</el-radio>
                <el-radio label="3" class="splitScreen_class">三分屏</el-radio>
                <el-radio label="4" class="splitScreen_class">四分屏</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- 显示设置 end -->
      </div>
      <!-- 监控设置模块 -->
      <div class="monitoring-setting" v-if="menuIndex == 3">
        <div class="titlePanle">
          <span class="bg-gray"></span>
          <span class="bg"></span>
          <span class="text">系统看板</span>
        </div>
        <div class="performance-analysis">
          <ul>
            <li class="item">
              <span>当前活跃内存</span>
              <span>{{ memory.usedJSHeapSize | byteToMB }} MB</span>
            </li>
            <li class="item">
              <span>已分配内存</span>
              <span>{{ memory.totalJSHeapSize | byteToMB }} MB</span>
            </li>
            <li class="item">
              <span>可用最大内存</span>
              <span>{{ memory.jsHeapSizeLimit | byteToMB }} MB</span>
            </li>
          </ul>
        </div>
        <!-- group-card-list 类是控制多个的，如果只有一个卡片去掉该类就可以了 需要把 :style="{width: '48%'}"也去掉-->
        <div
          :class="['card-list', 'group-card-list']"
          :style="{ display: 'none' }"
        >
          <el-card class="box-card" :style="{ width: '48%' }">
            <div slot="header" class="clearfix">
              <span>列表</span>
            </div>
            <div v-for="o in 4" :key="o" class="text item">
              {{ "列表内容 " + o }}
            </div>
          </el-card>
          <el-card class="box-card" :style="{ width: '48%' }">
            <div slot="header" class="clearfix">
              <span>列表</span>
            </div>
            <div v-for="o in 4" :key="o" class="text item">
              {{ "列表内容 " + o }}
            </div>
          </el-card>
        </div>
        <div class="card-list">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>应用列表</span>
            </div>
            <div v-for="app in runningApps" :key="app.name" class="text item">
              {{ app.name }} (状态: {{ app.status }}
              {{ app.loadTime && ` 加载耗时: ${app.loadTime}ms` }})
            </div>
          </el-card>
        </div>
      </div>
      <!-- 消息设置 -->
      <div class="news-setting" v-if="menuIndex == 2">
        <div class="titlePanle">
          <span class="bg-gray"></span>
          <span class="bg"></span>
          <span class="text">基础设置</span>
        </div>
        <div class="newSetingContent">
          <div class="rowItem">
            <span class="title-label">消息语音提示</span>
            <div class="right-content">
              <el-radio-group v-model="radio_message_hint">
                <el-radio label="无"></el-radio>
                <el-radio label="提示音"></el-radio>
                <el-radio label="语音播报"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">消息精准度</span>
            <div class="right-content">
              <el-radio-group v-model="radio_message_precise">
                <el-radio label="模糊"></el-radio>
                <el-radio label="精确"></el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">右侧消息提醒</span>
            <div class="right-content">
              <div
                class="switch-div"
                @click="switchMessageHint = !switchMessageHint"
              >
                <span
                  :class="{
                    switch_open: switchMessageHint,
                    switch_close_defult: !switchMessageHint,
                  }"
                >
                </span>
                <span
                  :class="{
                    switch_open_defult: switchMessageHint,
                    switch_close: !switchMessageHint,
                  }"
                >
                </span>
              </div>
            </div>
          </div>
          <div class="small-text-div">
            当前席位处于在线状态时，常规消息通知都会从右侧弹出，重要消息保持强提示方式
          </div>
          <span class="news-line"></span>
          <div class="rowItem">
            <span class="title-label">勿扰模式</span>
            <div class="right-content">
              <div class="switch-div" @click="DNDmodel = !DNDmodel">
                <span
                  :class="{
                    switch_open: DNDmodel,
                    switch_close_defult: !DNDmodel,
                  }"
                ></span>
                <span
                  :class="{
                    switch_open_defult: DNDmodel,
                    switch_close: !DNDmodel,
                  }"
                ></span>
              </div>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">勿扰时间段</span>
            <div class="right-content">
              <el-time-select
                placeholder="起始时间"
                v-model="startTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
              >
              </el-time-select>
              <span class="zhi">至</span>
              <el-time-select
                placeholder="结束时间"
                v-model="endTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                  minTime: startTime,
                }"
              >
              </el-time-select>
            </div>
          </div>
        </div>
        <!-- 消息设置 end -->
      </div>
      <div class="voice-setting" v-if="menuIndex == 4">
        <!-- 语音设置 -->
        <div class="titlePanle">
          <span class="bg-gray"></span>
          <span class="bg"></span>
          <span class="text">基础设置</span>
        </div>
        <div class="newSetingContent">
          <div class="rowItem">
            <span class="title-label">默认模式</span>
            <div class="right-content">
              <el-radio-group v-model="voiceSetting.iatoneType">
                <el-radio :label="3">实时流模式</el-radio>
                <el-radio :label="1">命令词模式</el-radio>
                <el-radio :label="2">自由说模式</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">语音播报</span>
            <div class="right-content">
              <el-radio-group v-model="voiceSetting.broadcast">
                <el-radio :label="0">关</el-radio>
                <el-radio :label="1">开</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">人声</span>
            <div class="right-content">
              <el-radio-group v-model="voiceSetting.role">
                <el-radio :label="0">男声</el-radio>
                <el-radio :label="1">女声</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">语速</span>
            <div class="right-content">
              <el-slider
                :min="0"
                :max="10"
                v-model="voiceSetting.speed"
              ></el-slider>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">语调</span>
            <div class="right-content">
              <el-slider
                :min="0"
                :max="10"
                v-model="voiceSetting.tone"
              ></el-slider>
            </div>
          </div>
          <div class="rowItem">
            <span class="title-label">音量</span>
            <div class="right-content">
              <el-slider
                :min="0"
                :max="10"
                v-model="voiceSetting.volume"
              ></el-slider>
            </div>
          </div>
        </div>
        <div class="titlePanle">
          <span class="bg-gray"></span>
          <span class="bg"></span>
          <span class="text">快 捷 指 令</span>
        </div>
        <div class="content content6">
          <el-tabs v-model="value6">
            <template v-for="(item, index) in instructList">
              <el-tab-pane
                label="组件菜单"
                :name="index.toString()"
                :key="index"
              >
                <el-button
                  class="button-new-tag"
                  size="small"
                  @click="showAddTagDialog(index)"
                >
                  +
                </el-button>
                <el-tag
                  v-for="(tag, i) in item.list"
                  :key="tag.name"
                  closable
                  @click="showEditDialog(index, i)"
                  @close="onTabClose(index, i)"
                  :type="tag.type"
                  v-show="!tag.hidden"
                >
                  {{ tag.name }}
                </el-tag>
              </el-tab-pane>
            </template>
          </el-tabs>
          <div class="search-content">
            <el-input
              v-model="searchValue"
              @input="onSearchInput"
              placeholder="搜索命令词"
            ></el-input>
            <el-button>搜索</el-button>
          </div>
        </div>
      </div>
      <div class="voice-synthesis" v-if="menuIndex == 5">
        <div class="titlePanle">
          <span class="bg-gray"></span>
          <span class="bg"></span>
          <span class="text">语音合成</span>
        </div>
        <div class="row">
          <label>合成语句：</label>
          <div class="right">
            <tis-input v-model="s2vStr" type="text"> </tis-input>
            <tis-button @click="s2vAndPlay">合成</tis-button>
          </div>
        </div>
        <div class="row">
          <label>合成结果：</label>
          <div class="right">
            <audio
              :src="playAfterS2vFlg ? voideFileSrc : ''"
              controls="controls"
            ></audio>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="添加标签"
      :visible.sync="addTagVisible"
      :modal="false"
      custom-class="dialogBox"
      width="35%"
    >
      <div class="content">
        <el-input placeholder="请输入名称" v-model="tagValue"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTagVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddTag">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="编辑标签"
      :visible.sync="editTagVisible"
      :modal="false"
      custom-class="dialogBox"
      width="35%"
    >
      <div class="content">
        <el-input placeholder="请输入名称" v-model="editTagValue"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editTagVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditTag">确 定</el-button>
      </span>
    </el-dialog>

    <div class="left-container">
      <el-row class="tac-menu">
        <el-col>
          <el-menu
            @select="onMenuSelect"
            :default-active="menuIndex.toString()"
            class="el-menu-vertical"
            text-color="#666"
            active-text-color="#ffffff"
          >
            <el-menu-item index="1">
              <!-- 建议有图标就都有图标，没有就都没有吧 -->
              <span class="icon-news" v-if="menuIndex == 1"></span>
              <span slot="title">显示设置</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span class="icon-news" v-if="menuIndex == 2"></span>
              <span slot="title">消息设置</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span class="icon-news" v-if="menuIndex == 3"></span>
              <span slot="title">监控模块</span>
            </el-menu-item>
            <el-menu-item index="4">
              <span class="icon-news" v-if="menuIndex == 4"></span>
              <span slot="title">语音设置</span>
            </el-menu-item>
            <el-menu-item index="5">
              <span class="icon-news" v-if="menuIndex == 5"></span>
              <span slot="title">语音合成</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: true,
      menuIndex: 1,
      radio: "1",
      radio_theme: "",
      radio_message_hint: "无",
      radio_message_precise: "模糊",
      switchMessageHint: true,
      DNDmodel: false,
      startTime: "",
      endTime: "",
      voiceSetting: {
        role: 1, // 人声
        speed: 5, // 语速
        tone: 5, // 语调
        volume: 5, // 音量
        iatoneType: 2, // 模式
        broadcast: 0, // 语音播报
      },
      value6: 0,
      broadcast: 1,
      playAfterS2vFlg: false,
      voideFileSrc: null,
      addTagVisible: false,
      editTagVisible: false,
      editTagValue: null,
      tagValue: null,
      commandList: [],
      searchValue: "",
      s2vStr: "",
      instructList: [
        {
          name: "快捷指令",
          list: [],
        },
        {
          name: "快捷指令",
          list: [],
        },
        {
          name: "快捷指令",
          list: [],
        },
      ],
      splitScreen: "1",
    };
  },
  filters: {
    byteToMB(byte) {
      return (byte / 1024 / 1024).toFixed(2);
    },
  },
  computed: {
    visibleWidgetSysSetting() {
      return this.$tis.bus.visibleWidgetSysSetting;
    },
    runningApps() {
      const apps = this.$tis.getApps();
      return apps.map((app) => {
        const loaded = !!app.instance;
        const opened = app.opened;

        let status = "未加载";
        if (loaded) {
          status = opened ? "运行" : "隐藏";
        }

        return {
          name: app.name,
          id: app.id,
          loadTime: app.loadTime,
          loaded,
          opened,
          status,
        };
      });
    },
    memory() {
      return window.performance.memory;
    },
  },
  created() {
    this.radio_theme =
      document.documentElement.getAttribute("data-theme") || "";

    this.getBnf();
  },
  mounted() {
    this.getSetting();
    this.menuIndex = this.$tis.bus.visibleWidgetSysSettingMenuIndex || 1;
    this.splitScreen = localStorage.getItem("splitScreen") || "1";
  },
  methods: {
    getBnf() {
      this.$tis.voice
        .getBnf()
        .then((res) => {
          this.commandList = (res || "").split(/\|/g);
          this.instructList = [
            {
              name: "快捷指令",
              list: this.commandList
                .filter((str) => str.match(/(打开)/i))
                .map((str) => {
                  return {
                    name: str,
                    edit: false,
                    type: "success",
                    hidden: false,
                  };
                }),
            },
            {
              name: "快捷指令",
              list: this.commandList
                .filter((str) => str.match(/(分屏|切屏)/i))
                .map((str) => {
                  return {
                    name: str,
                    edit: false,
                    type: "success",
                    hidden: false,
                  };
                }),
            },
            {
              name: "快捷指令",
              list: this.commandList
                .filter((str) => !str.match(/(分屏|切屏|打开)/i))
                .map((str) => {
                  return {
                    name: str,
                    edit: false,
                    type: "success",
                    hidden: false,
                  };
                }),
            },
          ];
          console.log(this.instructList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClose() {
      this.$tis.closeWidgetSettingPanel();
    },
    onTabClose(index, i) {
      this.instructList[index].list[i].hidden = true;
      this.updateBnf();
    },
    async updateBnf(tag = "") {
      let list = this.instructList
        .map((item) => item.list.filter((o) => !o.hidden).map((o) => o.name))
        .flat(1);
      list.push(tag);
      list = [...new Set(list)];
      await this.$tis.voice.updateBnf({
        bnf: list.join("|"),
      });
    },
    onSearchInput() {
      this.instructList.forEach((item) => {
        item.list.map((o) => {
          if (!this.searchValue || o.name.includes(this.searchValue)) {
            o.hidden = false;
          } else {
            o.hidden = true;
          }
        });
      });
    },
    s2vAndPlay() {
      this.playAfterS2vFlg = false;
      if (this.s2vStr) {
        this.$tis.voice
          .getVoice({
            string: this.s2vStr,
            time: Date.now().toString(),
          })
          .then((res) => {
            this.voideFileSrc = res;
            this.playAfterS2vFlg = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showAddTagDialog(index) {
      this.activateAddBtnIndex = index;
      this.tagValue = null;
      this.addTagVisible = true;
    },
    async onMenuSelect(index) {
      this.menuIndex = index;
    },
    async getSetting() {
      // 语音设置
      let {
        role = 3,
        speed = 5,
        tone = 5,
        volume = 5,
        iatoneType = 2,
        broadcast = 0,
      } = await this.$tis.voice.getSetting();
      this.voiceSetting = {
        role, // 人声
        speed, // 语速
        tone, // 语调
        volume, // 音量
        iatoneType, // 模式
        broadcast, // 语音播报
      };
    },
    showEditDialog(index, i) {
      this.editTagVisible = true;
      this.editTagValue = this.instructList[index].list[i].name;
      this.editIndex = [index, i];
    },
    onEditTag() {
      let [index, i] = this.editIndex;
      this.instructList[index].list[i].name = this.editTagValue;
      this.editTagVisible = false;
      this.updateBnf();
    },
    async onAddTag() {
      if (this.tagValue) {
        this.addTagVisible = false;
        await this.updateBnf(this.tagValue);
        this.getBnf();
      }
    },
  },
  watch: {
    radio_theme() {
      document.documentElement.setAttribute("data-theme", this.radio_theme);
      this.$tis.changeTheme(this.radio_theme === "" ? "default" : "blue");
      // 设置localstorage
      localStorage.setItem(
        "TIS_DESIGN_THEME",
        this.radio_theme === "" ? "YELLOW" : "BLUE"
      );
    },
    voiceSetting: {
      handler(val) {
        this.$tis.voice.setSetting(val);
      },
      deep: true,
    },
    splitScreen() {
      // 存在localStorage
      console.log(this.splitScreen, typeof this.splitScreen);
      localStorage.setItem("splitScreen", this.splitScreen);
    },
  },
};
</script>

<style lang="scss" scoped>
.SettingSys {
  width: 1200px;
  height: 800px;
  max-height: 80vh;
  box-sizing: border-box;
  margin: 10px auto;
  position: relative;
  border: 2px solid rgba(203, 185, 111, 0.39);
  border-image: -webkit-linear-gradient(#ccc, #f7c829) 30 30;
  background: url("../assets/border-top.png") no-repeat #1d1c18;
  background-size: 100% 32px;
  box-shadow: 0 0 8px 0 #f8dc6b;
  // height: calc(100% - 12px);
  // height: -webkit-calc(100% - 12px);
  // font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;
  .title {
    line-height: 30px;
    height: 32px;
    color: #fff;
    font-size: 24px;
    text-align: center;
  }
  .close {
    // height: 32px;
    // top: 10px;
    // right: 30px;
    // z-index: 999;
    // font-size: 18px;
    // position: absolute;
    // color: #FFDC67;
    position: absolute;
    background: url("../assets/close.png") no-repeat;
    background-size: 100%;
    width: 41px;
    height: 30px;
    top: 8px;
    right: 20px;
  }

  .icon,
  .icon-news {
    width: 54px;
    height: 44px;
    display: inline-block;
  }
  .icon-news {
    background: url("../assets/eye.png") no-repeat;
    background-size: 54px 44px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -22px;
  }
  .left-container {
    height: 100%;
    width: 224px;
    padding: 44px 0;
    border-right: 1px solid rgba(97, 95, 87, 0.37);
    margin-top: -4px;
  }
  .right-container {
    float: right;
    margin: 0 40px 0 30px;
    height: 100%;
    padding: 44px 0 0 0;
    width: 901px;
    overflow: hidden;
    height: calc(100% - 100px);
    overflow-y: auto;
    .monitoring-setting,
    .news-setting {
      background: #191814;
      height: calc(100% - 80px);
      height: -webkit-calc(100% - 80px);
    }
    .titlePanle {
      height: 64px;
      line-height: 64px;
      position: relative;
      font-size: 20px;
      color: #fff;
      padding: 0 0 0 70px;
      position: relative;
      // .titlePanle-icon {
      //   position: absolute;
      //   transform: rotate(45deg);
      //   transform: -webkit-rotate(45deg);
      //   background: #FFDC67;
      //   border: 1px solid #FFE692;
      //   box-shadow: 0 0 8px 4px rgba(247,200,41,0.50);
      //   height: 16px;
      //   width:16px;
      //   top: 50%;
      //   margin-top: -8px;
      //   left: 30px;
      // }
      .bg {
        content: "";
        position: absolute;
        height: 64px;
        background: url("../assets/title-bg.png") no-repeat;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
      }
      .bg-gray {
        position: absolute;
        height: 34px;
        width: 100%;
        background: #1d1c18;
        left: 0;
        top: 0;
        z-index: 0;
      }
      .text {
        position: relative;
        z-index: 3;
      }
    }
    //图表
    // .monitoring-module {
    // }
    .performance-analysis {
      .item {
        display: -webkit-flex; /* Safari */
        display: flex;
        display: flex;
        padding: 25px 0;
        flex-direction: row;
        justify-content: space-between;
        line-height: 20px;
        font-size: 18px;
      }
      padding: 24px 40px;
    }
    .card-list {
      padding: 10px 40px;
    }
    .group-card-list {
      display: -webkit-flex; /* Safari */
      display: flex;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .newSetingContent {
    padding: 25px 34px 0 54px;
    .small-text-div {
      margin: 0 0 50px 0;
      font-size: 14px;
      color: #fff;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
    }
    .news-line {
      height: 1px;
      background: rgba(255, 255, 255, 0.12);
      display: flex;
      margin: 0 0 30px 0;
    }
    .switch-div {
      position: relative;
      margin: 12px 50px 0 0;
    }
    .switch_open {
      position: absolute;
      right: 0;
      width: 20px;
      height: 25px;
      background: #ffc70a;
      box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 26%);
      transform: skewX(-26deg);
    }
    .switch_open_defult {
      position: absolute;
      left: 0;
      width: 20px;
      height: 25px;
      background: #48433c;
      box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 26%);
      transform: skewX(-26deg);
    }
    .rowItem {
      display: flex;
      -webkit-display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 10px 0;
      line-height: 48px;
      .title-label {
        font-size: 18px;
        color: #fff;
        width: 30%;
      }
    }
    .switch_close_defult {
      position: absolute;
      right: 0;
      width: 20px;
      height: 25px;
      background: #48433c;
      box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 26%);
      transform: skewX(-26deg);
    }
    .switch_close {
      position: absolute;
      left: 0;
      width: 20px;
      height: 25px;
      background: #888;
      box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 26%);
      transform: skewX(-26deg);
    }
    .zhi {
      font-size: 18px;
      color: reba(255, 255, 255, 0.5);
      padding: 0 15px;
    }
  }
}
::v-deep {
  .el-menu-item {
    width: 100%;
    height: 75px;
    line-height: 75px;
    margin-top: 5px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.52);
    // padding-left: 78px;
    text-indent: 60px;
  }
  .el-menu-item.is-active,
  .el-menu-item:hover {
    // background-image: linear-gradient(to right, #4a4324, #e4bf41);
    background: url("../assets/tab-bg.png") no-repeat;
    color: #fff !important;
  }
  .el-menu {
    background: none;
    border-right: none;
  }
  .el-card {
    background: #202020;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: #fff;
    font-size: 18px;
    line-height: 36px;
  }
  .el-card,
  .el-message {
    border-radius: 0;
  }
  .el-card__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
  }
  .el-radio {
    // margin: 0 20px 0 50px;
    width: 130px;
  }
  .el-radio.splitScreen_class{
    // margin: 0 20px 0 50px;
    width:19%;
  }
  .el-radio__inner {
    background: rgba(255, 255, 255, 0.11);
    border: 1px solid rgba(255, 255, 255, 0.47);
    width: 20px;
    height: 20px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ffc70a;
    background: #ffc70a;
  }
  .el-radio__label {
    font-size: 18px;
    color: #fff;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #fff;
  }
  .el-input__inner {
    background: rgba(216, 216, 216, 0.06);
    border: 1px solid #606060;
    border-radius: 0;
    height: 48px;
    line-height: 18px;
    color: #fff;
    font-size: 18px;
  }
  .el-icon-close:before {
    font-size: 34px;
  }
  .voice-setting {
    .right-content {
      min-width: 290px;
    }
    .content6 {
      padding-top: 20px;
      padding-left: 54px;
    }
    .button-new-tag {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
      color: rgba(255, 255, 255, 0.5);
      margin-right: 14px;
      border-radius: 2px;
    }
    .el-tag {
      margin-right: 14px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
      .el-tag__close {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        width: 18px;
        height: 18px;
        &:before {
          font-size: 12px;
          border-radius: 100%;
          width: 18px;
          height: 18px;
          // text-align: center;
          // line-height: 18px;
          background: rgba(255, 255, 255, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .voice-synthesis {
    .row {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      label {
        margin-right: 20px;
      }
      .right {
        flex: 1;
        display: flex;
      }
    }
  }
}
::v-deep {
  .el-tabs__nav-wrap {
    &:after {
      display: none;
    }
  }
}
.search-content {
  display: flex;
  margin-top: 10px;
  padding-bottom: 20px;
}
</style>
